import { createContext } from "react";

/**
 * Context data for the {@link RefreshContext}.
 */
export interface RefreshContextData {
  /** A function that can be used by descendants to refresh data. */
  refresh: () => Promise<void>;
}

/**
 * A context that can be used to provide descendants with a way to refresh
 * data and/or UI.
 */
export const RefreshContext = createContext<RefreshContextData>({
  refresh: async () => {},
});

export default RefreshContext;
