import { Timestamp } from "firebase/firestore";

/**
 * Converts firestore Timestamp objects to JS Dates. All fields and
 * sub-fields are checked and converted if they are Timestamp objects.
 *
 * @param data the object with timestamps
 */
export function convertTimestamps<T>(data: T): T {
  if (!data || typeof data !== "object") return data;

  // Go through each field
  for (const field in data) {
    if (field && field.charAt(0) === "_") continue;

    // Field is a timestamp
    let v = data[field];
    if (v instanceof Timestamp) {
      data[field] = v.toDate() as any;
      continue;
    }

    // Object or array
    if (v && typeof v === "object") {
      if ("__date__" in v && typeof v.__date__ === "number") {
        data[field] = new Date(v.__date__) as any;
      } else if (Array.isArray(v)) {
        for (let i = 0, n = v.length; i < n; i++) {
          let v2 = v[i];
          if (v2 instanceof Timestamp) {
            v[i] = v2.toDate();
          } else {
            convertTimestamps(v2);
          }
        }
      } else {
        convertTimestamps(v);
      }
    }
  }

  return data;
}
