import { SpaceEnv } from "@formatlas/types";
import { createContext } from "react";

/**
 * Represents the data contained in the {@link SpaceEnvContext}.
 */
export interface SpaceEnvContextData {
  /** The current space environment. */
  env: SpaceEnv;
}

/**
 * The Space Environment Context provides common Space environment data that is
 * used in apps like the Form Editor, Form Manager, etc.
 */
export const SpaceEnvContext = createContext<SpaceEnvContextData>({
  env: {
    env: "dev",
    isAvailable: false,
    id: "",
  },
});

export default SpaceEnvContext;
