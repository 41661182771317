import { useCallback, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import Button from "../button/Button";
import {
  attemptFocus,
  containerHas,
  getActiveElement,
  getFocusableElements,
} from "../../utils/dom-utils";

/**
 * The source of a modal close event.
 */
export type ModalCloseSource = "bg" | "close-button" | "esc" | "custom";

/**
 * An interface that describes a modal "close" event, which is fired when
 * the user attempts to close a modal.
 */
export interface ModalCloseEvent {
  /** The source of the event. */
  source: ModalCloseSource;
  /** The original element that had focus before the modal appeared. */
  original: Element | null;
  /** The modal container. */
  modal: HTMLDivElement | null;
  /**
   * A function that can be called with an element that will attempt to focus
   * on a specific node.
   */
  focus: typeof attemptFocus;
}

/**
 * The class that can be added to a single focusable component (e.g. a Cancel
 * button) in a modal to tell the component to add focus when the modal is shown.
 */
export const MODAL_AUTOFOCUS_CLASS = "modal-autofocus";

/**
 * The properties for the {@link Modal} component.
 */
export interface ModalProps {
  /** The modal's DOM id. */
  id?: string;
  /** Extra classes to be added to the modal. */
  className?: string;
  /** If true, the modal's role will be changed to "alertdialog". */
  asAlert?: boolean;
  /** If true, no close icon is shown for the modal. */
  hideCloseButton?: boolean;
  /** The tooltip label for the close button. */
  closeButtonLabel?: string;
  /**
   * A function called when the modal should close. It is up to this function
   * to actually close the modal and focus on an element in the page.
   */
  onClose: (event: ModalCloseEvent) => void | Promise<void>;
  /** A function that creates the modal content. */
  render: (close: () => void) => React.JSX.Element;
  /**
   * An accessible name for the modal, usually the modal's title.
   */
  "aria-label"?: string;
  /**
   * A DOM id that gives the modal an accessible name; usually the id of the
   * element with the modal title.
   */
  "aria-labelledby"?: string;
  /**
   * A DOM id of an element that provides a description of the modal.
   */
  "aria-describedby"?: string;
}

/**
 * A modal dialog window that appears over the main content.
 */
export function Modal(props: ModalProps) {
  const { hideCloseButton, closeButtonLabel = "Close", onClose } = props;

  const bgRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const lastFocusRef = useRef<EventTarget | null>(null);
  const ignoreFocus = useRef(false);
  const originalFocus = useRef(getActiveElement());

  const close = useCallback(
    (source: ModalCloseSource) => {
      onClose({
        source,
        modal: modalRef.current,
        original: originalFocus.current,
        focus: (node) => {
          ignoreFocus.current = true;
          const success = attemptFocus(node);
          ignoreFocus.current = false;
          return success;
        },
      });
    },
    [onClose]
  );

  // Prevent the body from scrolling and change focus
  useEffect(() => {
    document.body.classList.add("overflow-hidden");

    // Determine element to focus
    const elements = getFocusableElements(modalRef.current);
    const autofocus = elements.find((element) =>
      element.classList.contains(MODAL_AUTOFOCUS_CLASS)
    );
    if (autofocus) {
      attemptFocus(autofocus);
    } else if (elements.length) {
      attemptFocus(elements[0]);
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  // Listen for key events
  useEffect(() => {
    function onKeyUp(e: KeyboardEvent) {
      const code = e.code;
      const key = e.keyCode;

      // The modal should close when escape is pressed
      if (code === "Escape" || key === 27) {
        e.preventDefault();
        e.stopPropagation();
        close("esc");
      }
    }
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [close]);

  // Listen for focus events
  useEffect(() => {
    function onFocus(e: FocusEvent) {
      if (ignoreFocus.current) {
        return;
      }

      // Update reference to currently focused modal element
      if (containerHas(modalRef.current, e.target)) {
        lastFocusRef.current = e.target;
        return;
      }

      // Focus has moved outside modal, cancel + focus inside modal
      e.preventDefault();
      e.stopPropagation();
      const elements = getFocusableElements(modalRef.current);
      let focusElement = elements[0];
      if (elements[0] === lastFocusRef.current) {
        focusElement = elements[elements.length - 1];
      }
      attemptFocus(focusElement);
    }
    document.addEventListener("focus", onFocus, true);
    return () => {
      document.removeEventListener("focus", onFocus, true);
    };
  }, []);

  let className = "modal";
  if (!hideCloseButton) {
    className += " modal-with-close";
  }
  if (props.className) {
    className += " " + props.className;
  }

  return createPortal(
    <div
      ref={bgRef}
      className="modal-bg"
      onClick={(e) => {
        if (e.target === bgRef.current) {
          close("bg");
        }
      }}
    >
      <div tabIndex={0} />
      <div
        ref={modalRef}
        id={props.id}
        className={className}
        role={props.asAlert ? "alertdialog" : "dialog"}
        aria-modal
        aria-label={props["aria-label"]}
        aria-labelledby={props["aria-labelledby"]}
        aria-describedby={props["aria-describedby"]}
      >
        {!hideCloseButton && (
          <Button
            className="modal-close"
            type="icon"
            icon="close"
            onClick={() => close("close-button")}
            title={closeButtonLabel}
            aria-label={closeButtonLabel}
          />
        )}
        {props.render(() => close("custom"))}
      </div>
      <div tabIndex={0} />
    </div>,
    document.body
  );
}

export default Modal;
