import { Link } from "react-router-dom";
import { Space } from "@formatlas/types";

import "./SpaceCard.css";
import { Icon } from "@formatlas/react";

export interface SpaceCardProps {
  space: Space;
}

/**
 * A card that the user can click to navigate to a space.
 */
export function SpaceCard(props: SpaceCardProps): JSX.Element {
  const space = props.space;
  return (
    <Link
      to={`/s/${space.id}`}
      className="space-card card container-flex gap-s ai-center shadow-hover flex col-30 s-col-100"
      title={`View the ${space.displayName} Space.`}
    >
      <Icon name={space.icon} size="large" />
      <span className="space-card-name bold">{space.displayName}</span>
    </Link>
  );
}

export default SpaceCard;
