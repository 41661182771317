import { useState } from "react";
import MenuOption from "../../models/MenuOption";
import Button from "../button/Button";
import Icon from "../icon/Icon";
import { Link } from "react-router-dom";

/**
 * The properties for the {@link NavMenu} component.
 */
export interface NavMenuProps {
  /** The component's DOM id. */
  id?: string;

  /** Extra classes to be added to the component. */
  className?: string;

  /**
   * The type of navigation menu. The large menu is designed to appear on
   * larger screens on the header, while the small menu is designed for mobile use.
   */
  type: "large" | "small";

  /** The navigation menu options. */
  options?: MenuOption[];

  /** The URL the page should navigate to if the profile is clicked. */
  profileLink?: string;

  /** The name (or message) to display for the profile. E.g. "Alice Doe", "Not Logged In", etc. */
  profileName?: string;
}

function OptionContent(option: MenuOption): JSX.Element {
  return (
    <>
      {option.icon && <Icon name={option.icon} />}
      {option.name}
    </>
  );
}

function LargeNavMenu(props: NavMenuProps): JSX.Element {
  const [activeOption, setActiveOption] = useState<MenuOption | null>(null);

  function createOption(option: MenuOption, isTopLevel: boolean) {
    const hasChildren = option.children && option.children.length;
    const optionContent = OptionContent(option);
    const isActive = option === activeOption;
    return (
      <li
        key={option.name}
        role="none"
        className={hasChildren && isTopLevel ? "option-with-more" : undefined}
      >
        {option.url ? (
          <Link role="menuitem" to={option.url} title={option.tooltip}>
            {optionContent}
          </Link>
        ) : (
          <button
            role="menuitem"
            className="option"
            onClick={() => option.onClick && option.onClick(option)}
            title={option.tooltip}
          >
            {optionContent}
          </button>
        )}
        {hasChildren && isTopLevel && (
          <>
            <Button
              type="icon"
              icon="expand_more"
              className={"more-btn" + (isActive ? " active" : "")}
              onKeyDown={(event) => {
                if (
                  event.code === "Space" ||
                  event.keyCode === 32 ||
                  event.code === "Enter" ||
                  event.keyCode === 13
                ) {
                  setActiveOption(isActive ? null : option);
                }
              }}
              aria-label={isActive ? "Hide sub menu" : "Show sub menu"}
              aria-expanded={isActive}
            />
            <ul role="menu">
              {option.children?.map((option) => createOption(option, false))}
            </ul>
          </>
        )}
      </li>
    );
  }

  return (
    <ul role="menubar" onMouseEnter={() => setActiveOption(null)}>
      {props.options?.map((option) => createOption(option, true))}
    </ul>
  );
}

function SmallNavMenu(props: NavMenuProps): JSX.Element {
  const [selectedOption, setSelectedOption] = useState<MenuOption | null>(null);

  function createOption(
    option: MenuOption,
    parentOption: MenuOption | null,
    useKey: boolean
  ) {
    const hasChildren = option.children && option.children.length;
    const optionContent = OptionContent(option);
    return (
      <li
        key={useKey ? option.name : undefined}
        role="none"
        className={
          hasChildren && !parentOption ? "option-with-more" : undefined
        }
      >
        {option.url ? (
          <Link role="menuitem" to={option.url} title={option.tooltip}>
            {optionContent}
          </Link>
        ) : (
          <button
            role="menuitem"
            className="option"
            onClick={() => option.onClick && option.onClick(option)}
            title={option.tooltip}
          >
            {optionContent}
          </button>
        )}
        {hasChildren && !parentOption ? (
          <Button
            type="icon"
            className="more-btn"
            icon="arrow_forward_ios"
            onClick={() => setSelectedOption(option)}
            title="Show sub menu"
            aria-label="Show sub menu"
          />
        ) : null}
      </li>
    );
  }

  return (
    <ul role="menubar">
      {selectedOption ? (
        <li role="none">
          <button
            className="option menu-back-btn"
            onClick={() => setSelectedOption(null)}
            title="Return to main menu"
            aria-label="Return to main menu"
          >
            <Icon name="arrow_back_ios" />
            {OptionContent(selectedOption)}
          </button>
        </li>
      ) : (
        props.profileLink &&
        props.profileName &&
        createOption(
          {
            name: props.profileName,
            url: props.profileLink,
            icon: "account_circle",
          },
          null,
          false
        )
      )}
      {(selectedOption ? selectedOption.children : props.options)?.map(
        (option) => createOption(option, selectedOption, true)
      )}
    </ul>
  );
}

/**
 * A navigation menu designed for the header.
 */
export function NavMenu(props: NavMenuProps): JSX.Element {
  const hasOptions = !!(props.options && props.options.length);
  if (
    !hasOptions &&
    (props.type === "large" || !props.profileLink || !props.profileName)
  ) {
    return <></>;
  }

  let className = "menu menu-" + props.type;
  if (props.className) {
    className += " " + props.className;
  }

  return (
    <nav id={props.id} className={className}>
      {props.type === "large" ? LargeNavMenu(props) : SmallNavMenu(props)}
    </nav>
  );
}

export default NavMenu;
