import { useId, useState } from "react";
import FieldOption from "../../models/FieldOption";
import RadioButtonBox from "../radio-button-box/RadioButtonBox";
import RadioButton from "../radio-button/RadioButton";
import FieldError from "../field-error/FieldError";

/**
 * The properties for the {@link RadioButtonGroup} component.
 */
export interface RadioButtonGroupProps {
  /** The DOM id to use for the container. */
  id?: string;

  /** The field container class name. */
  className?: string;

  /** The label to display for this field. */
  label: string | JSX.Element;

  /** The DOM name attribute value. */
  name: string;

  /** The options to display. */
  options: FieldOption[];

  /** The style of radio button. */
  type?: "normal" | "box";

  /** The selected value. */
  value?: string;

  /** The radio button on hover title. */
  title?: string;

  /** Flag indicating if the radio button group is disabled or not. */
  disabled?: boolean;

  /** Flag indicating if the radio button group value is required. */
  required?: boolean;

  /** Flag indicating if the radio button group is invalid (i.e. has an error). */
  hasError?: boolean;

  /** An error message to display. */
  error?: string | JSX.Element;

  /** An event handler triggered when the user changes the field value. */
  onChange?: (value: string | null) => any;

  /** An event handler triggered when the user focuses on the field. */
  onFocus?: (value: string | null, isFirstVisit: boolean) => any;

  /** An event handler triggered when the user removes focus from the field. */
  onBlur?: (value: string | null) => any;

  "aria-describedby"?: string;
}

/**
 * A group of radio buttons (normal or boxes)
 */
export function RadioButtonGroup(props: RadioButtonGroupProps): JSX.Element {
  const id = useId();
  const [value, setValue] = useState<string | null>(props.value ?? null);
  const [isVisited, setIsVisited] = useState(false);

  function createOnChange(opt: FieldOption) {
    return () => {
      setValue(opt.value);
      props.onChange && props.onChange(opt.value);
    };
  }

  function createOnFocus() {
    return () => {
      const isFirstVisit = !isVisited;
      if (isFirstVisit) {
        setIsVisited(true);
      }
      props.onFocus && props.onFocus(value, isFirstVisit);
    };
  }

  function createOnBlur() {
    return () => props.onBlur && props.onBlur(value);
  }

  let className = "fieldset";
  if (props.className) {
    className += " " + props.className;
  }
  return (
    <fieldset
      id={props.id}
      className={className}
      aria-describedby={props["aria-describedby"]}
    >
      <legend>
        {props.label}
        {props.required && <span className="c-fb-neg-dark"> *</span>}
      </legend>
      <div
        className={
          props.type === "box"
            ? " radio-button-box-group"
            : " radio-button-group"
        }
      >
        {props.type === "box"
          ? props.options.map((opt) => {
              return (
                <RadioButtonBox
                  key={opt.value}
                  checked={opt.value === value}
                  name={props.name}
                  label={opt.label}
                  required={props.required || opt.required}
                  disabled={props.disabled || opt.disabled}
                  hasError={props.hasError || opt.hasError}
                  onChange={createOnChange(opt)}
                  onFocus={createOnFocus()}
                  onBlur={createOnBlur()}
                  aria-describedby={
                    props.error ? id + "-error" : props["aria-describedby"]
                  }
                />
              );
            })
          : props.options.map((opt) => {
              return (
                <RadioButton
                  key={opt.value}
                  checked={opt.value === value}
                  name={props.name}
                  label={opt.label}
                  required={props.required || opt.required}
                  disabled={props.disabled || opt.disabled}
                  hasError={props.hasError || opt.hasError}
                  onChange={createOnChange(opt)}
                  onFocus={createOnFocus()}
                  onBlur={createOnBlur()}
                  aria-describedby={
                    props.error ? id + "-error" : props["aria-describedby"]
                  }
                />
              );
            })}
      </div>
      {props.error && <FieldError id={id + "-error"} error={props.error} />}
    </fieldset>
  );
}

export default RadioButtonGroup;
