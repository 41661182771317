/**
 * Checks if a given Firestore path segment (i.e. collection or document ID)
 * is valid based on the rules set out by Google in
 * https://firebase.google.com/docs/firestore/quotas#collections_documents_and_fields.
 * @param {string} segment the Firestore path segment to check.
 * @param {boolean} allowSpecialKeys if true, special path segments that match __.*__
 * will be valid.
 * @return {boolean} true if and only if the the path segment is valid.
 */
export function isValidPathSegment(
  segment: string,
  allowSpecialKeys = false
): boolean {
  if (
    !segment ||
    segment === "." ||
    segment === ".." ||
    segment.length > 1500 ||
    segment.indexOf("/") >= 0 ||
    (!allowSpecialKeys && /^__.*__$/.test(segment))
  ) {
    return false;
  }
  return true;
}

/**
 * Validates an email address.
 * @param {string} email the email to check.
 * @return {boolean} true if the email is valid.
 */
export function isValidEmail(email: string): boolean {
  return (
    email.length > 3 &&
    /^[a-z\d._-]+(\+[a-z\d._-]+)?@[a-z\d-]+\.[a-z\d\-.]+$/i.test(email)
  );
}
