import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import {
  getAuth,
  Auth,
  onAuthStateChanged,
  signOut,
  User,
  getIdToken,
  IdTokenResult,
} from "firebase/auth";
import { Functions, getFunctions } from "firebase/functions";
import {
  getFirestore,
  getDoc,
  Firestore,
  setDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";

import { MFAUser, Space } from "@formatlas/types";
import { toast } from "@formatlas/react";
import environment from "environment";
import * as db from "./db";
import { spaceManager } from "./SpaceManager";
import APIManager from "./APIManager";

export type OnUserReadyCallback = (
  userDoc: MFAUser,
  user: User,
  app: AppManager
) => void;

export type OnSpacesChangeCallback = (spaces: Space[], app: AppManager) => void;

/**
 * Wraps a {@link FirebaseApp} object and gets the user information.
 */
export class AppManager {
  private app: FirebaseApp;

  private auth: Auth;

  private db: Firestore;

  private functions: Functions;

  private isAuthReady: boolean = false;

  private user: User | null = null;

  private token: IdTokenResult | null = null;

  private userDoc: MFAUser | null = null;

  private hasUserDoc = false;

  private userSpaces: Space[] = [];

  private isLoadingSpaces = false;

  private lastSpacesSet = 0;

  private onAuthReady?: (app: AppManager, user: User | null) => void;

  private onUserReadyCallbacks: OnUserReadyCallback[] = [];

  private onSpacesChangeCallbacks: OnSpacesChangeCallback[] = [];

  constructor(config?: FirebaseOptions) {
    const c = config ? config : environment.firebaseConfig;
    const app = initializeApp(c);
    this.app = app;
    this.auth = getAuth(this.app);
    this.db = getFirestore();
    this.functions = getFunctions(this.app);
    onAuthStateChanged(this.auth, (user) => {
      if (!this.isAuthReady) this.isAuthReady = true;
      this.user = user;
      if (this.onAuthReady) this.onAuthReady(this, user);
      if (!user || !user.emailVerified) return;

      // Get token data
      this.updateToken()
        .then(() => {
          if (this.userDoc) {
            this.handleOnUserReady();
          }

          // Get the spaces
          this.loadSpaces();
        })
        .catch((error) => {
          console.error("Failed to parse user token.", error);
          toast.add({ label: "Failed to get user info.", type: "error" });
        });

      // Handle creating the user document if required
      this.getFreshFirestoreUser()
        .then((userDoc) => {
          // New user
          if (!userDoc) {
            this.initUserDoc();
          }

          // Existing user
          else {
            this.handleOnUserReady();
          }
        })
        .catch((error) => {
          console.error("Failed to get user info.", error);
          toast.add({ label: "Failed to get user info.", type: "error" });
          this.loadSpaces();
        });
    });
  }

  /**
   * Initializes the user document, or waits for it to be initialized by the
   * backend. User document creation follows the process below:
   * 1. UI creates an empty user document in `/users/${userId}`
   * 1. Cloud Function `MFA_onUserCreated` is called and populates it with
   * all expected fields
   * 1. UI listens for the document update by backend and continues with normal
   * flow once the document data is populated
   */
  private async initUserDoc() {
    const docRef = this.getUserDocRef();
    if (!this.hasUserDoc) {
      await setDoc(docRef, {});
    }
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const data = doc.data();
      if (!data || !Object.keys(data).length) {
        return;
      }
      data.id = this.user?.uid;
      const userDoc = data as MFAUser;
      this.userDoc = userDoc;
      this.hasUserDoc = true;
      this.handleOnUserReady();
      unsubscribe();
    });
  }

  private handleOnUserReady() {
    const app = this;
    this.onUserReadyCallbacks.forEach((callback) => {
      if (!app.userDoc || !app.user) return;
      callback(app.userDoc, app.user, app);
    });
  }

  private async loadSpaces(isFirst = true) {
    const user = this.user;
    if (!user) return;
    this.isLoadingSpaces = true;
    await spaceManager
      .getSpaces()
      .then((spaces) => {
        this.setUserSpaces(spaces);
      })
      .catch(async (error) => {
        if (isFirst) {
          console.warn("Failed to load spaces.", error);
          try {
            await getIdToken(user, true);
            await this.loadSpaces(false);
          } catch (e) {
            console.error("Failed to refresh user token.", e);
            toast.add({ label: "Failed to load spaces.", type: "error" });
          }
        } else {
          toast.add({ label: "Failed to load spaces.", type: "error" });
          console.error("Failed to load spaces.", error);
        }
      })
      .finally(() => (this.isLoadingSpaces = false));
  }

  public getApp(): FirebaseApp {
    return this.app;
  }

  public getAuth(): Auth {
    return this.auth;
  }

  public getFirestore(): Firestore {
    return this.db;
  }

  public getFunctions(): Functions {
    return this.functions;
  }

  public getAPIManager(): APIManager {
    return new APIManager(this.getFunctions());
  }

  public getIsAuthReady(): boolean {
    return this.isAuthReady;
  }

  public getUser(): User | null {
    return this.user;
  }

  public getToken(): IdTokenResult | null {
    return this.token;
  }

  public async updateToken(
    forceRefresh = false
  ): Promise<IdTokenResult | null> {
    const user = this.user;
    if (!user) return null;
    const result = await user.getIdTokenResult(forceRefresh);
    this.token = result;
    return result;
  }

  /**
   * Gets the user data (document), which was set on init or by getting a
   * fresh Firestore user.
   *
   * @returns the app manager cached user data from Firestore, or null if no
   * user is logged in.
   * @see {@link getFreshFirestoreUser}
   */
  public getUserDoc(): MFAUser | null {
    return this.userDoc;
  }

  /**
   * Creates a Firestore document reference to the current user's My Form Atlas
   * user details.
   *
   * @returns the user document reference.
   * @throws an error if the user is not logged in or the auth is not ready.
   */
  public getUserDocRef() {
    if (!this.user) {
      throw new Error("No user is logged in.");
    }
    return db.user(this.user.uid);
  }

  /**
   * Gets the spaces which the user is a member of and can access.
   *
   * @returns the user spaces.
   */
  public getUserSpaces(): Space[] {
    return this.userSpaces;
  }

  /**
   * Updates the user spaces and calls event handlers listening for
   * `onSpacesChange` events.
   *
   * @param spaces the new spaces the user belongs to.
   * @see {@link onSpacesChange}
   */
  public setUserSpaces(spaces: Space[]): void {
    this.userSpaces = spaces;
    this.lastSpacesSet = Date.now();
    this.onSpacesChangeCallbacks.forEach((callback) => {
      callback(spaces, this);
    }, this);
  }

  /**
   * Gets the last time from `Date.now` when the spaces were last updated for
   * the user. This value can be used to determine how stale the space
   * information is for the user.
   *
   * @returns the last time the user spaces were updated.
   */
  public getLastSpacesSetTime(): number {
    return this.lastSpacesSet;
  }

  /**
   * Gets the flag indicating if the app manager is loading information on the
   * user spaces.
   *
   * @returns the flag.
   */
  public isLoadingUserSpaces(): boolean {
    return this.isLoadingSpaces;
  }

  /**
   * Gets the unique user ID associated with the Firebase user.
   *
   * @returns the unique user ID or an empty string, if not logged in.
   */
  public getUID(): string {
    return this.user ? this.user.uid : "";
  }

  /**
   * Checks if the user is logged in. If this function is called before
   * Firebase auth is initialized, it will return false.
   *
   * @returns true if and only if the user is logged in.
   */
  public isLoggedIn(): boolean {
    return !!this.user;
  }

  /**
   * Logs out the current user.
   */
  public logout(): Promise<void> {
    return signOut(getAuth());
  }

  /**
   * The callback which is called once the Firebase auth is ready.
   *
   * @param onAuthReady the callback.
   */
  public setOnAuthReady(
    onAuthReady?: (app: AppManager, user: User | null) => void
  ) {
    this.onAuthReady = onAuthReady;
    if (onAuthReady && this.isAuthReady) {
      onAuthReady(this, this.user);
    }
  }

  /**
   * Adds a callback which is called once the user document is loaded
   * (even if the document is null). If the document us already loaded when
   * this function is called, the callback is called with the details.
   *
   * @param onUserReady the callback.
   * @returns a function that when called, will unsubscribe the event listener.
   */
  public onUserReady(onUserReady: OnUserReadyCallback): () => void {
    this.onUserReadyCallbacks.push(onUserReady);
    if (this.userDoc && this.user) {
      onUserReady(this.userDoc, this.user, this);
    }
    return () => {
      this.removeOnUserReady(onUserReady);
    };
  }

  /**
   * Removes an event listener for the `onUserReady` event.
   *
   * @param onUserReady the callback to remove.
   */
  public removeOnUserReady(onUserReady: OnUserReadyCallback): void {
    this.onUserReadyCallbacks = this.onUserReadyCallbacks.filter(
      (callback) => callback !== onUserReady
    );
  }

  /**
   * Adds a callback to listen for `onSpacesChange` events, which are triggered
   * any time {@link setUserSpaces} is called.
   *
   * @param onSpacesChange the callback to add.
   * @returns a function that when called, will unsubscribe the event listener.
   */
  public onSpacesChange(onSpacesChange: OnSpacesChangeCallback): () => void {
    this.onSpacesChangeCallbacks.push(onSpacesChange);
    return () => {
      this.removeOnSpacesChange(onSpacesChange);
    };
  }

  /**
   * Removes an event listener for the `onSpacesChange` event.
   *
   * @param onSpacesChange the callback to remove.
   */
  public removeOnSpacesChange(onSpacesChange: OnSpacesChangeCallback): void {
    this.onSpacesChangeCallbacks = this.onSpacesChangeCallbacks.filter(
      (callback) => callback !== onSpacesChange
    );
  }

  /**
   * Updates the user-configurable user document data.
   * @param data the updated data.
   * @returns a promise resolved when the user document has been updated.
   */
  public async updateUser(
    data: Partial<{ firstName: string; lastName: string }>
  ) {
    const result = await updateDoc(this.getUserDocRef(), data);
    if (!this.userDoc) return result;
    if (data.firstName !== undefined) {
      this.userDoc.firstName = data.firstName;
    }
    if (data.lastName !== undefined) {
      this.userDoc.lastName = data.lastName;
    }
    return result;
  }

  /**
   * Gets the profile name of the current user.
   * @returns the user's displayName, name, email, or a short string indicating
   * no user is logged in.
   */
  public getProfileName(): string {
    if (!this.user) {
      return "(Not Logged In)";
    }
    if (this.user.displayName) {
      return this.user.displayName;
    }
    const userDoc = this.userDoc;
    if (userDoc && (userDoc.firstName || userDoc.lastName)) {
      return (userDoc.firstName + " " + userDoc.lastName)
        .replace(/(^\s+|\s+$)/g, "")
        .replace(/\s+/g, " ");
    }
    return this.user.email || "(No User Name)";
  }

  /**
   * Refreshes the user document from firestore.
   *
   * @returns the fresh user document.
   */
  public async getFreshFirestoreUser(): Promise<MFAUser | null> {
    if (!this.user) {
      this.userDoc = null;
      this.hasUserDoc = false;
      return null;
    }

    // Get the reference from firestore
    const user = this.user;
    const docRef = this.getUserDocRef();
    const userDoc = await getDoc(docRef);
    if (!userDoc.exists()) {
      this.userDoc = null;
      this.hasUserDoc = false;
      return null;
    }

    // Convert into usable, safe data
    const data = userDoc.data();
    this.hasUserDoc = true;
    if (!Object.keys(data).length) {
      this.userDoc = null;
      return null;
    }
    data.id = user.uid;
    const userData = data as MFAUser;

    this.userDoc = userData;

    return userData;
  }

  /**
   * Adds a size parameter to any Google user photo URL if there is no query
   * string part of the photo URL.
   *
   * @param url the user's photo URL.
   * @returns the updated URL.
   */
  public static addSizeToGoogleProfilePic(url: string): string {
    if (
      url.indexOf("googleusercontent.com") !== -1 &&
      url.indexOf("?") === -1
    ) {
      return url + "?sz=150";
    }
    return url;
  }

  /**
   * Redirects the client to the login page which will redirect to the
   * current page once the user logs in successfully.
   */
  public static redirectToSignIn(): void {
    var l = window.location;
    var loginURL =
      l.protocol +
      "//" +
      l.host +
      environment.loginURL +
      "?to=" +
      encodeURIComponent(l.pathname);
    window.location.assign(loginURL);
  }
}

/**
 * The shared app manager across the entire web app.
 */
export const app = new AppManager();

export default app;
