import { Icon } from "@formatlas/react";

import "./ContextMenuButton.css";

export interface ContextMenuButtonProps {
  title?: string;
  isMenuVisible: boolean;
  children?: any;
  onClick: () => void;
}

export function ContextMenuButton(props: ContextMenuButtonProps) {
  const { title } = props;

  return (
    <button
      className="context-menu-button"
      onClick={props.onClick}
      title={title}
      aria-label={title}
      aria-haspopup
      aria-expanded={props.isMenuVisible}
    >
      {props.children}
      <Icon name="expand_more" />
    </button>
  );
}

export default ContextMenuButton;
