import { Colour, ColourTheme, StyleDefDocData } from "@formatlas/types";
import { FEStyleEditorProps } from "../FEStyleEditor";
import {
  Accordion,
  Banner,
  Button,
  ButtonBar,
  COLOUR_BG,
  COLOUR_TEXT,
  DropDown,
  MODAL_AUTOFOCUS_CLASS,
  Modal,
  PrimaryDropDownOption,
  Table,
  chooseHighestContrastColour,
  formatNumber,
  getColourContrastRatio,
  toast,
} from "@formatlas/react";
import { useState } from "react";
import { deleteField, updateDoc } from "firebase/firestore";
import ColourCard from "src/shared/colour-card/ColourCard";
import { orderColours } from "src/utils/content";

export interface FEStyleThemeEditorProps extends FEStyleEditorProps {
  mode: "light" | "dark";
  onChange: () => void;
  onDelete?: (field: keyof StyleDefDocData) => void;
}

const UNKNOWN_COLOUR: Colour = {
  colour: "#000000",
  name: "Unknown",
};

export function FEStyleThemeEditor({
  mode,
  data,
  readonly,
  docRef,
  onChange,
  onDelete,
}: FEStyleThemeEditorProps) {
  const field: keyof StyleDefDocData =
    mode === "dark" ? "darkColours" : "lightColours";
  const title = mode === "dark" ? "Dark Theme" : "Default Theme";
  const theme = data[field];

  const [currentTheme, setCurrentTheme] = useState<ColourTheme>(
    theme ? { ...theme } : { ...data.lightColours }
  );

  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  if (!theme) {
    return <></>;
  }

  const themeColourIDs: (keyof ColourTheme)[] = [
    "c",
    "bg",
    "p",
    "s",
    "t",
    "url",
    "dbg",
    "df",
    "inbg",
    "inf",
    "sbg",
    "sf",
    "wbg",
    "wf",
    "ebg",
    "ef",
  ];
  const mainColourIDs: (keyof ColourTheme)[] = [
    "c",
    "bg",
    "p",
    "s",
    "t",
    "url",
  ];
  const feedbackColourLabels = {
    d: "Disabled",
    in: "Information",
    s: "Success",
    w: "Warning",
    e: "Error",
  };
  const feedbackColours: (keyof typeof feedbackColourLabels)[] = [
    "d",
    "in",
    "s",
    "w",
    "e",
  ];
  const themeColours = themeColourIDs.reduce<{ [id: string]: Colour }>(
    (result, id) => {
      result[id] = data.allColours[currentTheme[id]];
      return result;
    },
    {}
  );
  const colourLabels: {
    [Property in keyof ColourTheme]: string;
  } = {
    c: "Text",
    bg: "Background",
    p: "Primary",
    s: "Secondary",
    t: "Tertiary",
    url: "Links",
    dbg: "Disabled (Background)",
    df: "Disabled (Foreground)",
    inbg: "Information Feedback (Background)",
    inf: "Information Feedback (Foreground)",
    sbg: "Success Feedback (Background)",
    sf: "Success Feedback (Foreground)",
    wbg: "Warning Feedback (Background)",
    wf: "Warning Feedback (Foreground)",
    ebg: "Error Feedback (Background)",
    ef: "Error Feedback (Foreground)",
  };

  const rawColours = themeColourIDs.reduce<{ [id: string]: string }>(
    (result, id) => {
      result[id] = (themeColours[id] || UNKNOWN_COLOUR).colour;
      return result;
    },
    {}
  );

  const allColours = orderColours(data.allColours);
  const colourOptions = allColours.map<PrimaryDropDownOption>((c) => {
    const { colour, name } = c.value;
    return {
      value: c.id,
      label: (name ? name + " - " : "") + colour.toUpperCase(),
    };
  });

  function createSample(colour: string) {
    const c = rawColours[colour];
    return (
      <span
        key={colour}
        className="p-s br-s"
        style={{
          background: c,
          color: chooseHighestContrastColour(c, COLOUR_BG, COLOUR_TEXT),
        }}
      >
        {colourLabels[colour as keyof typeof colourLabels]}
      </span>
    );
  }

  let contrastCheckFailAACount = 0;

  const contrastChecks = [
    { name: "Text vs Background", c1: "c", c2: "bg" },
    { name: "Links vs Background", c1: "url", c2: "bg" },
  ].map((v, i) => {
    const c1 = rawColours[v.c1];
    const c2 = rawColours[v.c2];
    const contrast = getColourContrastRatio(c1, c2);

    function checkContrast(min: number, isAA: boolean) {
      if (contrast < min) {
        if (isAA) contrastCheckFailAACount++;
        return false;
      }
      return true;
    }
    return {
      ...v,
      c1Colour: c1,
      c2Colour: c2,
      contrast,
      textAA: checkContrast(4.5, true),
      textAAA: checkContrast(7, false),
      largeTextAA: checkContrast(3, true),
      largeTextAAA: checkContrast(4.5, false),
    };
  });

  function getContrastTestTag(pass: boolean, isAA: boolean) {
    return (
      <span>
        <span
          className={
            "fe-se-theme-wcag-test-tag br-s inline-block " +
            (pass ? "pass" : "fail")
          }
        >
          AA{isAA ? "" : "A"} - {pass ? "Pass" : "Fail"}
        </span>
      </span>
    );
  }

  return (
    <div className="relative card m-s">
      <div className="container-flex ai-center jc-between mb-s">
        <h4>{title}</h4>
        {onDelete && !readonly && (
          <Button
            type="icon"
            icon="delete"
            title="Delete this theme"
            aria-label="Delete this theme"
            onClick={() => setShowConfirmDelete(true)}
          />
        )}
      </div>
      {!readonly && (
        <ButtonBar className="mt-xs mb-m">
          {!isEditing && (
            <Button
              type="tertiary"
              icon="edit"
              onClick={() => setIsEditing(true)}
            >
              Edit Theme
            </Button>
          )}
          {isEditing && (
            <>
              <Button
                type="primary"
                disabled={isUpdating}
                onClick={() => {
                  setIsUpdating(true);
                  updateDoc(docRef, { [field]: currentTheme })
                    .then(() => {
                      data[field] = { ...currentTheme };
                      toast.add({
                        label: "Successfully updated theme.",
                        type: "success",
                      });
                      setIsEditing(false);
                      onChange();
                    })
                    .catch((err) => {
                      console.error("Failed to update " + title + ".", err);
                      toast.add({
                        label: "Failed to update theme.",
                        type: "error",
                      });
                    })
                    .finally(() => setIsUpdating(false));
                }}
              >
                Update
              </Button>
              <Button
                type="tertiary"
                disabled={isUpdating}
                onClick={() => {
                  setCurrentTheme({ ...theme });
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </ButtonBar>
      )}
      {!isEditing && (
        <>
          <h5 className="mt-s mb-xxs">Main Colours</h5>
          <div className="container-flex gap-xxs wrap">
            {mainColourIDs.map((id) => (
              <ColourCard
                key={id}
                colour={themeColours[id] || UNKNOWN_COLOUR}
                label={colourLabels[id]}
              />
            ))}
          </div>
          {feedbackColours.map((id) => {
            const label = feedbackColourLabels[id];
            return (
              <div key={id} className="m-s">
                <h5 className="mb-xxs">{label} Feedback Colours</h5>
                <div className="container-flex gap-xs ai-center mb-xxs">
                  <span className="fe-se-theme-label">Background</span>
                  <ColourCard
                    colour={themeColours[id + "bg"] || UNKNOWN_COLOUR}
                  />
                </div>
                <div className="container-flex gap-xs ai-center">
                  <span className="fe-se-theme-label">Foreground</span>
                  <ColourCard
                    colour={themeColours[id + "f"] || UNKNOWN_COLOUR}
                  />
                </div>
              </div>
            );
          })}
        </>
      )}
      {isEditing && (
        <div className="container-flex wrap">
          {themeColourIDs.map((id, i) => {
            const selected = rawColours[id];
            const padding = (i % 2 ? "p-l-s" : "p-r-s") + " s-p-lr-0";
            return (
              <div
                key={id}
                className={
                  "container-flex m-xs flex gap-xxs ai-end col-50 s-col-100 " +
                  padding
                }
              >
                <DropDown
                  className="grow"
                  label={colourLabels[id]}
                  options={colourOptions}
                  value={currentTheme[id]}
                  onChange={(value) =>
                    setCurrentTheme((v) => ({ ...v, [id]: value }))
                  }
                />
                <span
                  className="fe-se-theme-colour br-s"
                  style={{ background: selected }}
                  aria-hidden
                ></span>
              </div>
            );
          })}
        </div>
      )}
      <Accordion className="m-s" title="Sample Theme">
        <div
          className="p-s br-s fe-se-theme-sample"
          style={{ background: rawColours.bg, color: rawColours.c }}
          aria-hidden
        >
          <p className="font-h3 mb-xs">Sample</p>
          <p>
            This is a sample of what the theme will look like based on the
            colours above. The sample includes{" "}
            <span style={{ color: rawColours.url }} className="underline">
              links
            </span>{" "}
            and other components below:
          </p>
          <div className="container-flex gap-xs mt-xs">
            {["p", "s", "t"].map(createSample)}
          </div>
        </div>
      </Accordion>
      <Accordion title="Accessibility Tests">
        <Table
          caption="WCAG Accessibility Contrast Checks"
          cols={[
            { id: "name", title: "Test Name", format: (v) => v.name },
            {
              id: "sample",
              title: "Sample",
              format: (v) => (
                <span
                  className="p-s block br-s"
                  style={{ color: v.c1Colour, background: v.c2Colour }}
                >
                  Sample Text
                </span>
              ),
              displayRules: [{ comparison: ">", value: 500 }],
            },
            {
              id: "ratio",
              title: "Contrast Ratio",
              format: (v) => formatNumber(v.contrast, 2, 2),
            },
            {
              id: "normal-wcag",
              title: "Normal Text",
              format: (v) => (
                <span className="container-flex wrap gap-xxs fd-col">
                  {getContrastTestTag(v.textAA, true)}
                  {getContrastTestTag(v.textAAA, false)}
                </span>
              ),
            },
            {
              id: "large-wcag",
              title: "Large Text",
              format: (v) => (
                <span className="container-flex wrap gap-xxs fd-col">
                  {getContrastTestTag(v.largeTextAA, true)}
                  {getContrastTestTag(v.largeTextAAA, false)}
                </span>
              ),
            },
          ]}
          data={contrastChecks.map((value, i) => {
            return { id: i, value };
          })}
        />
      </Accordion>
      {contrastCheckFailAACount > 0 && (
        <Banner type="warning" className="m-m">
          <p>
            {contrastCheckFailAACount} WCAG AA contrast accessibility test
            {contrastCheckFailAACount === 1 ? " fails" : "s fail"} with the
            selected colours. Using these colours may make it hard for certain
            people to use forms or other content.
          </p>
        </Banner>
      )}
      {onDelete && showConfirmDelete && (
        <Modal
          render={(close) => (
            <>
              <h2>Confirm Delete Theme</h2>
              <p className="mt-s mb-l">
                You are about to delete the {title}, which cannot be undone. Are
                you sure?
              </p>
              <ButtonBar align="right">
                <Button
                  type="tertiary"
                  className={MODAL_AUTOFOCUS_CLASS}
                  onClick={() => close()}
                  disabled={isDeleting}
                >
                  Cancel
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    setIsDeleting(true);
                    updateDoc(docRef, { [field]: deleteField() })
                      .then(() => {
                        onDelete && onDelete(field);
                        close();
                      })
                      .catch((err) => {
                        console.error("Failed to delete " + title + ".", err);
                        toast.add({
                          label: "Failed to delete theme.",
                          type: "error",
                        });
                      })
                      .finally(() => setIsDeleting(false));
                  }}
                  disabled={isDeleting}
                >
                  Delete Theme
                </Button>
              </ButtonBar>
            </>
          )}
          onClose={(e) => {
            e.focus(e.original);
            setShowConfirmDelete(false);
          }}
        />
      )}
    </div>
  );
}

export default FEStyleThemeEditor;
