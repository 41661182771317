/**
 * Wraps content in a heading element (h1 - h6).
 * @param level the heading level (1 - 6, which correspond to h1 - h6).
 * @param content the content to put in the heading.
 * @param className an option class name for the heading.
 * @returns the content wrapped in a heading, or the content if no valid level
 * was provided.
 */
export function wrapInHeading(
  level: number,
  content: JSX.Element,
  className?: string
): JSX.Element {
  if (level === 1) return <h1 className={className}>{content}</h1>;
  if (level === 2) return <h2 className={className}>{content}</h2>;
  if (level === 3) return <h3 className={className}>{content}</h3>;
  if (level === 4) return <h4 className={className}>{content}</h4>;
  if (level === 5) return <h5 className={className}>{content}</h5>;
  if (level === 6) return <h6 className={className}>{content}</h6>;
  return content;
}
