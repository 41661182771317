import React from "react";
import { createPortal } from "react-dom";
import Toasts, { ToastSetter } from "../toasts/Toasts";
import Toast from "../../models/Toast";

/**
 * The toast manager keeps track of a list of toasts that are currently being
 * displayed on the page.
 *
 * To add the toasts to the UI, please call the {@link renderAllToasts} method
 * in a top-level component (e.g. `App`).
 */
export class ToastManager {
  /** The default amount of time a toast will display for, in milliseconds. */
  private duration = 3500;

  /** The toasts currently being displayed. */
  private toasts: Toast[] = [];

  /** A numerical tracker to generate a toast ID. */
  private lastToast = 0;

  /** A function to call when a toast is added or removed. */
  private setter: ToastSetter | null = null;

  /**
   * Adds a new toast to the screen.
   * @param toast a toast to add.
   * @returns a reference to this toast manager.
   */
  public add(toast: Toast): ToastManager {
    const id = "t" + this.lastToast++;
    toast.id = id;
    toast.duration =
      toast.duration && toast.duration > 0 && !isNaN(toast.duration)
        ? toast.duration
        : this.duration;
    this.toasts.push(toast);
    if (this.setter) this.setter(this.toasts);
    setTimeout(() => {
      this.remove(toast);
    }, toast.duration);
    return this;
  }

  /**
   * Removes a toast from the screen.
   * @param toast the toast to remove.
   * @returns a reference to this toast manager.
   */
  public remove(toast: Toast): ToastManager {
    const index = this.toasts.indexOf(toast);
    if (index >= 0) {
      this.toasts.splice(index, 1);
      if (this.setter) this.setter(this.toasts);
    }
    return this;
  }

  /**
   * Removes all toasts.
   * @returns a reference to this toast manager.
   */
  public clear(): ToastManager {
    this.toasts = [];
    if (this.setter) this.setter(this.toasts);
    return this;
  }

  /**
   * Gets all toasts.
   * @returns a list of toasts being tracked by this toast manager.
   */
  public getToasts(): Toast[] {
    return this.toasts;
  }

  /**
   * Gets the toast default duration.
   * @returns the default duration a toast will display for, in milliseconds.
   */
  public getToastDuration(): number {
    return this.duration;
  }

  /**
   * Sets the default duration a toast will display for, in milliseconds.
   * @param duration the default toast duration.
   * @returns a reference to this toast manager.
   */
  public setToastDuration(duration: number): ToastManager {
    if (duration <= 0 || isNaN(duration)) {
      throw new Error("Duration must be greater than 0.");
    }
    this.duration = duration;
    return this;
  }

  /**
   * Creates a portal to render all toasts.
   * @returns a portal reference which is rendering all this toasts.
   */
  public renderAllToasts(): React.ReactPortal {
    return createPortal(
      <Toasts onUpdate={(setter) => (this.setter = setter)} />,
      document.body
    );
  }
}

/** The primary toast manager for the app. */
export const toast = new ToastManager();

export default ToastManager;
