import { Table, Icon } from "@formatlas/react";
import { Link } from "react-router-dom";
import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";
import SmartNavBar from "src/shared/smart-nav-bar/SmartNavBar";
import SpaceSelect from "src/shared/space-select/SpaceSelect";
import FEBreadcrumbs from "../fe-breadcrumbs/FEBreadcrumbs";

export interface FEAppHomeProps {}

export function FEAppHome(props: FEAppHomeProps): JSX.Element {
  return (
    <>
      <ProfileAppHeader title="Form Editor" />
      <SmartNavBar />
      <main className="container-max-width">
        <SpaceSelect />
        <FEBreadcrumbs parts={[]} />
        <Table
          className="m-top-m m-bottom-l"
          caption="Folders"
          cols={[
            {
              id: "folder",
              title: "Folder",
              format: (v) => (
                <Link to={v.url} className="icon-start">
                  <Icon name={v.icon} />
                  {v.text}
                </Link>
              ),
              useHeader: true,
              style: { minWidth: 130 },
            },
            {
              id: "info",
              title: "Info",
              format: (v) => v.details,
            },
          ]}
          data={[
            {
              icon: "web",
              url: "./forms",
              text: "Forms",
              details: "All forms.",
            },
            {
              icon: "folder_shared",
              url: "./ui",
              text: "UI",
              details: "Shared user interface components.",
            },
            {
              icon: "palette",
              url: "./styles",
              text: "Styles",
              details: "Shared styles and themes.",
            },
            {
              icon: "image",
              url: "./res",
              text: "Resources",
              details: "Shared images, media, and files.",
            },
          ].map((value) => ({
            id: value.url,
            value,
          }))}
        />
      </main>
    </>
  );
}

export default FEAppHome;
