import { useId, useState } from "react";
import Icon from "../icon/Icon";
import { wrapInHeading } from "../../utils/headings";

/**
 * The properties for the {@link Accordion} component.
 */
export interface AccordionProps {
  /** The DOM id to use for the container. */
  id?: string;

  /** The field container class name. */
  className?: string;

  /** The accordion title that is always displayed. */
  title?: string;

  /** The heading level for the accordion header. */
  heading?: 1 | 2 | 3 | 4 | 5 | 6;

  /** Content that appears right-aligned on the accordion title. */
  otherTitleContent?: JSX.Element;

  /** Flag indicating if the accordion is expanded. */
  expanded?: boolean;

  /** Flag indicating if the accordion content should be marked with role="region". */
  contentAsRegion?: boolean;

  children?: any;
}

export function Accordion(props: AccordionProps): JSX.Element {
  const buttonID = useId(),
    sectionID = useId();
  const [expanded, setExpanded] = useState(props.expanded ?? false);

  let className = "accordion";
  if (props.className) {
    className += " " + props.className;
  }

  const titleContent = (
    <button
      id={buttonID}
      className="accordion-toggle"
      onClick={() => {
        setExpanded(!expanded);
      }}
      aria-expanded={expanded}
      aria-controls={sectionID}
    >
      <Icon name={expanded ? "expand_less" : "expand_more"} />
      {props.title}
    </button>
  );

  return (
    <div id={props.id} className={className}>
      <div className="container-title">
        {props.heading
          ? wrapInHeading(props.heading, titleContent)
          : titleContent}
        {props.otherTitleContent}
      </div>
      <div
        id={sectionID}
        className="container-content"
        style={{ display: expanded ? "block" : "none" }}
        role={props.contentAsRegion ? "region" : undefined}
        aria-labelledby={buttonID}
        hidden={!expanded}
      >
        {props.children}
      </div>
    </div>
  );
}

export default Accordion;
