import { useContext } from "react";
import { Link } from "react-router-dom";
import Icon from "../icon/Icon";
import PageContext from "../../../context/page-context/PageContext";

/**
 * Represents an individual breadcrumb to display.
 */
export interface Breadcrumb {
  /** The URI that the breadcrumb links to. */
  uri: string;
  /** An icon to display to the left of the title. */
  icon?: string;
  /** The breadcrumb title. */
  title: string;
  /** A hover tooltip to display. */
  tooltip?: string;
}

/**
 * The properties for the {@link Breadcrumbs} component.
 */
export interface BreadcrumbsProps {
  /** Extra classes to be added to the component. */
  className?: string;
  /** The breadcrumbs to display. */
  parts: Breadcrumb[];
  /**
   * If true, all breadcrumb links are relative to the last current page URL
   * set in the {@link PageContext} (or PageContainer).
   */
  relative?: boolean;
  /** An accessible label for the breadcrumbs. */
  "aria-label"?: string;
}

/**
 * Creates breadcrumbs that allow the user to easily navigate to higher-levl
 * pages.
 */
export function Breadcrumbs(props: BreadcrumbsProps) {
  const { current } = useContext(PageContext);
  const { parts } = props;

  const baseURL = (props.relative && current?.url) || "";

  let className = "breadcrumbs";
  if (props.className) {
    className += " " + props.className;
  }

  let lastPath = baseURL;

  return (
    <nav className={className} aria-label={props["aria-label"]}>
      <ol>
        {parts.map((part, i) => {
          const isLast = i === parts.length - 1;
          const uri = part.uri;
          let path = `${lastPath}/${uri}`;
          if (uri.charAt(0) === "/") {
            path = `${baseURL}${uri}`;
          } else if (!uri || lastPath.charAt(lastPath.length - 1) === "/") {
            path = `${lastPath}${uri}`;
          }
          lastPath = path;
          return (
            <li key={path}>
              <Link
                to={isLast ? {} : path}
                className={"breadcrumb"}
                aria-current={isLast ? "page" : undefined}
                title={part.tooltip}
              >
                {part.icon && <Icon name={part.icon} />}
                {part.title}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
