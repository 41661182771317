import { Tabs } from "@formatlas/react";
import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";
import SmartNavBar from "src/shared/smart-nav-bar/SmartNavBar";
import SpaceSelect from "src/shared/space-select/SpaceSelect";
import GeneralSettings from "./general-settings/GeneralSettings";

export interface SpaceSettingsAppProps {}

export function SpaceSettingsApp(props: SpaceSettingsAppProps): JSX.Element {
  return (
    <>
      <ProfileAppHeader title="Space Settings" />
      <SmartNavBar />
      <main className="container-max-width m-bottom-l">
        <SpaceSelect />
        <Tabs
          tabs={[
            {
              id: "general",
              title: "General",
              content: <GeneralSettings />,
            },
            {
              id: "users",
              title: "Users",
              content: <>Users content TODO</>,
            },
            {
              id: "notifications",
              title: "Notifications",
              content: <>Notifications TODO</>,
            },
          ]}
        />
      </main>
    </>
  );
}

export default SpaceSettingsApp;
