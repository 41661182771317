import { useContext, useState } from "react";
import { ContextMenu, ContextMenuOption } from "@formatlas/react";
import SpaceContext from "src/context/space-context/SpaceContext";

import "./SpaceSelect.css";
import app from "app";
import { useNavigate } from "react-router-dom";
import ContextMenuButton from "../context-menu-button/ContextMenuButton";

export interface SpaceSelectProps {}

export function SpaceSelect(props: SpaceSelectProps) {
  const navigate = useNavigate();

  const spaceContext = useContext(SpaceContext);
  const space = spaceContext.space;

  const [showMenu, setShowMenu] = useState(false);

  const max = 4;

  const spaces = app
    .getUserSpaces()
    .filter((v) => v.id !== space.id)
    .sort((a, b) =>
      a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1
    );
  const options: ContextMenuOption[][] = [
    [
      {
        id: space.id,
        name: space.displayName,
        icon: space.icon ?? "explore",
        active: true,
      },
      ...spaces
        .filter((_, index) => index < max || spaces.length === max + 1)
        .map((s) => {
          return {
            id: s.id,
            name: s.displayName,
            icon: s.icon ?? "explore",
            onClick: () => {
              navigate(`/s/${s.id}`);
            },
          };
        }),
    ],
    [
      {
        id: "all-spaces",
        name: "All spaces",
        onClick: () => {
          navigate("/");
        },
      },
    ],
  ];

  return (
    <div className="space-select grow relative m-bottom-m">
      <ContextMenuButton
        title={`Change the current space from "${space.displayName}"`}
        isMenuVisible={showMenu}
        onClick={() => setShowMenu((v) => !v)}
      >
        {space.displayName}
      </ContextMenuButton>
      {showMenu && (
        <ContextMenu options={options} onBlur={() => setShowMenu(false)} />
      )}
    </div>
  );
}

export default SpaceSelect;
