import { Breadcrumb } from "@formatlas/react";
import { ContentType } from "@formatlas/types";

export const navByContent: Record<ContentType, Breadcrumb> = {
  form: {
    uri: "forms",
    title: "Forms",
    icon: "web",
  },
  component: {
    uri: "ui",
    title: "UI",
    icon: "folder_shared",
  },
  style: {
    uri: "styles",
    title: "Styles",
    icon: "palette",
  },
  resource: {
    uri: "res",
    title: "Resources",
    icon: "image",
  },
};
