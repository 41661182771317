import { useContext, useEffect, useState } from "react";
import ContentContext from "src/context/content-context/ContentContext";
import ContentVersionContext from "src/context/content-version-context/ContentVersionContext";
import FEStyleEditor from "./FEStyleEditor";
import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";
import SmartNavBar from "src/shared/smart-nav-bar/SmartNavBar";
import FEEditorBreadcrumbs from "../FEEditorBreadcrumbs";
import { getDoc } from "firebase/firestore";
import LoadingPage from "src/pages/loading-page/LoadingPage";
import { Banner, ButtonBar, Button } from "@formatlas/react";
import ErrorPage from "src/pages/errors/error-page/ErrorPage";
import { convertTimestamps } from "src/utils/firebase-helpers";
import { StyleDefDocData } from "@formatlas/types";
import RefreshContext from "src/context/refresh-context/RefreshContext";

export interface FEFullStyleEditorProps {}

export function FEFullStyleEditor(props: FEFullStyleEditorProps) {
  const { typeLabelTitle, typeLabel, canUpdate, cm, content } =
    useContext(ContentContext);
  const { version } = useContext(ContentVersionContext);
  const contentID = content.id;
  const versionID = version.id;
  const docRef = cm.getStyleDocDefinitionRef(contentID, versionID);

  const [doc, setDoc] = useState<StyleDefDocData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getDoc(cm.getStyleDocDefinitionRef(contentID, versionID))
      .then((doc) => {
        if (!doc.exists()) {
          setDoc(null);
          setLoadError("Version not found.");
          return;
        }
        setDoc(convertTimestamps(doc.data() as StyleDefDocData));
      })
      .catch((err) => {
        setLoadError("Failed to load version information: " + err);
      })
      .finally(() => setIsLoading(false));
  }, [contentID, versionID, cm]);

  if (!doc && isLoading) {
    return <LoadingPage />;
  }
  if (!doc) {
    return (
      <ErrorPage title="Version Not Found" showNavLinks>
        <Banner type="error" className="mb-l">
          The {typeLabel} version you are looking for could not be loaded.{" "}
          {loadError || "Unknown error."}
        </Banner>
        <ButtonBar className="m-l">
          <Button linkURL="..">Return to {typeLabelTitle}</Button>
        </ButtonBar>
      </ErrorPage>
    );
  }

  return (
    <RefreshContext.Provider
      value={{
        refresh: async () => {
          const doc = await getDoc(
            cm.getStyleDocDefinitionRef(contentID, versionID)
          );
          if (!doc.exists()) {
            throw new Error("Style version data not found.");
          }
          setDoc(convertTimestamps(doc.data() as StyleDefDocData));
        },
      }}
    >
      <ProfileAppHeader title={`View ${typeLabelTitle} Version`} />
      <SmartNavBar />
      <main className="container-max-width">
        <FEEditorBreadcrumbs />
        <FEStyleEditor
          readonly={!version.draft || !canUpdate}
          data={doc}
          docRef={docRef}
        />
      </main>
    </RefreshContext.Provider>
  );
}

export default FEFullStyleEditor;
