import { Button, ContextMenu } from "@formatlas/react";
import { ContentVersion } from "@formatlas/types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export interface ContentVersionMoreMenuProps {
  version: ContentVersion;
  label: string;
}

export function ContentVersionMoreMenu(props: ContentVersionMoreMenuProps) {
  const navigate = useNavigate();

  const { version, label } = props;
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <Button
        type="icon"
        icon="more_vert"
        title={label}
        aria-label={label}
        onClick={() => setShowMenu((v) => !v)}
      />
      {showMenu && (
        <ContextMenu
          options={[
            [
              {
                id: "view",
                icon: "visibility",
                name: "View",
                tooltip: `View the version.`,
                onClick: () => {
                  navigate(`./v/${version.id}`);
                },
              },
            ],
          ]}
          start="bottom"
          align="right"
          onBlur={() => setShowMenu(false)}
        />
      )}
    </>
  );
}

export default ContentVersionMoreMenu;
