import React, { useId, useState } from "react";
import FieldError from "../field-error/FieldError";

/**
 * The properties for the {@link TextField} component.
 */
export interface TextFieldProps {
  /** The label content. */
  label?: JSX.Element | string;

  /** The DOM id to use for the container. */
  id?: string;

  /** The DOM name attribute value. */
  name?: string;

  /** The field container class name. */
  className?: string;

  /** The input type. */
  type?: "text" | "password" | "email" | "url" | "search";

  /** The input mode. */
  mode?: "text" | "decimal" | "numeric";

  /** The text field value. */
  value?: string;

  /** The text field on hover title. */
  title?: string;

  /** The text field placeholder value. */
  placeholder?: string;

  /** The text field autocomplete value. */
  autoComplete?: string;

  /** The text field spellcheck value. */
  spellCheck?: boolean;

  /** The maximum number of characters that can be entered into the field. */
  maxLength?: number;

  /** The minimum value allowed. */
  min?: string | number;

  /** The maximum value allowed. */
  max?: string | number;

  /** Flag indicating if the text field is disabled or not. */
  disabled?: boolean;

  /** Flag indicating if the text field value is required. */
  required?: boolean;

  /** Flag indicating if the text field is invalid (i.e. has an error). */
  hasError?: boolean;

  /** An error message to display. */
  error?: string | JSX.Element;

  /** An event handler triggered when the user changes the field value. */
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => any;

  /** An event handler triggered when the user focuses on the field. */
  onFocus?: (
    isFirstVisit: boolean,
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => any;

  /** An event handler triggered when the user removes focus from the field. */
  onBlur?: (
    value: string,
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => any;

  /** A function that formats the current value to a new value (e.g. to auto
   * add spaces, dashes, etc.). */
  formatter?: (value: string) => string;

  "aria-describedby"?: string;
}

/**
 * A simple text field to collect single-line input from the user.
 */
export const TextField = React.forwardRef(function (
  props: TextFieldProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const id = useId();
  const [isVisited, setIsVisited] = useState(false);

  let className = "field text-field",
    value = props.value;
  if (props.className) {
    className += " " + props.className;
  }
  if (props.hasError) {
    className += " error";
  }
  if (value !== undefined && props.formatter) {
    value = props.formatter(value);
  }
  return (
    <div id={props.id} className={className}>
      {props.label && (
        <label htmlFor={id}>
          {props.label}
          {props.required && <span className="c-fb-neg-dark"> *</span>}
        </label>
      )}
      <input
        ref={ref}
        type={props.type ?? "text"}
        inputMode={props.mode}
        id={id}
        value={value}
        name={props.name}
        title={props.title}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        spellCheck={props.spellCheck}
        maxLength={props.maxLength}
        min={props.min}
        max={props.max}
        disabled={props.disabled}
        required={props.required}
        onChange={(event) => {
          if (props.formatter) {
            event.target.value = props.formatter(event.target.value);
          }
          props.onChange && props.onChange(event.target.value, event);
        }}
        onFocus={(event) => {
          const isFirstVisit = !isVisited;
          if (isFirstVisit) {
            setIsVisited(true);
          }
          props.onFocus && props.onFocus(isFirstVisit, event);
        }}
        onBlur={(event) =>
          props.onBlur && props.onBlur(event.target.value, event)
        }
        aria-invalid={props.hasError}
        aria-describedby={
          props.error ? id + "-error" : props["aria-describedby"]
        }
      />
      {props.error && <FieldError id={id + "-error"} error={props.error} />}
    </div>
  );
});

export default TextField;
