import { createContext } from "react";
import { ContentType, ContentVersion, FAContent } from "@formatlas/types";
import ContentManager from "src/utils/ContentManager";

export interface ContentContextData {
  type: ContentType;
  typeLabel: string;
  typeLabelTitle: string;
  canUpdate: boolean;
  content: FAContent;
  versions: ContentVersion[];
  versionsLastLoaded: Date | null;
  versionLoadError?: string | null;
  refreshVersions: () => Promise<void>;
  setVersions: (versions: ContentVersion[]) => void;
  cm: ContentManager;
}

export const ContentContext = createContext<ContentContextData>({
  type: "form",
  typeLabel: "",
  typeLabelTitle: "",
  canUpdate: false,
  content: {
    id: "",
    created: new Date(),
    updated: new Date(),
    name: "",
  },
  versions: [],
  versionsLastLoaded: null,
  cm: new ContentManager(""),
  refreshVersions: async () => {},
  setVersions: () => {},
});

export default ContentContext;
