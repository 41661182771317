import { getDoc, getDocs, query, where } from "firebase/firestore";
import {
  APIRequestCreateSpace,
  APIRequestUpdateSpaceSettings,
  APIResponseCreateSpaceData,
  APIResponseUpdateSpaceSettings,
  Space,
  SpaceEnv,
  SpaceSettingsDocData,
  UserSpaceDocData,
} from "@formatlas/types";
import app, { AppManager } from "app";
import { convertTimestamps } from "./firebase-helpers";
import * as db from "./db";
import ContentManager from "./ContentManager";
import { EMEventCallback, EventManager } from "@formatlas/react";

type SMEvents = {
  onSpaceSettingsUpdated: {
    settings: SpaceSettingsDocData;
  };
};

/**
 * The Space Manager class assists with getting and updating information for
 * a specific space. It also contains static helper functions to assist with
 * listing user spaces and creating new ones.
 */
export class SpaceManager extends EventManager<SMEvents> {
  /** The current space ID. */
  private spaceID: string | null = null;

  /** The current space's settings. */
  private spaceSettings?: SpaceSettingsDocData;

  /** The space environments for the specified space. */
  private spaceEnvs?: SpaceEnv[];

  /** The current user's details for the current space. */
  private userSpace?: UserSpaceDocData;

  /** The current user's permissions for the current space. */
  private userSpacePermissionsByID?: { [permissionID: string]: boolean };

  private cm: ContentManager = new ContentManager("");

  constructor() {
    super();
    this.onSpaceChanged();
  }

  private onSpaceChanged() {
    if (this.spaceID) {
      this.cm.setSpaceID(this.spaceID);
    }
    this.spaceSettings = undefined;
    this.spaceEnvs = undefined;
    this.userSpace = undefined;
    this.userSpacePermissionsByID = undefined;
  }

  private getUserID(): string {
    const user = app.getUser();
    if (!user) {
      throw new Error("User not logged in.");
    }
    return user.uid;
  }

  /**
   * Updates the current space.
   * @param spaceID the updated space ID.
   */
  public setCurrentSpace(spaceID: string | null) {
    if (this.spaceID === spaceID) return;
    this.spaceID = spaceID;
    this.onSpaceChanged();
  }

  /**
   * Gets the current space ID.
   * @returns the current space ID, or null if no space has been set.
   */
  public getCurrentSpace() {
    return this.spaceID;
  }

  /**
   * Gets the current space ID or throws an error if one is not set.
   * @returns the current space ID.
   * @throws an error if no space ID was set.
   */
  private getID(): string {
    if (!this.spaceID) {
      throw new Error("No current space ID.");
    }
    return this.spaceID;
  }

  public getContentManager(): ContentManager | null {
    return this.spaceID ? this.cm : null;
  }

  /**
   * Gets the environments for the current space. If the data has already been
   * retrieved, the cached data is returned unless fresh data is requested.
   * @param fresh if true, the data will be read directly from Firestore.
   * @returns the environments for the current space.
   */
  public async getSpaceEnvironments(fresh?: boolean): Promise<SpaceEnv[]> {
    const spaceID = this.getID();
    if (this.spaceEnvs && !fresh) {
      return this.spaceEnvs;
    }
    const envCollection = db.spaceEnvs(spaceID);
    const q = query(envCollection);
    const results = await getDocs(q);
    const envs: SpaceEnv[] = [];
    results.forEach((result) => {
      const data = result.data();
      data.id = result.id;
      convertTimestamps(data);
      envs.push(data as SpaceEnv);
    });
    const envOrder = ["prod", "qa", "dev"];
    envs.sort((a, b) => {
      const aa = a.isAvailable,
        ba = b.isAvailable;
      if ((aa && !ba) || (!aa && ba)) {
        return aa ? -1 : 1;
      }
      if (a.env === b.env) return 0;
      const ao = envOrder.indexOf(a.env),
        bo = envOrder.indexOf(b.env);
      return ao < bo ? -1 : 1;
    });
    this.spaceEnvs = envs;

    return envs;
  }

  /**
   * Gets the space settings for the current space. If the data has already been
   * retrieved, the cached data is returned unless fresh data is requested.
   * @param fresh if true, the data will be read directly from Firestore.
   * @returns the space settings document for the current space.
   */
  public async getSpaceSettings(
    fresh?: boolean
  ): Promise<SpaceSettingsDocData> {
    const spaceID = this.getID();
    if (this.spaceSettings && !fresh) {
      return this.spaceSettings;
    }

    // Get the document
    const spaceSettingsDoc = await getDoc(db.spaceSettings(spaceID));
    if (!spaceSettingsDoc.exists()) {
      throw new Error("No space settings document found.");
    }

    // Parse the data
    const data = spaceSettingsDoc.data();
    convertTimestamps(data);
    const settings = data as SpaceSettingsDocData;
    this.spaceSettings = settings;
    this.triggerEvent("onSpaceSettingsUpdated", { settings });

    return settings;
  }

  /**
   * Sends a request to update the space settings. If successful, the space
   * settings are automatically refreshed too.
   * @param data the updated space settings information.
   * @returns the API response from updating the current space's settings.
   */
  public async updateSpaceSettings(
    data: Omit<APIRequestUpdateSpaceSettings, "spaceID">
  ): Promise<APIResponseUpdateSpaceSettings> {
    const spaceID = this.getID();

    const res = await app
      .getAPIManager()
      .updateSpaceSettings({ spaceID, ...data });
    if (!res.failed) {
      try {
        await this.getSpaceSettings(true);
      } catch (e) {
        console.warn("Failed to refresh space settings.", e);
      }
    }

    return res;
  }

  /**
   * Gets the user space details for the current space. If the data has already been
   * retrieved, the cached data is returned unless fresh data is requested.
   * @param fresh if true, the data will be read directly from Firestore.
   * @returns the user's space details for the current space.
   */
  public async getUserSpaceDetails(fresh?: boolean): Promise<UserSpaceDocData> {
    const spaceID = this.getID();
    if (this.userSpace && !fresh) {
      return this.userSpace;
    }

    // Get the document
    const userSpaceDocRef = db.userSpace(this.getUserID(), spaceID);
    const userSpaceDoc = await getDoc(userSpaceDocRef);
    if (!userSpaceDoc.exists()) {
      throw new Error("No user space document found.");
    }

    // Parse the data
    const data = userSpaceDoc.data();
    convertTimestamps(data);
    const userSpace = data as UserSpaceDocData;
    const permissions = userSpace.permissions;
    this.userSpace = userSpace;
    this.userSpacePermissionsByID = {};
    if (Array.isArray(permissions)) {
      for (let i = 0; i < permissions.length; i++) {
        const id = permissions[i];
        if (!id || typeof id !== "string") continue;
        this.userSpacePermissionsByID[id] = true;
      }
    }

    return userSpace;
  }

  /**
   * Loads the user's Space User Permissions for the current space.
   */
  public async loadUserPermissions() {
    await this.getUserSpaceDetails();
  }

  /**
   * Checks if the current user has a specific Space User Permission.
   * @param permissionID the Space User Permission ID.
   * @returns true if and only if the Space User Permissions are loaded and the
   * current user has the specified permission ID.
   * @see {@link loadUserPermissions}
   */
  public hasPermission(permissionID: string): boolean {
    return !!(
      this.userSpacePermissionsByID &&
      this.userSpacePermissionsByID[permissionID]
    );
  }

  /**
   * Gets the spaces the user is a member of based on the custom claims data
   * associated with the user's auth token.
   * @returns the spaces the user is a member of.
   */
  public async getSpaces(): Promise<Space[]> {
    const token = app.getToken();
    if (!token || !token.claims) {
      return [];
    }
    const spaceClaims = token.claims.spaces;
    if (
      !spaceClaims ||
      Array.isArray(spaceClaims) ||
      typeof spaceClaims !== "object"
    ) {
      return [];
    }
    const spaceIDs = Object.keys(spaceClaims);
    if (!spaceIDs.length) {
      return [];
    }

    const spaces: Space[] = [];
    const q = query(db.spaces(), where("__name__", "in", spaceIDs));
    const result = await getDocs(q);
    result.forEach((doc) => {
      spaces.push(convertTimestamps({ ...doc.data(), id: doc.id } as Space));
    });
    spaces.sort((a, b) =>
      a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1
    );

    return spaces;
  }

  protected onEventListenerAdded<U extends keyof SMEvents>(
    type: U,
    callback: EMEventCallback<SMEvents, U>
  ): void {
    if (type === "onSpaceSettingsUpdated" && this.spaceSettings) {
      callback({ settings: this.spaceSettings }, type);
    }
  }

  /**
   * Creates a new My Form Atlas Space.
   * @param app the app manager reference.
   * @param request the create space request data.
   * @returns the newly created space.
   */
  public static async createSpace(
    app: AppManager,
    request: APIRequestCreateSpace
  ): Promise<APIResponseCreateSpaceData> {
    const response = await app.getAPIManager().createSpace(request);
    const data = response.data;
    if (!data) {
      throw new Error("No space was created.");
    }
    app.setUserSpaces([data.space, ...app.getUserSpaces()]);
    await app.updateToken(true);

    return data;
  }
}

/** The primary space manager My Form Atlas. */
export const spaceManager = new SpaceManager();

export default SpaceManager;
