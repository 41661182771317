import { useEffect, useState } from "react";
import { Button, ButtonBar, toast } from "@formatlas/react";
import { Space } from "@formatlas/types";

import app from "app";
import { spaceManager } from "src/utils/SpaceManager";

import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";
import { SpaceCard } from "./space-card/SpaceCard";

/** The minimum amount of time that needs to pass before spaces are refreshed. */
const MIN_SPACE_REFRESH_TIME = 5 * 60 * 1000;

/**
 * The properties for the {@link HomePage} component.
 */
export interface HomePageProps {}

/**
 * The main landing page of My Form Atlas, which helps direct users to a
 * specific space, documentation, or other relevant links/tools.
 */
export default function HomePage(props: HomePageProps): JSX.Element {
  const currentSpaces = app.getUserSpaces();
  const isAppLoadingSpaces = app.isLoadingUserSpaces();
  const lastSpacesSetTime = app.getLastSpacesSetTime();
  const [isLoadingSpaces, setIsLoadingSpaces] = useState(
    currentSpaces.length === 0 &&
      !isAppLoadingSpaces &&
      Date.now() - lastSpacesSetTime >= MIN_SPACE_REFRESH_TIME
  );
  const [spaces, setSpaces] = useState<Space[]>(currentSpaces);

  useEffect(() => {
    return app.onSpacesChange((spaces) => {
      setSpaces(spaces);
      setIsLoadingSpaces(false);
    });
  }, []);

  useEffect(() => {
    if (
      isAppLoadingSpaces ||
      currentSpaces.length ||
      Date.now() - lastSpacesSetTime < MIN_SPACE_REFRESH_TIME
    ) {
      return;
    }
    spaceManager
      .getSpaces()
      .then((spaces) => {
        setSpaces(spaces);
        setIsLoadingSpaces(false);
        app.setUserSpaces(spaces);
      })
      .catch((error) => {
        setIsLoadingSpaces(false);
        console.error("Failed to load spaces.", error);
        toast.add({ label: "Failed to load spaces.", type: "error" });
      });
  }, [isAppLoadingSpaces, currentSpaces.length, lastSpacesSetTime]);

  return (
    <>
      <ProfileAppHeader title="My Form Atlas Home" />
      <main className="container-max-width">
        <h2 className="m-m">Your Spaces</h2>
        <div className="m-m">
          {isLoadingSpaces || isAppLoadingSpaces ? (
            <p>Loading...</p>
          ) : spaces.length ? (
            <nav aria-label="Your spaces" className="container-flex wrap gap-m">
              {spaces.map((space) => {
                return <SpaceCard key={space.id} space={space} />;
              })}
            </nav>
          ) : (
            <p>You aren't currently a member of any space.</p>
          )}
        </div>
        <ButtonBar className="m-l">
          <Button linkURL="/spaces/new" title="Create a new space">
            Create Space
          </Button>
        </ButtonBar>
      </main>
    </>
  );
}
