import { useEffect, useId, useRef, useState } from "react";

/**
 * The properties for the {@link Checkbox} component.
 */
export interface CheckboxProps {
  /** The label content. */
  label?: JSX.Element | string;

  /** The DOM id to use for the container. */
  id?: string;

  /** The DOM name attribute value. */
  name?: string;

  /** The field container class name. */
  className?: string;

  /** Flag indicating if the checkbox is checked or not. */
  checked?: boolean;

  /** The checkbox on hover title. */
  title?: string;

  /** Flag indicating if the checkbox is disabled or not. */
  disabled?: boolean;

  /** Flag indicating if the checkbox value is required. */
  required?: boolean;

  /** Flag indicating if the checkbox is invalid (i.e. has an error). */
  hasError?: boolean;

  /** A function to receive the reference to the checkbox input. */
  getRef?: (ref: React.RefObject<HTMLInputElement>) => any;

  /** An event handler triggered when the user changes the field value. */
  onChange?: (
    checked: boolean,
    event: React.ChangeEvent<HTMLInputElement>
  ) => any;

  /** An event handler triggered when the user focuses on the field. */
  onFocus?: (
    isFirstVisit: boolean,
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => any;

  /** An event handler triggered when the user removes focus from the field. */
  onBlur?: (
    checked: boolean,
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => any;

  "aria-describedby"?: string;
}

/**
 * A simple checkbox to collect true/false input from the user.
 */
export function Checkbox(props: CheckboxProps): JSX.Element {
  const id = useId();
  const [isVisited, setIsVisited] = useState(false);

  const checkboxRef = useRef<HTMLInputElement>(null);

  const getRef = props.getRef;

  useEffect(() => {
    if (getRef && checkboxRef.current) {
      getRef(checkboxRef);
    }
  }, [getRef, checkboxRef]);

  let className = "checkbox";
  if (props.className) {
    className += " " + props.className;
  }
  if (props.hasError) {
    className += " error";
  }
  return (
    <div id={props.id} className={className}>
      <input
        ref={checkboxRef}
        type="checkbox"
        id={id}
        defaultChecked={props.checked}
        name={props.name}
        title={props.title}
        disabled={props.disabled}
        required={props.required}
        onChange={(event) => {
          props.onChange && props.onChange(event.target.checked, event);
        }}
        onFocus={(event) => {
          const isFirstVisit = !isVisited;
          if (isFirstVisit) {
            setIsVisited(true);
          }
          props.onFocus && props.onFocus(isFirstVisit, event);
        }}
        onBlur={(event) =>
          props.onBlur && props.onBlur(event.target.checked, event)
        }
        aria-invalid={props.hasError}
        aria-describedby={props["aria-describedby"]}
      />
      <label htmlFor={id}>
        {props.label || ""}
        {props.required && <span className="c-fb-neg-dark"> *</span>}
      </label>
    </div>
  );
}

export default Checkbox;
