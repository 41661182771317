import { useContext } from "react";
import ContentContext from "src/context/content-context/ContentContext";
import ContentVersionContext from "src/context/content-version-context/ContentVersionContext";
import { navByContent as feNavByContent } from "src/apps/form-editor-app/fe-utils/fe-nav-utils";
import FEBreadcrumbs from "../fe-breadcrumbs/FEBreadcrumbs";

export interface FEEditorBreadcrumbsProps {}

export function FEEditorBreadcrumbs(props: FEEditorBreadcrumbsProps) {
  const { type, typeLabelTitle, content } = useContext(ContentContext);
  const { version } = useContext(ContentVersionContext);

  const title = `View ${typeLabelTitle}`;
  return (
    <FEBreadcrumbs
      parts={[
        { ...feNavByContent[type], uri: feNavByContent[type].uri },
        {
          uri: content.id,
          title,
        },
        {
          uri: `v/${version.id}`,
          title: "View Version",
        },
      ]}
    />
  );
}

export default FEEditorBreadcrumbs;
