import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";
import SpaceAppCard from "./space-app-card/SpaceAppCard";
import { useContext } from "react";
import SpaceContext from "src/context/space-context/SpaceContext";
import SmartNavBar from "src/shared/smart-nav-bar/SmartNavBar";
import SpaceSelect from "src/shared/space-select/SpaceSelect";

/**
 * The properties for the {@link SpacePage} component.
 */
export interface SpacePageProps {}

export function SpacePage(props: SpacePageProps): JSX.Element {
  const spaceContext = useContext(SpaceContext);
  const space = spaceContext.space;

  return (
    <>
      <ProfileAppHeader title="Space Home" />
      <SmartNavBar />
      <main className="container-max-width m-bottom-l">
        <SpaceSelect />
        <nav
          className="container-flex wrap"
          aria-label={`Available apps for the "${space.displayName}" space`}
        >
          <SpaceAppCard
            className="flex col-33 s-col-100 l-col-50 p-r-s m-xs s-p-lr-0"
            appID="dashboard"
          />
          <SpaceAppCard
            className="flex col-33 s-col-100 l-col-50 p-lr-s m-xs s-p-lr-0 l-p-r-0"
            appID="form-editor"
          />
          <SpaceAppCard
            className="flex col-33 s-col-100 l-col-50 p-l-s m-xs s-p-lr-0 l-p-l-0 l-p-r-s"
            appID="form-manager"
          />
          <SpaceAppCard
            className="flex col-33 s-col-100 l-col-50 p-r-s m-xs s-p-lr-0 l-p-l-s l-p-r-0"
            appID="settings"
          />
        </nav>
      </main>
    </>
  );
}

export default SpacePage;
