import React from "react";
import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import SpaceContext from "src/context/space-context/SpaceContext";
import SpaceEnvContext from "src/context/space-env-context/SpaceEnvContext";
import FMAppHome from "./fm-app-home/FMAppHome";
import FMEnvHome from "./fm-env-home/FMEnvHome";

export interface FormManagerAppProps {}

export function FormManagerApp(props: FormManagerAppProps): JSX.Element {
  const spaceContext = useContext(SpaceContext);
  return (
    <Routes>
      <Route path="" element={<FMAppHome />} />
      {spaceContext.envs?.map((env) => {
        if (!env.isAvailable) return null;
        return (
          <React.Fragment key={env.id}>
            <Route
              path={`env/${env.id}`}
              element={
                <SpaceEnvContext.Provider value={{ env }}>
                  <FMEnvHome />
                </SpaceEnvContext.Provider>
              }
            />
          </React.Fragment>
        );
      })}
    </Routes>
  );
}

export default FormManagerApp;
