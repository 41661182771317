import { ContentVersion } from "@formatlas/types";
import { createContext } from "react";

export interface ContentVersionContextData {
  version: ContentVersion;
}

export const ContentVersionContext = createContext<ContentVersionContextData>({
  version: {
    id: "",
    created: new Date(),
    updated: new Date(),
    draft: false,
    createdBy: [],
  },
});

export default ContentVersionContext;
