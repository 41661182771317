import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Banner,
  Button,
  ButtonBar,
  CheckboxGroup,
  RadioButtonGroup,
  TextField,
  formatCurrency,
  removeUndefined,
  toast,
} from "@formatlas/react";
import { Plan } from "@formatlas/types";

import app from "app";

import { collection, getDocs, query, where } from "firebase/firestore";
import { DocsLink } from "src/shared/docs-link/DocsLink";
import PlanCard from "src/shared/plan-card/PlanCard";
import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";
import SpaceManager from "src/utils/SpaceManager";
import SmartNavBar from "src/shared/smart-nav-bar/SmartNavBar";

export interface CreateSpacePageProps {}

export function CreateSpacePage(props: CreateSpacePageProps): JSX.Element {
  const locale = "en";

  const navigate = useNavigate();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Space fields
  const [usage, setUsage] = useState<string | null>(null);
  const [displayName, setDisplayName] = useState("");
  const [shortName, setShortName] = useState("");
  const [publicName, setPublicName] = useState("");
  const [publicEmail, setPublicEmail] = useState("");
  const [regions, setRegions] = useState<string[]>([]);

  // Your needs fields
  const [reqFormSubs, setReqFormSubs] = useState<string | null>(null);
  const [reqUsers, setReqUsers] = useState<string | null>(null);
  const [reqEnvs, setReqEnvs] = useState<string | null>(null);

  // Plan fields
  const [plans, setPlans] = useState<Plan[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<Plan>();
  const [autorenew, setAutorenew] = useState<string | null>(null);
  const [paymentFrequency, setPaymentFrequency] = useState<string | null>(null);
  const [allowOverage, setAllowOverage] = useState<string | null>(null);

  // Get the plans available
  useEffect(() => {
    const q = query(
      collection(app.getFirestore(), "plans"),
      where("isAvailable", "==", true)
    );
    const plans: Plan[] = [];
    getDocs(q)
      .then((queryResults) => {
        queryResults.forEach((result) => {
          const data = result.data();
          data.id = result.id;
          plans.push(data as Plan);
        });
        setPlans(
          plans.sort((a, b) => (a.pricePerMonth <= b.pricePerMonth ? -1 : 1))
        );
      })
      .catch((error) => {
        console.error("Failed to get available plans.", error);
        toast.add({ label: "Failed to get available plans.", type: "error" });
      });
  }, []);

  function addToastError(label: string) {
    toast.add({ label, type: "error" });
  }

  function handleSubmit() {
    setHasSubmitted(true);

    // Check mandatory fields
    if (!usage) {
      return addToastError('No "Usage" type selected.');
    }
    if (!displayName) {
      return addToastError('Missing space "Name".');
    }
    if (!selectedPlan) {
      return addToastError("No plan selected.");
    }
    if (!autorenew) {
      return addToastError("No auto-renew option selected.");
    }
    if (!paymentFrequency) {
      return addToastError("No payment frequency selected.");
    }
    if (!allowOverage) {
      return addToastError("No allow overage charges option selected.");
    }

    // Send the request
    setIsSubmitting(true);
    SpaceManager.createSpace(
      app,
      removeUndefined({
        usage: usage as "business" | "individual",
        space: {
          displayName,
          shortName: shortName || undefined,
          publicDisplayName: publicName || undefined,
          publicEmail: publicEmail || undefined,
          regions,
        },
        needs: {
          submissionsPerMonth: reqFormSubs ? reqFormSubs : undefined,
          users: reqUsers ? reqUsers : undefined,
          environments: reqEnvs ? (reqEnvs as "Y" | "N" | "idk") : undefined,
        },
        plan: {
          id: selectedPlan.id,
          autorenew: autorenew === "Y",
          allowOverageCharges: allowOverage === "Y",
          paymentFrequency: paymentFrequency as "monthly" | "annually",
        },
      })
    )
      .then((response) => {
        toast.add({
          label: `Successfully created space ${response.space.displayName}.`,
          type: "success",
        });
        navigate(`/s/${response.space.id}`);
      })
      .catch((error) => {
        console.error("Failed to create space.", error);
        addToastError("Failed to create space.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    toast.add({ label: "Creating space...", type: "info" });
  }

  return (
    <>
      <ProfileAppHeader title="Create Space" />
      <SmartNavBar />
      <main className="container-max-width m-bottom-l">
        <p className="m-m">
          Use this page to create a new Form Atlas Space, which has its own
          settings, content, and users. You can learn more about{" "}
          <DocsLink to="/my-form-atlas/spaces/how-to-create">
            how to create a space
          </DocsLink>{" "}
          and{" "}
          <DocsLink to="/my-form-atlas/spaces/overview">
            what are spaces
          </DocsLink>{" "}
          in the documentation.
        </p>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            return false;
          }}
        >
          <h2 className="m-m">About Your Space</h2>
          <RadioButtonGroup
            className="m-s"
            name="space-usage"
            label="Usage"
            type="box"
            options={[
              { label: "Business", value: "business" },
              { label: "Individual", value: "individual" },
            ]}
            value={usage || undefined}
            required
            onChange={(value) => setUsage(value)}
            hasError={hasSubmitted && !usage}
          />
          <div className="container-flex wrap">
            <TextField
              className="flex col-50 s-col-100 m-s p-r-s s-p-lr-0"
              name="space-name"
              label="Space Name"
              title="The name of the space."
              value={displayName}
              onChange={(value) => setDisplayName(value)}
              maxLength={128}
              required
              hasError={hasSubmitted && !displayName}
            />
            <TextField
              className="flex col-25 l-col-50 s-col-100 m-s p-l-s s-p-lr-0"
              name="space-short-name"
              label="Short Space Name"
              title="A short name for the space, which is displayed in places like My Form Atlas."
              value={shortName}
              onChange={(value) => setShortName(value)}
              maxLength={24}
            />
          </div>
          <div className="container-flex wrap">
            <TextField
              className="flex col-50 s-col-100 m-s p-r-s s-p-lr-0"
              name="space-public-name"
              label="Public Space Name"
              title="The public name of the space which appears on end-user content like forms."
              value={publicName}
              onChange={(value) => setPublicName(value)}
              maxLength={128}
            />
            <TextField
              className="flex col-50 s-col-100 m-s p-l-s s-p-lr-0"
              name="space-public-email"
              label="Public Contact Email"
              title="The email address which appears on end-user content like forms."
              autoComplete="email"
              value={publicEmail}
              onChange={(value) => setPublicEmail(value)}
              maxLength={256}
            />
          </div>
          <CheckboxGroup
            className="m-s"
            name="space-regions[]"
            label="Customer Regions"
            title="Regions you expect to have customers in, who may use the end-user content (e.g. forms) you create."
            options={[
              { label: "North America", value: "NA" },
              { label: "Europe", value: "EU" },
              { label: "Asia", value: "AS" },
              { label: "South America", value: "SA" },
              { label: "Oceania", value: "OC" },
              { label: "Africa", value: "AF" },
            ]}
            values={regions}
            onChange={(values) => setRegions(values)}
          />

          <h2 className="m-m">Your Needs</h2>
          <p>
            You can use the questions below to help determine what plan may be
            best for you. You can also refer to the links below for more details
            about different features:
          </p>
          <ul className="m-top-xs">
            <li>
              <DocsLink to="/forms/what-counts-as-a-form-submission">
                Learn about form submissions
              </DocsLink>
            </li>
            <li>
              <DocsLink to="/my-form-atlas/spaces/users">Space Users</DocsLink>
            </li>
            <li>
              <DocsLink to="/my-form-atlas/environments">
                Form Atlas Environments
              </DocsLink>
            </li>
          </ul>
          <RadioButtonGroup
            className="m-s"
            type="normal"
            name="space-req-form-submissions"
            label="How many form submissions or orders do you expect per month?"
            options={[
              { label: "Less than 100", value: "<100" },
              { label: "Less than 500", value: "<500" },
              { label: "Less than 1,000", value: "<1000" },
              { label: "At least 1,000", value: ">=1000" },
              { label: "I don't know", value: "idk" },
            ]}
            value={reqFormSubs || undefined}
            onChange={(value) => setReqFormSubs(value)}
          />
          <RadioButtonGroup
            className="m-s"
            type="normal"
            name="space-req-users"
            label="Including you, how many users need access to this space?"
            options={[
              { label: "Only me", value: "1" },
              { label: "Less than 10", value: "<10" },
              { label: "Less than 100", value: "<100" },
              { label: "At least 100", value: ">=100" },
              { label: "I don't know", value: "idk" },
            ]}
            value={reqUsers || undefined}
            onChange={(value) => setReqUsers(value)}
          />
          <RadioButtonGroup
            className="m-s"
            type="box"
            name="space-req-envs"
            label="Do you need multiple environments to build and test forms?"
            options={[
              { label: "Yes", value: "Y" },
              { label: "No", value: "N" },
              { label: "I don't know", value: "idk" },
            ]}
            value={reqEnvs || undefined}
            onChange={(value) => setReqEnvs(value)}
          />
          <h2 className="m-m">Plans</h2>
          <div className="m-m container-flex wrap jc-center gap-s">
            {plans.map((plan) => {
              return (
                <PlanCard
                  key={plan.id}
                  plan={plan}
                  selected={selectedPlan && plan.id === selectedPlan.id}
                  onClick={() => {
                    setSelectedPlan(plan);
                  }}
                />
              );
            })}
          </div>
          {selectedPlan ? (
            <>
              <RadioButtonGroup
                className="m-m"
                type="box"
                name="space-autorenew"
                label="Auto-Renew Plan Subscription"
                options={[
                  { label: "Yes", value: "Y" },
                  { label: "No", value: "N" },
                ]}
                onChange={(value) => setAutorenew(value)}
                value={autorenew || undefined}
                required
                hasError={hasSubmitted && !autorenew}
              />
              {autorenew === "N" && (
                <Banner type="info" className="m-m">
                  <p>
                    You will be sent an email reminder at least 7 days prior to
                    your plan subscription expiring.
                  </p>
                </Banner>
              )}
              <RadioButtonGroup
                className="m-m"
                name="space-payment-frequency"
                label="Payment Frequency"
                options={[
                  {
                    label: `Annually (${formatCurrency(
                      selectedPlan.pricePerYear,
                      locale
                    )} per year)`,
                    value: "annually",
                  },
                  {
                    label: `Monthly (${formatCurrency(
                      selectedPlan.pricePerMonth,
                      locale
                    )} per month)`,
                    value: "monthly",
                  },
                ]}
                onChange={(value) => setPaymentFrequency(value)}
                value={paymentFrequency || undefined}
                required
                hasError={hasSubmitted && !paymentFrequency}
              />
              <RadioButtonGroup
                className="m-m"
                type="box"
                name="space-allow-overage"
                label="Allow overage charges?"
                options={[
                  { label: "Yes", value: "Y" },
                  { label: "No", value: "N" },
                ]}
                onChange={(value) => setAllowOverage(value)}
                value={allowOverage || undefined}
                required
                hasError={hasSubmitted && !allowOverage}
              />
              <Banner type="info" className="m-m">
                <p>
                  Allowing overage charges enables you to use as many resources
                  - such as form submissions - per month as you require. Any
                  usage beyond what is included in your plan is charged at a
                  standard rate.
                </p>
              </Banner>
            </>
          ) : (
            <Banner type="info" className="m-m">
              <p>Please select a plan to continue.</p>
            </Banner>
          )}
          <ButtonBar className="m-top-l m-bottom-m">
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Checkout
            </Button>
          </ButtonBar>
        </form>
      </main>
    </>
  );
}

export default CreateSpacePage;
