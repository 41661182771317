import { useId, useState } from "react";
import FieldError from "../field-error/FieldError";

import "./ColourPicker.css";

/**
 * The properties for the {@link ColourPicker} component.
 */
export interface ColourPickerProps {
  /** The label content. */
  label?: JSX.Element | string;

  /** The DOM id to use for the container. */
  id?: string;

  /** The DOM name attribute value. */
  name?: string;

  /** The field container class name. */
  className?: string;

  /** The colour picker value. */
  value?: string;

  /** The colour picker on hover title. */
  title?: string;

  /** Flag indicating if the colour picker is disabled or not. */
  disabled?: boolean;

  /** Flag indicating if the colour picker value is required. */
  required?: boolean;

  /** Flag indicating if the colour picker is invalid (i.e. has an error). */
  hasError?: boolean;

  /** An error message to display. */
  error?: string | JSX.Element;

  /** An event handler triggered when the user changes the field value. */
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => any;

  /** An event handler triggered when the user focuses on the field. */
  onFocus?: (
    isFirstVisit: boolean,
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => any;

  /** An event handler triggered when the user removes focus from the field. */
  onBlur?: (
    value: string,
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => any;

  "aria-describedby"?: string;
}

export function ColourPicker(props: ColourPickerProps) {
  const id = useId();
  const [isVisited, setIsVisited] = useState(false);

  let className = "field colour-picker",
    value = props.value;
  if (props.className) {
    className += " " + props.className;
  }
  if (props.hasError) {
    className += " error";
  }

  return (
    <div id={props.id} className={className}>
      {props.label && (
        <label htmlFor={id}>
          {props.label}
          {props.required && <span className="c-fb-neg-dark"> *</span>}
        </label>
      )}
      <input
        type="color"
        id={id}
        value={value}
        name={props.name}
        title={props.title}
        disabled={props.disabled}
        required={props.required}
        onChange={(event) => {
          props.onChange && props.onChange(event.target.value, event);
        }}
        onFocus={(event) => {
          const isFirstVisit = !isVisited;
          if (isFirstVisit) {
            setIsVisited(true);
          }
          props.onFocus && props.onFocus(isFirstVisit, event);
        }}
        onBlur={(event) =>
          props.onBlur && props.onBlur(event.target.value, event)
        }
        aria-invalid={props.hasError}
        aria-describedby={
          props.error ? id + "-error" : props["aria-describedby"]
        }
      />
      {props.error && <FieldError id={id + "-error"} error={props.error} />}
    </div>
  );
}

export default ColourPicker;
