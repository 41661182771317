import { EnvironmentID } from "@formatlas/types";

import "./EnvTag.css";

export interface EnvTagProps {
  className?: string;
  env: EnvironmentID;
}

export function EnvTag(props: EnvTagProps): JSX.Element {
  let className = `env-tag bold env-tag-${props.env}`;
  if (props.className) {
    className += " " + props.className;
  }
  return <span className={className}>{props.env}</span>;
}

export default EnvTag;
