import { AppHeader } from "@formatlas/react";

/**
 * The properties for the {@link LoadingPage} component.
 */
export interface LoadingPageProps {
  title?: string;
  children?: any;
}

/**
 * A simple loading page.
 */
export default function LoadingPage(props: LoadingPageProps): JSX.Element {
  return (
    <>
      <AppHeader title={props.title ?? "Loading My Form Atlas..."} />
      <main className="container-max-width">
        {props.children || "Loading..."}
      </main>
    </>
  );
}
