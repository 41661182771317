import { getColourContrastRatio } from "@formatlas/react";
import { Colour, ContentType, MappedObject } from "@formatlas/types";

export interface OrderedColour {
  id: string;
  value: Colour;
  whiteContrast: number;
}

export function getContentDevPermission(type: ContentType) {
  return `dev-${type}s`;
}

export function orderColours(colours: MappedObject<Colour>): OrderedColour[] {
  return Object.keys(colours || {})
    .filter((id) => !!colours[id])
    .map((id) => {
      const value = colours[id];
      return {
        id,
        value,
        whiteContrast: getColourContrastRatio(value.colour, "#FFFFFF"),
      };
    })
    .sort((a, b) => {
      const an = a.value.name.toLowerCase();
      const bn = b.value.name.toLowerCase();
      if (an !== bn) {
        return an < bn ? -1 : 1;
      }
      return a.whiteContrast - b.whiteContrast;
    });
}
