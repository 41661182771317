import { useEffect, useLayoutEffect, useState } from "react";
import Icon from "../icon/Icon";
import { formatNumber } from "../../utils/format";

/**
 * The properties for the {@link Table} component.
 */
export interface TableProps<T> {
  /** The DOM id to use for the table container. */
  id?: string;

  /** The table container class name. */
  className?: string;

  /** The column definitions. */
  cols: TableColumn<T>[];

  /** The data elements. */
  data: TableData<T>[];

  /** The max number of items per "page" to display. */
  limit?: number;

  /** Content displayed if no data is provided to the table. */
  emptyTable?: string | JSX.Element;

  /** If true, the header row of the table will be hidden. */
  hideHeader?: boolean;

  /** Footer content to display at the bottom of the table (starting with `tfoot`). */
  footer?: JSX.Element;

  /** The caption to display before the table starts. */
  caption?: string | JSX.Element;

  "aria-describedby"?: string;
}

/**
 * Represents a table column and contains configuration options for both the
 * header and body for the column.
 */
export interface TableColumn<T> {
  /** A unique ID for the column. */
  id: string | number;
  /** The title of the column. */
  title: string | JSX.Element;
  /** A function to format a value to the column cell. */
  format: (
    record: T,
    row: number,
    col: TableColumn<T>
  ) => string | JSX.Element | number | boolean | null | undefined;
  /** If provided, the column will be sortable via this function. */
  sort?: (a: T, b: T, col: TableColumn<T>) => number;
  /** If true, the body rows will appear as a header. */
  useHeader?: boolean;
  /** A class to assign to body row cells. */
  className?: string;
  /** The width to display the column at. */
  width?: number | string;
  /** The styles to assign to body row cells. */
  style?: React.CSSProperties;
  /**
   * A set of rules that determine at what device widths the column is displayed
   * for. If rules are provided, all must be true to display the column.
   */
  displayRules?: TableDisplayRule[];
}

/**
 * Represents a generic display rule to be used by the {@link Table} component.
 */
export interface TableDisplayRule {
  /** The value to check against. */
  value: number;
  /** The type of comparison. */
  comparison: "<" | ">";
}

/**
 * Represents data that can be mapped and displayed in the {@link Table}.
 */
export interface TableData<T> {
  /** A unique ID. */
  id: string | number;
  /** The value to display. */
  value: T;
}

/**
 * A table component that displays tabular data.
 */
export function Table<T>(props: TableProps<T>) {
  const { cols, data, limit } = props;
  const [records, setRecords] = useState([...data]);
  const [sortCol, setSortCol] = useState(cols.find((v) => !!v.sort));
  const [sortDir, setSortDir] = useState<"asc" | "dsc" | null>(null);
  const [visibleCols, setVisibleCols] = useState(cols.map(() => true));
  const [offset, setOffset] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    setRecords([...data]);
  }, [data]);

  useEffect(() => {
    if (!sortCol || !sortCol.sort) {
      setSortCol(cols.find((v) => !!v.sort));
    }
  }, [cols, sortCol]);

  useLayoutEffect(() => {
    setVisibleCols(
      cols.map(
        (col) =>
          !col.displayRules ||
          col.displayRules.every((rule) => {
            const { value, comparison } = rule;
            return (
              (comparison === ">" && windowWidth > value) ||
              (comparison === "<" && windowWidth < value)
            );
          })
      )
    );
  }, [cols, windowWidth]);

  useEffect(() => {
    const sort = sortCol?.sort;
    if (!sort) {
      return;
    }
    const ascMult = sortDir === "dsc" ? -1 : 1;
    setRecords((v) => [
      ...v.sort((a, b) => {
        return ascMult * sort(a.value, b.value, sortCol);
      }),
    ]);
  }, [sortCol, sortDir]);

  let className = "fa-table";
  if (props.className) {
    className += " " + props.className;
  }

  let finalRecords = records;
  let max = records.length;
  if (limit && limit > 0) {
    finalRecords = records.filter((_, i) => i >= offset && i < offset + limit);
    const cleanCount = records.length - (records.length % limit);
    max = Math.max(0, cleanCount - (cleanCount === records.length ? limit : 0));
  }

  const showEmpty = !records.length && props.emptyTable;

  return (
    <div id={props.id} className={showEmpty ? undefined : className}>
      {showEmpty ? (
        props.emptyTable
      ) : (
        <>
          <table aria-describedby={props["aria-describedby"]}>
            {props.caption && <caption>{props.caption}</caption>}
            {!props.hideHeader && (
              <thead>
                <tr>
                  {cols.map((col, i) => {
                    if (!visibleCols[i]) return null;
                    if (col === sortCol && sortDir) {
                      return (
                        <th
                          key={col.id}
                          scope="col"
                          aria-sort={
                            sortDir === "dsc" ? "descending" : "ascending"
                          }
                        >
                          <button
                            className="action container-flex"
                            onClick={() => {
                              setSortDir((v) => (v === "dsc" ? "asc" : "dsc"));
                            }}
                          >
                            <span>{col.title}</span>
                            <Icon
                              className="action-icon"
                              name={sortDir === "dsc" ? "south" : "north"}
                            />
                          </button>
                        </th>
                      );
                    }
                    if (col.sort) {
                      return (
                        <th key={col.id} scope="col">
                          <button
                            className="action"
                            onClick={() => {
                              setSortDir("asc");
                              setSortCol(col);
                            }}
                          >
                            {col.title}
                          </button>
                        </th>
                      );
                    }
                    return (
                      <th key={col.id} scope="col">
                        {col.title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>
              {finalRecords.map((v, i) => (
                <tr key={v.id}>
                  {cols.map((col, j) => {
                    if (!visibleCols[j]) return null;
                    const style = { ...(col.style ?? {}) };
                    if (col.width) {
                      style.width = col.width;
                    }
                    const value = col.format(v.value, offset + i, col);
                    if (col.useHeader) {
                      return (
                        <th
                          key={col.id}
                          scope="row"
                          style={style}
                          className={col.className}
                        >
                          {value}
                        </th>
                      );
                    }
                    return (
                      <td key={col.id} style={style} className={col.className}>
                        {value}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
            {props.footer}
          </table>
          {!!limit && limit > 0 && records.length > limit && (
            <div className="container-flex jc-end">
              <div className="container-flex gap-xs wrap ai-center">
                <span>
                  Row {formatNumber(offset + 1, 0, 0)}-
                  {formatNumber(Math.min(records.length, offset + limit), 0, 0)}
                </span>
                <button
                  aria-label="First page"
                  title="First page"
                  onClick={() => setOffset(0)}
                  disabled={offset < 1}
                >
                  <Icon name="skip_previous" />
                </button>
                <button
                  aria-label="Previous page"
                  title="Previous page"
                  onClick={() => setOffset((v) => Math.max(0, v - limit))}
                  disabled={offset < 1}
                >
                  <Icon name="arrow_left" />
                </button>
                <button
                  aria-label="Next page"
                  title="Next page"
                  onClick={() => setOffset((v) => Math.min(max, v + limit))}
                  disabled={offset >= max}
                >
                  <Icon name="arrow_right" />
                </button>
                <button
                  aria-label="Last page"
                  title="Last page"
                  onClick={() => setOffset(max)}
                  disabled={offset >= max}
                >
                  <Icon name="skip_next" />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Table;
