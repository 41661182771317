/**
 * Generates a random ID using safe characters (A-Z, a-z, 0-9) using a
 * pseudorandom number generator. Note: this function **should not be used** to
 * generate any secure IDs (for example session IDs).
 * @param {number} length the length of the ID to generate.
 * @return {string} a randomly generated ID of the specified length.
 */
export function generateID(length = 20): string {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const n = chars.length;
  let id = "";
  while (id.length < length) {
    id += chars.charAt(Math.floor(n * Math.random()));
  }
  return id;
}

/**
 * Gets the contrast ratio between two hexadecimal colours. Each colour must
 * follow the format #rrggbb, where rr, gg, bb are the red, green, blue
 * components of the colour (respectively).
 *
 * See https://www.w3.org/TR/WCAG20-TECHS/G17.html for more details.
 * @param {string} colour1 the first colour.
 * @param {string} colour2 the second colour.
 * @return {number} the contrast ratio between the two colours, or NaN if one or both
 * colours are invalid.
 */
export function getColourContrastRatio(
  colour1: string,
  colour2: string
): number {
  const colourExp = /^#[\da-f]{6}$/i;
  if (!colourExp.test(colour1) || !colourExp.test(colour2)) {
    return NaN;
  }

  function getLuminanceComponent(colour: string, index: number) {
    const l = parseInt(colour.slice(index * 2 + 1, index * 2 + 3), 16) / 255.0;
    return l <= 0.03928 ? l / 12.92 : Math.pow((l + 0.055) / 1.055, 2.4);
  }

  function getLuminance(parts: [number, number][], index: number) {
    return (
      0.2126 * parts[0][index] +
      0.7152 * parts[1][index] +
      0.0722 * parts[2][index]
    );
  }

  const parts: [number, number][] = [0, 1, 2].map((i) => [
    getLuminanceComponent(colour1, i),
    getLuminanceComponent(colour2, i),
  ]);
  const l1 = getLuminance(parts, 0);
  const l2 = getLuminance(parts, 1);

  return (Math.max(l1, l2) + 0.05) / (Math.min(l1, l2) + 0.05);
}

/**
 * Chooses a colour with the highest contrast against a test colour. Each
 * colour must follow the format #rrggbb, where rr, gg, bb are the red, green,
 * blue components of the colour (respectively).
 * @param {string} testColour the reference colour.
 * @param {string[]} colours the colours to choose from.
 * @return {string} the colour with the highest contrast against the test colour,
 * or an empty string if no valid colour exists.
 */
export function chooseHighestContrastColour(
  testColour: string,
  ...colours: string[]
): string {
  let maxContrast = 0;
  let maxContrastIndex = -1;
  for (let i = 0, n = colours.length; i < n; i++) {
    const contrast = getColourContrastRatio(testColour, colours[i]);
    if (contrast > maxContrast) {
      maxContrast = contrast;
      maxContrastIndex = i;
    }
  }

  return maxContrastIndex < 0 ? "" : colours[maxContrastIndex];
}
