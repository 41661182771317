import { NavBar, NavBarItem, PageContext } from "@formatlas/react";
import { useContext } from "react";
import { apps } from "src/utils/apps";

export interface SmartNavBarProps {}

export function SmartNavBar(props: SmartNavBarProps) {
  const { ancestors, current } = useContext(PageContext);
  if (!ancestors.length) {
    return <></>;
  }
  const previous = ancestors[ancestors.length - 1];
  const pages = [...ancestors, current];
  const spacePage = pages.find((v) => v?.id === "space-home");
  const home: NavBarItem = {
    url: "/",
    icon: "home",
    tooltip: "Go to My Form Atlas home.",
  };
  const previousItem: NavBarItem = {
    url: previous.url,
    icon: previous.icon || "keyboard_backspace",
    title: previous.name,
    tooltip: previous.description,
  };
  const defaultItems =
    !previousItem.url || previousItem.url === "/" ? [] : [home];

  // Normal
  if (!spacePage) {
    return <NavBar leftItems={[previousItem]} rightItems={defaultItems} />;
  }

  // In a space
  const spaceID = spacePage.data?.spaceID;
  const baseURL = typeof spaceID === "string" ? `/s/${spaceID}/` : "";
  const appItems: NavBarItem[] = apps
    .filter((app) => !pages.some((v) => v?.data?.app === app.id))
    .map((app) => {
      return {
        url: `${baseURL}${app.uri}`,
        icon: app.icon,
        tooltip: app.shortDescription
          ? `Space ${app.name} - ${app.shortDescription.toLowerCase()}`
          : "",
      };
    });

  return (
    <NavBar
      leftItems={[previousItem]}
      rightItems={appItems.concat(defaultItems)}
    />
  );
}

export default SmartNavBar;
