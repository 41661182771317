import { Breadcrumb, Breadcrumbs } from "@formatlas/react";
import { appsByID } from "src/utils/apps";

export interface FEBreadcrumbsProps {
  parts: Breadcrumb[];
}

export function FEBreadcrumbs(props: FEBreadcrumbsProps) {
  const feApp = appsByID["form-editor"];
  return (
    <Breadcrumbs
      className="m-m"
      parts={[
        {
          uri: "",
          title: feApp.name,
          icon: feApp.icon,
        },
        ...props.parts,
      ]}
      relative
      aria-label={`${feApp.name} breadcrumbs`}
    />
  );
}

export default FEBreadcrumbs;
