import Environment from "src/models/Environment";

/**
 * SANDBOX environment configuration.
 */
export const environment: Environment = {
  cdnURL: "https://assets.sandbox.formatlas.dev",
  loginURL: "/login",
  docsURL: "https://docs.formatlas.ca",
  firebaseConfig: {
    apiKey: "AIzaSyBzkQHUVHSOzSK7WGmu8tmtnwsV5Rj9MMs",
    authDomain: "formatlas-sandbox.firebaseapp.com",
    projectId: "formatlas-sandbox",
    storageBucket: "formatlas-sandbox.appspot.com",
    messagingSenderId: "224260941877",
    appId: "1:224260941877:web:f9e5788c70017ec6fee97c",
    measurementId: "G-F5EXCC8VP0",
  },
};

export default environment;
