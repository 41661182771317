import React, { useCallback, useEffect, useRef, useState } from "react";
import Icon from "../icon/Icon";
import { getActiveElement } from "../../utils/dom-utils";

/**
 * Represents an option that appears in a context menu.
 */
export interface ContextMenuOption {
  /** A unique ID for this option. */
  id: string;
  /** An icon to display to the left of the menu name. */
  icon?: string;
  /** The menu option name. */
  name: string;
  /** A tooltip to show for the option. */
  tooltip?: string;
  /**
   * Indicates if the menu option is active, e.g. when the context menu acts
   * similar to a dropdown, it could indicate the selected option.
   */
  active?: boolean;
  /**
   * A handler triggered when the option is clicked or an equivalent keyboard
   * event is fired (i.e. enter or space).
   */
  onClick?: (option: ContextMenuOption, index: number) => void;
}

/**
 * The properties for the {@link ContextMenu} component.
 */
export interface ContextMenuProps {
  /** A DOM ID to give to the context menu. */
  id?: string;
  /**
   * Determines whether the menu is aligned to the left or right of the
   * parent container. By default, the menu is left aligned.
   */
  align?: "left" | "right";
  /**
   * Determines whether the menu starts from the top going down or bottom
   * going up. By default, the menu starts top down.
   */
  start?: "top" | "bottom";
  /**
   * The menu option groups. Each group of options is automatically separated
   * by a separator.
   */
  options: ContextMenuOption[][];
  /**
   * An event handler called when either a menu option is activated or the menu
   * loses focus.
   */
  onBlur?: () => void;
}

/**
 * A context menu option with all the required handlers.
 */
function MenuOption(props: {
  option: ContextMenuOption;
  index: number;
  tabIndex: number;
  setRef: React.Dispatch<React.SetStateAction<OptRefs>>;
  onFocus: () => void;
  onBlur: (e: React.FocusEvent<HTMLLIElement, Element>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  onClick: () => void;
}) {
  const { option, setRef, index } = props;
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    setRef((s) => ({ ...s, [index]: ref }));
  }, [ref, index, setRef]);

  return (
    <li
      ref={ref}
      role="menuitem"
      className={option.active ? "active" : ""}
      tabIndex={props.tabIndex}
      title={option.tooltip}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onKeyDown={props.onKeyDown}
      onClick={props.onClick}
      data-index={props.index}
    >
      {option.icon && <Icon name={option.icon} />}
      {option.name}
    </li>
  );
}

type OptRefs = {
  [value: string]: React.RefObject<HTMLLIElement>;
};

/**
 * A context menu that appears with one or more groups of options. The menu
 * automatically calls an optional `onBlur` event handler once it loses focus.
 */
export function ContextMenu(props: ContextMenuProps) {
  const { align = "left", start = "top", options, onBlur } = props;

  const containerRef = useRef<HTMLUListElement>(null);
  const [optRefs, setOptRefs] = useState<OptRefs>({});
  const [focusIndex, setFocusIndex] = useState(0);
  const [originalFocus] = useState(getActiveElement());

  /**
   * Calls the onBlur callback provided, if one exists.
   */
  const closeMenu = useCallback(() => {
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  useEffect(() => {
    function onClick(ev: MouseEvent) {
      if (
        ([document.body, document.body.parentElement] as any[]).indexOf(
          ev.target
        ) >= 0
      ) {
        closeMenu();
      }
    }
    window.addEventListener("click", onClick);
    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [closeMenu, originalFocus]);

  useEffect(() => {
    const focusRef = optRefs[focusIndex];
    if (focusRef && focusRef.current) {
      focusRef.current.focus();
    }
  }, [focusIndex, optRefs]);

  const flatOptions: ContextMenuOption[] = options.reduce(
    (r, opts) => r.concat(opts),
    []
  );

  /**
   * Focuses on the original element, if there was one, then calls the onBlur
   * callback provided in the props.
   */
  function focusOnOriginal() {
    if (originalFocus && originalFocus instanceof HTMLElement) {
      originalFocus.focus();
    }
    closeMenu();
  }

  /**
   * Creates a menu option with all the necessary handlers.
   * @param option the option information.
   * @returns a menu option element.
   */
  function createOption(option: ContextMenuOption) {
    const index = flatOptions.indexOf(option);

    /**
     * Activates the option by calling the option's on click handler, if one
     * was provided. Then, focuses on the original element and requests to
     * close the menu.
     */
    function activate() {
      if (option.onClick) {
        option.onClick(option, index);
      }
      focusOnOriginal();
    }

    return (
      <MenuOption
        key={option.id}
        option={option}
        index={index}
        tabIndex={focusIndex === index ? 0 : -1}
        setRef={setOptRefs}
        onFocus={() => setFocusIndex(() => index)}
        onBlur={(e) => {
          if (index !== focusIndex) {
            return;
          }

          // Check if this blur event is targeting another option
          const target = e.relatedTarget;
          if (target && "getAttribute" in target) {
            const newIndex = parseInt(
              target.getAttribute("data-index") || "-1"
            );
            const ref = optRefs[newIndex];
            if (ref && ref.current === target) {
              return;
            }
          }
          closeMenu();
        }}
        onKeyDown={(e) => {
          const code = e.code;
          const key = e.keyCode;

          // Enter/activate
          let preventDefault = false;
          if (
            code === "Space" ||
            key === 32 ||
            code === "Enter" ||
            key === 13
          ) {
            activate();
            preventDefault = true;
          }

          // Down
          else if (code === "ArrowDown" || key === 40) {
            setFocusIndex((v) => (v < flatOptions.length - 1 ? v + 1 : 0));
            preventDefault = true;
          }

          // Up
          else if (code === "ArrowUp" || key === 38) {
            setFocusIndex((v) => (v > 0 ? v - 1 : flatOptions.length - 1));
            preventDefault = true;
          }

          // Close
          else if (code === "Escape" || key === 27) {
            focusOnOriginal();
            preventDefault = true;
          }

          // Tab
          else if (code === "Tab" || key === 9) {
            focusOnOriginal();
            preventDefault = true;
          }

          if (preventDefault) {
            e.preventDefault();
            e.stopPropagation();
          }

          return false;
        }}
        onClick={() => activate()}
      />
    );
  }

  // Create all the options for the menu
  const formattedOpts = options.map((opts, i) => {
    return (
      <React.Fragment key={i}>
        {opts.map((opt) => createOption(opt))}
      </React.Fragment>
    );
  });
  const allOpts: JSX.Element[] = [];
  for (let i = 0, n = formattedOpts.length; i < n; i++) {
    allOpts.push(formattedOpts[i]);
    if (i < n - 1) {
      allOpts.push(<li key={`sep-${i}`} role="separator"></li>);
    }
  }

  // Determine the class name
  let className = "context-menu";
  if (flatOptions.some((v) => !!v.icon)) {
    className += " with-icons";
  }

  return (
    <ul
      ref={containerRef}
      role="menu"
      id={props.id}
      className={className}
      style={{
        left: align === "left" ? 0 : undefined,
        right: align === "right" ? 0 : undefined,
        top: start === "top" ? 0 : undefined,
        bottom: start === "bottom" ? 0 : undefined,
      }}
    >
      {allOpts}
    </ul>
  );
}

export default ContextMenu;
