import useNavIfSingleEnv from "src/hooks/use-nav-if-single-env/useNavIfSingleEnv";
import EnvSelect from "src/shared/env-select/EnvSelect";
import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";
import SmartNavBar from "src/shared/smart-nav-bar/SmartNavBar";
import SpaceSelect from "src/shared/space-select/SpaceSelect";

export interface FMAppHomeProps {}

export function FMAppHome(props: FMAppHomeProps): JSX.Element {
  useNavIfSingleEnv();
  return (
    <>
      <ProfileAppHeader title="Form Manager" />
      <SmartNavBar />
      <main className="container-max-width">
        <SpaceSelect />
        <EnvSelect />
      </main>
    </>
  );
}

export default FMAppHome;
