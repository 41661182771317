/**
 * Gets the active element in the document, which has focus.
 * @returns the active element (with focus) or null.
 */
export function getActiveElement(): Element | null {
  const focused = document.activeElement;
  return focused && focused !== document.body ? focused : null;
}

/**
 * Checks if an element is focusable.
 * @param element the element to check.
 * @returns true if the element should be able to receive focus.
 */
export function isFocusable(element: Element | null): boolean {
  if (
    !element ||
    !(element instanceof HTMLElement) ||
    element.tabIndex < 0 ||
    (element as any).disabled
  ) {
    return false;
  }
  switch (element.nodeName) {
    case "A":
      return (
        !!(element as HTMLAnchorElement).href &&
        (element as HTMLAnchorElement).rel !== "ignore"
      );
    case "INPUT":
      return (element as HTMLInputElement).type !== "hidden";
    case "BUTTON":
    case "SELECT":
    case "TEXTAREA":
      return true;
  }
  return element.tabIndex >= 0;
}

/**
 * Gets all focusable elements in a container, excluding the container itself.
 * If there is a focusable element within another focusable element, only the
 * ancestor is included.
 * @param container the container with focusable children.
 * @returns an array of focusable child elements, excluding the container.
 */
export function getFocusableElements(container: Element | null): Element[] {
  if (!container) {
    return [];
  }

  let elements: Element[] = [];
  const children = container.children;
  for (let i = 0, n = children.length; i < n; i++) {
    const child = children.item(i);
    if (child && isFocusable(child)) {
      elements.push(child);
    } else {
      elements = elements.concat(getFocusableElements(child));
    }
  }

  return elements;
}

/**
 * Attempts to set focus on a specific node.
 * @param node the node to focus on.
 * @returns true if the node got focused on.
 */
export function attemptFocus(node: Node | EventTarget | null): boolean {
  if (!node || !("focus" in node) || typeof node.focus !== "function") {
    return false;
  }
  try {
    node.focus();
  } catch (e) {}
  return (node as any) === getActiveElement();
}

/**
 * Checks if a container has another node in it or if they are the same.
 * @param container the container node.
 * @param node the node to check.
 * @returns true if the node is contained in the container or if they are the same.
 */
export function containerHas(
  container: Node | null,
  node: Node | EventTarget | null
): boolean {
  if (!container || !(node instanceof Node)) {
    return false;
  }
  if (container === node) {
    return true;
  }
  return container.contains(node);
}
