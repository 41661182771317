import { useContext, useEffect, useState } from "react";
import PageContext, {
  Page,
  PageContextData,
} from "../../../context/page-context/PageContext";

/**
 * The properties for the {@link PageContainer} component.
 */
export interface PageContainerProps {
  /** The current page. */
  current: Page;
  /** Page content. */
  children?: any;
}

/**
 * A page container displays whatever child content is passed without adding
 * any UI elements, but also updates the {@link PageContext} by adding a new
 * page to the context data.
 */
export function PageContainer(props: PageContainerProps) {
  const parentPageContext = useContext(PageContext);
  const parentPage = parentPageContext.current;
  const { current } = props;
  const [pageContext, setPageContext] = useState<PageContextData>({
    ancestors: [...parentPageContext.ancestors].concat(
      parentPage ? [parentPage] : []
    ),
    current,
  });

  useEffect(() => {
    setPageContext({
      ancestors: [...parentPageContext.ancestors].concat(
        parentPage ? [parentPage] : []
      ),
      current,
    });
  }, [current, parentPageContext, parentPage]);

  return (
    <PageContext.Provider value={pageContext}>
      {props.children}
    </PageContext.Provider>
  );
}

export default PageContainer;
