import { Colour } from "@formatlas/types";
import {
  chooseHighestContrastColour,
  COLOUR_TEXT,
  COLOUR_BG,
} from "@formatlas/react";

import "./ColourCard.css";

export interface ColourCardProps {
  colour: Colour;
  label?: string;
}

export function ColourCard(props: ColourCardProps) {
  const { name, colour } = props.colour;
  const other = chooseHighestContrastColour(colour, COLOUR_TEXT, COLOUR_BG);
  return (
    <div className="flex grow container-flex fd-col gap-xxs">
      {props.label && <b>{props.label}</b>}
      <div
        className="colour-card p-s flex grow"
        style={{ background: colour, color: other }}
      >
        <span className="select-all">{colour.toUpperCase()}</span>
        {name ? " - " + name : ""}
      </div>
    </div>
  );
}

export default ColourCard;
