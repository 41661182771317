import { ContentType, EnvironmentID } from "@formatlas/types";

export function formatEnvironmentID(id: EnvironmentID): string {
  if (id === "prod") {
    return "Production";
  } else if (id === "qa") {
    return "Quality Assurance";
  } else if (id === "dev") {
    return "Development";
  }
  return id;
}

export function formatContentType(
  type: ContentType,
  plural = false,
  asTitle = false
) {
  let result: string = type;
  if (plural) result += "s";
  if (asTitle) result = result.charAt(0).toUpperCase() + result.slice(1);
  return result;
}
