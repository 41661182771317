import { formatDateTime } from "../../utils/format";

/**
 * The properties for the {@link CreatedUpdated} component.
 */
export interface CreatedUpdatedProps {
  /** The component's DOM id. */
  id?: string;

  /** Extra classes to be added to the component. */
  className?: string;

  /** The record containing the created and updated timestamps. */
  data: {
    /** The created time. */
    created: any;
    /** The updated time. */
    updated: any;
    [key: string]: any;
  };
}

/**
 * A component that shows created and updated timestamps in a standard format.
 */
export function CreatedUpdated(props: CreatedUpdatedProps) {
  let className = "font-info-light container-flex gap-xxs wrap";
  if (props.className) {
    className += " " + props.className;
  }

  const { created, updated } = props.data;

  function showTime(v: any) {
    const date = new Date(v);
    const isoDate = date.getTime() ? date.toISOString() : undefined;
    return <time dateTime={isoDate}>{formatDateTime(date)}</time>;
  }

  return (
    <p id={props.id} className={className}>
      <span className="flex s-col-100">
        <b>Created</b>: {showTime(created)}
      </span>
      <span className="flex s-col-100">
        <b>Updated</b>: {showTime(updated)}
      </span>
    </p>
  );
}

export default CreatedUpdated;
