import { useContext, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import SpaceContext from "src/context/space-context/SpaceContext";

/**
 * If the current space only has one environment available, this will
 * automatically add `/env/{envId}` to the URL.
 */
export function useNavIfSingleEnv() {
  const spaceContext = useContext(SpaceContext);

  const navigate = useNavigate();
  const singleEnv = spaceContext.singleEnv;

  useLayoutEffect(() => {
    if (singleEnv) {
      navigate(`env/${singleEnv.id}`);
    }
  }, [singleEnv, navigate]);
}

export default useNavIfSingleEnv;
