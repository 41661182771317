import { Banner, Button, ButtonBar } from "@formatlas/react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import ContentContext from "src/context/content-context/ContentContext";
import ContentVersionContext from "src/context/content-version-context/ContentVersionContext";
import ErrorPage from "src/pages/errors/error-page/ErrorPage";
import LoadingPage from "src/pages/loading-page/LoadingPage";
import FEFullStyleEditor from "../editors/fe-style-editor/FEFullStyleEditor";

export interface FEVersionViewerProps {}

export function FEVersionViewer(props: FEVersionViewerProps) {
  const { versionID } = useParams();

  const {
    type,
    typeLabel,
    typeLabelTitle,
    versions,
    versionsLastLoaded,
    versionLoadError,
  } = useContext(ContentContext);
  const version = versions.find((v) => v.id === versionID);

  if (!version) {
    if (!versionsLastLoaded) {
      return <LoadingPage />;
    }
    if (versionLoadError) {
      return (
        <ErrorPage title="Version Data Missing" showNavLinks>
          <Banner type="error" className="mb-l">
            {versionLoadError}
          </Banner>
          <ButtonBar className="m-l">
            <Button linkURL="..">Return to {typeLabelTitle}</Button>
          </ButtonBar>
        </ErrorPage>
      );
    }
    return (
      <ErrorPage title="Version Not Found" showNavLinks>
        <Banner type="error" className="mb-l">
          The {typeLabel} version you are looking for could not be found.
        </Banner>
        <ButtonBar className="m-l">
          <Button linkURL="..">Return to {typeLabelTitle}</Button>
        </ButtonBar>
      </ErrorPage>
    );
  }
  return (
    <ContentVersionContext.Provider value={{ version }}>
      {type === "form" && <>TODO form editor</>}
      {type === "component" && <>TODO component editor</>}
      {type === "style" && <FEFullStyleEditor />}
      {type === "resource" && <>TODO resource editor</>}
    </ContentVersionContext.Provider>
  );
}

export default FEVersionViewer;
