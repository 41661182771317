import { AppHeader, MenuOption } from "@formatlas/react";
import app from "app";

/**
 * The properties for the {@link ProfileAppHeader} component.
 */
export interface ProfileAppHeaderProps {
  /** The page title that appears next to the Form Atlas logo. */
  title?: string;

  /** The URL the page should navigate to if the title is clicked. */
  titleLink?: string;

  /** If true, the header content will take the full page width. */
  fullWidth?: boolean;

  /** The URL the page should navigate to if the profile is clicked. */
  profileLink?: string;

  /** The name (or message) to display for the profile. E.g. "Alice Doe", "Not Logged In", etc. */
  profileName?: string;

  /** The navigation menu options. */
  navOptions?: MenuOption[];
}

/**
 * A header component that can be placed at the top of the page. It contains a
 * title, and profile link/name. It extends the {@link AppHeader} component by
 * providing the profile information.
 */
export default function ProfileAppHeader(
  props: ProfileAppHeaderProps
): JSX.Element {
  return (
    <AppHeader
      title={props.title ?? "My Form Atlas"}
      titleLink={props.titleLink}
      fullWidth={props.fullWidth}
      profileLink={props.profileLink ?? "/profile"}
      profileName={props.profileName ?? app.getProfileName()}
      navOptions={props.navOptions}
    />
  );
}
