import { Plan } from "@formatlas/types";

import "./PlanCard.css";
import {
  formatCurrency,
  formatNumberInLocale,
  formatPercent1,
} from "@formatlas/react";
import { useId } from "react";

export interface PlanCardProps {
  plan: Plan;
  recommended?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

function PlanFeature(props: {
  feature: string;
  value: string;
  type?: "good" | "bad";
}) {
  let className = "container-flex plan-card-feature font-info ai-start";
  if (props.type === "good") {
    className += " bg-fb-info";
  } else if (props.type === "bad") {
    className += " bg-fb-neg";
  }
  return (
    <p className={className}>
      <span className="flex col-70">{props.feature}</span>
      <span className="flex col-30 text-right">{props.value}</span>
    </p>
  );
}

export function PlanCard(props: PlanCardProps): JSX.Element {
  const locale = "en";

  const id = useId();

  const plan = props.plan;
  const features = plan.features;
  let className = "plan-card flex col-20 l-col-30 m-col-40 s-col-100";
  if (props.recommended) {
    className += " recommended";
  }
  if (props.selected) {
    className += " selected";
  }
  const savings =
    (plan.pricePerMonth * 12 - plan.pricePerYear) / (plan.pricePerMonth * 12);
  return (
    <label
      htmlFor={id}
      className={className}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      <p className="font-h3 m-bottom-xxs">
        <input
          id={id}
          type="radio"
          name="space-plan"
          defaultChecked={!!props.selected}
          required
        />{" "}
        {plan.name}
      </p>
      <p className="font-info m-bottom-s">
        {formatCurrency(plan.pricePerMonth, locale)} per month
        <br />
        {formatCurrency(plan.pricePerYear)} per year
        {savings ? <> (save {formatPercent1(savings, locale)})</> : null}
      </p>
      <p className="bold">Features:</p>
      <PlanFeature
        type="good"
        feature="Users"
        value={formatNumberInLocale(features.space.users, 0, 0, locale)}
      />
      <PlanFeature
        type="good"
        feature="Form Submissions per month"
        value={formatNumberInLocale(
          features.forms.submissionsPerMonth,
          0,
          0,
          locale
        )}
      />
      <PlanFeature
        type={features.env.dev ? "good" : "bad"}
        feature="Dev Environment"
        value={features.env.dev ? "Yes" : "No"}
      />
      <PlanFeature
        type={features.env.qa ? "good" : "bad"}
        feature="QA Environment"
        value={features.env.qa ? "Yes" : "No"}
      />
      <PlanFeature
        type={features.space.vanityID ? "good" : "bad"}
        feature="Custom URLs"
        value={features.space.vanityID ? "Yes" : "No"}
      />
      {plan.description && <p className="m-top-s">{plan.description}</p>}
    </label>
  );
}

export default PlanCard;
