export type MFAAppID =
  | "dashboard"
  | "form-editor"
  | "form-manager"
  | "settings";

export interface MFAApp {
  id: MFAAppID;
  name: string;
  shortDescription: string;
  icon: string;
  uri: string;
}

export const appsByID: { [Property in MFAAppID]: MFAApp } = {
  dashboard: {
    id: "dashboard",
    name: "Dashboard",
    shortDescription: "Monitor usage.",
    icon: "analytics",
    uri: "dashboard",
  },
  "form-editor": {
    id: "form-editor",
    name: "Form Editor",
    shortDescription: "View and update forms.",
    icon: "construction",
    uri: "form-editor",
  },
  "form-manager": {
    id: "form-manager",
    name: "Form Manager",
    shortDescription: "Manage forms.",
    icon: "admin_panel_settings",
    uri: "form-manager",
  },
  settings: {
    id: "settings",
    name: "Settings",
    shortDescription: "View and update settings.",
    icon: "settings",
    uri: "settings",
  },
};

export const apps: MFAApp[] = Object.keys(appsByID).map(
  (id) => appsByID[id as MFAAppID]
);
