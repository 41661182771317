import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { COMPANY_NAME } from "../../../constants";
import Icon from "../icon/Icon";
import NavMenu from "../nav-menu/NavMenu";
import MenuOption from "../../models/MenuOption";

import "./AppHeader.css";
import { Button } from "../button/Button";

/** The default app header title. */
const DEFAULT_TITLE = COMPANY_NAME;

/**
 * The properties for the {@link AppHeader} component.
 */
export interface AppHeaderProps {
  /** The component's DOM id. */
  id?: string;

  /** Extra classes to be added to the component. */
  className?: string;

  /** The page title that appears next to the Form Atlas logo. */
  title?: string | JSX.Element;

  /** The URL the page should navigate to if the title is clicked. */
  titleLink?: string;

  /** If true, the header content will take the full page width. */
  fullWidth?: boolean;

  /** The URL the page should navigate to if the profile is clicked. */
  profileLink?: string;

  /** The name (or message) to display for the profile. E.g. "Alice Doe", "Not Logged In", etc. */
  profileName?: string;

  /** The navigation menu options. */
  navOptions?: MenuOption[];
}

/**
 * A header component that can be placed at the top of the page. It contains a
 * title, and profile link/name.
 */
export function AppHeader(props: AppHeaderProps): JSX.Element {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // For determining if the large or small menu should be shown
  const [showSmallMenu, setShowSmallMenu] = useState(false);
  // If the small menu is shown, what state is it
  const [isSmallMenuVisible, setIsSmallMenuVisible] = useState(false);

  // Dimensions for calculations
  const [titleXStart, setTitleXStart] = useState(0);
  const [titleXEnd, setTitleXEnd] = useState(0);
  const [profileWidth, setProfileWidth] = useState(0);

  const titleRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);

  const hasNavMenu =
    (Array.isArray(props.navOptions) && !!props.navOptions.length) ||
    !!(props.profileLink && props.profileName);

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  useLayoutEffect(() => {
    if (titleRef.current) {
      const titleRect = titleRef.current.getBoundingClientRect();
      if (titleRect.width) {
        setTitleXStart(titleRect.x);
        setTitleXEnd(titleRect.x + titleRect.width);
      }
    }
  }, [titleRef]);

  useLayoutEffect(() => {
    if (profileRef.current) {
      const profileRect = profileRef.current.getBoundingClientRect();
      if (profileRect.width) {
        setProfileWidth(profileRect.width);
      }
    }
  }, [profileRef]);

  useLayoutEffect(() => {
    // Determine if small menu should be shown
    if (
      hasNavMenu &&
      titleXEnd +
        16 * 3 /* flex gap */ +
        20 /* extra space to prevent distorted layout near small/large screen width */ +
        profileWidth <
        windowWidth - titleXStart
    ) {
      setShowSmallMenu(false);
    } else {
      setShowSmallMenu(true);
    }
  }, [
    windowWidth,
    props.fullWidth,
    hasNavMenu,
    titleXStart,
    titleXEnd,
    profileWidth,
  ]);

  let className = "fa-header";
  if (props.className) {
    className += " " + props.className;
  }

  // Create the title
  const titleClass = "no-underline flex-container fa-header-title";
  const title = props.title ?? DEFAULT_TITLE;
  const titleContent = (
    <>
      <span className="fa-logo logo64x64" aria-hidden="true"></span>
      {typeof title === "string" ? (
        <h1 className="font-title">{title}</h1>
      ) : (
        title
      )}
    </>
  );

  // Create the profile
  const profileClass = "account";
  let profile = null;
  if (props.profileName) {
    profile = (
      <span className={profileClass}>
        <span className="account-display">{props.profileName}</span>
        <Icon name="account_circle" />
      </span>
    );
  }

  return (
    <header className={className} id={props.id}>
      <div
        className={
          "flex-container app-header-content" +
          (props.fullWidth ? "" : " container-max-width")
        }
      >
        <div className="flex-container title-and-menu" ref={titleRef}>
          {props.titleLink ? (
            <Link to={props.titleLink} className={titleClass}>
              {titleContent}
            </Link>
          ) : (
            <div className={titleClass}>{titleContent}</div>
          )}
          {hasNavMenu && !showSmallMenu && (
            <div className="large-menu-container">
              <NavMenu type="large" options={props.navOptions} />
            </div>
          )}
        </div>
        {!showSmallMenu && (
          <div ref={profileRef}>
            {profile ? (
              props.profileLink ? (
                <Link to={props.profileLink} className="no-underline">
                  {profile}
                </Link>
              ) : (
                profile
              )
            ) : null}
          </div>
        )}
        {hasNavMenu &&
          showSmallMenu &&
          (isSmallMenuVisible ? (
            <Button
              type="icon"
              onClick={() => setIsSmallMenuVisible(false)}
              title="Close navigation menu"
              aria-label="Close navigation menu"
            >
              <Icon name="close" className="bg-primary-inverse" />
            </Button>
          ) : (
            <Button
              type="icon"
              onClick={() => setIsSmallMenuVisible(true)}
              title="Open navigation menu"
              aria-label="Open navigation menu"
            >
              <Icon name="menu" className="bg-primary-inverse" />
            </Button>
          ))}
      </div>
      {hasNavMenu && showSmallMenu && isSmallMenuVisible && (
        <NavMenu
          type="small"
          options={props.navOptions}
          profileLink={props.profileLink}
          profileName={props.profileName}
        />
      )}
    </header>
  );
}

export default AppHeader;
