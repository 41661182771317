import { Link } from "react-router-dom";
import "./SpaceAppCard.css";
import { Icon } from "@formatlas/react";
import { MFAAppID, appsByID } from "src/utils/apps";

export interface SpaceAppCardProps {
  className?: string;
  appID: MFAAppID;
}

export function SpaceAppCard(props: SpaceAppCardProps): JSX.Element {
  const app = appsByID[props.appID];
  return (
    <div className={props.className}>
      <Link
        to={`./${app.uri}`}
        className="space-app-card card container-flex gap-s ai-center shadow-hover"
      >
        <Icon name={app.icon} size="large" />
        <span className="space-app-card-details">
          <span className="bold">{app.name}</span>
          {app.shortDescription && (
            <span className="m-top-xs">{app.shortDescription}</span>
          )}
        </span>
      </Link>
    </div>
  );
}

export default SpaceAppCard;
