import { collection as fsCollection, doc as fsDoc } from "firebase/firestore";
import { ContentType } from "@formatlas/types";
import app from "app";

// Source: formatlas-apps-backend/functions/src/utils/db.ts
// To update:
// 1. Copy everything from source file after `doc` function
// 2. Replace content after `doc` function in this file
// 3. If any helper functions/types are missing, copy/import them
// 4. Replace the "@return \{[^\}]+\} " with "@return "

/**
 * Determines the collection name where content related to a specified
 * content type is stored in firestore.
 * @param {ContentType} contentType the type of content.
 * @return the collection name.
 */
function convertContentTypeToCollection(contentType: ContentType): string {
  if (contentType === "form") {
    return "forms";
  } else if (contentType === "component") {
    return "ui";
  } else if (contentType === "style") {
    return "styles";
  } else if (contentType === "resource") {
    return "res";
  }
  throw new Error("Invalid argument: content type is not a valid value.");
}

/**
 * Gets path segments to space content.
 * @param {string} spaceID the space ID.
 * @param {ContentType} contentType the type of content.
 * @return the path segments.
 */
function getSpaceContentSegments(
  spaceID: string,
  contentType: ContentType
): string[] {
  return ["spaces", spaceID, convertContentTypeToCollection(contentType)];
}

/**
 * Creates a firestore collection reference with the specified path.
 * @param {string[]} segments the firestore path segments.
 * @return the collection reference.
 */
function collection(...segments: string[]) {
  return fsCollection(
    app.getFirestore(),
    segments[0],
    ...segments.filter((_, i) => i > 0)
  );
}

/**
 * Creates a firestore document reference with the specified path.
 * @param {string[]} segments the firestore path segments.
 * @return the document reference.
 */
function doc(...segments: string[]) {
  return fsDoc(
    app.getFirestore(),
    segments[0],
    ...segments.filter((_, i) => i > 0)
  );
}

/**
 * Creates a firestore collection reference to `/users`.
 * @return the collection reference.
 */
export function users() {
  return collection("users");
}

/**
 * Creates a firestore document reference to `/users/{id}`.
 * @param {string} id the user ID.
 * @return the document reference.
 */
export function user(id: string) {
  return doc("users", id);
}

/**
 * Creates a firestore collection reference to `/users/{id}/spaces`.
 * @param {string} id the user ID.
 * @return the collection reference.
 */
export function userSpaces(id: string) {
  return collection("users", id, "spaces");
}

/**
 * Creates a firestore document reference to `/users/{userID}/spaces/{spaceID}`.
 * @param {string} userID the user ID.
 * @param {string} spaceID the user space ID.
 * @return the document reference.
 */
export function userSpace(userID: string, spaceID: string) {
  return doc("users", userID, "spaces", spaceID);
}

/**
 * Creates a firestore collection reference to `/spaces`.
 * @return the collection reference.
 */
export function spaces() {
  return collection("spaces");
}

/**
 * Creates a firestore document reference to `/spaces/{id}`.
 * @param {string} id the space ID.
 * @return the document reference.
 */
export function space(id: string) {
  return doc("spaces", id);
}

/**
 * Creates a firestore collection reference to `/spaces/{spaceID}/users`.
 * @param {string} id the space ID.
 * @return the collection reference.
 */
export function spaceUsers(id: string) {
  return collection("spaces", id, "users");
}

/**
 * Creates a firestore document reference to `/spaces/{spaceID}/users/{userID}`.
 * @param {string} spaceID the space ID.
 * @param {string} userID the space user ID.
 * @return the document reference.
 */
export function spaceUser(spaceID: string, userID: string) {
  return doc("spaces", spaceID, "users", userID);
}

/**
 * Creates a firestore collection reference to `/spaces/{id}/permissions`.
 * @param {string} id the space ID.
 * @return the collection reference.
 */
export function spacePermissions(id: string) {
  return collection("spaces", id, "permissions");
}

/**
 * Creates a firestore document reference to `/spaces/{spaceID}/permissions/{permissionID}`.
 * @param {string} spaceID the space ID.
 * @param {string} permissionID the space permission ID.
 * @return the document reference.
 */
export function spacePermission(spaceID: string, permissionID: string) {
  return doc("spaces", spaceID, "permissions", permissionID);
}

/**
 * Creates a firestore collection reference to
 * `/spaces/{spaceID}/permissions/{permissionID}/users`.
 * @param {string} spaceID the space ID.
 * @param {string} permissionID the space permission ID.
 * @return the collection reference.
 */
export function spacePermissionUsers(spaceID: string, permissionID: string) {
  return collection("spaces", spaceID, "permissions", permissionID, "users");
}

/**
 * Creates a firestore document reference to
 * `/spaces/{spaceID}/permissions/{permissionID}/users/{userID}`.
 * @param {string} spaceID the space ID.
 * @param {string} permissionID the space permission ID.
 * @param {string} userID the space user ID.
 * @return the document reference.
 */
export function spacePermissionUser(
  spaceID: string,
  permissionID: string,
  userID: string
) {
  return doc("spaces", spaceID, "permissions", permissionID, "users", userID);
}

/**
 * Creates a firestore collection reference to `/spaces/{id}/env`.
 * @param {string} id the space ID.
 * @return the collection reference.
 */
export function spaceEnvs(id: string) {
  return collection("spaces", id, "env");
}

/**
 * Creates a firestore document reference to `/spaces/{spaceID}/env/{envID}`.
 * @param {string} spaceID the space ID.
 * @param {string} envID the space environment ID.
 * @return the document reference.
 */
export function spaceEnv(spaceID: string, envID: string) {
  return doc("spaces", spaceID, "env", envID);
}

/**
 * Creates a firestore collection reference to `/spaces/{spaceID}/{contentType}`.
 * @param {string} spaceID the space ID.
 * @param {ContentType} contentType the type of content.
 * @return the collection reference.
 */
export function content(spaceID: string, contentType: ContentType) {
  return collection(...getSpaceContentSegments(spaceID, contentType));
}

/**
 * Creates a firestore document reference to `/spaces/{spaceID}/{contentType}/{contentID}`.
 * @param {string} spaceID the space ID.
 * @param {ContentType} contentType the type of content.
 * @param {string} contentID the content ID.
 * @return the document reference.
 */
export function contentItem(
  spaceID: string,
  contentType: ContentType,
  contentID: string
) {
  return doc(...getSpaceContentSegments(spaceID, contentType), contentID);
}

/**
 * Creates a firestore collection reference to `/spaces/{spaceID}/{contentType}/{contentID}/v`.
 * @param {string} spaceID the space ID.
 * @param {ContentType} contentType the type of content.
 * @param {string} contentID the content ID.
 * @return the collection reference.
 */
export function contentVersions(
  spaceID: string,
  contentType: ContentType,
  contentID: string
) {
  return collection(
    ...getSpaceContentSegments(spaceID, contentType),
    contentID,
    "v"
  );
}

/**
 * Creates a firestore document reference to `/spaces/{spaceID}/{contentType}/{contentID}/v/{versionID}`.
 * @param {string} spaceID the space ID.
 * @param {ContentType} contentType the type of content.
 * @param {string} contentID the content ID.
 * @param {string} versionID the content version ID.
 * @return the document reference.
 */
export function contentVersion(
  spaceID: string,
  contentType: ContentType,
  contentID: string,
  versionID: string
) {
  return doc(
    ...getSpaceContentSegments(spaceID, contentType),
    contentID,
    "v",
    versionID
  );
}

/**
 * Creates a firestore collection reference to `/spaces/{spaceID}/{contentType}/{contentID}/v/{versionID}/d`.
 * @param {string} spaceID the space ID.
 * @param {ContentType} contentType the type of content.
 * @param {string} contentID the content ID.
 * @param {string} versionID the content version ID.
 * @return the collection reference.
 */
export function contentVersionDefinitions(
  spaceID: string,
  contentType: ContentType,
  contentID: string,
  versionID: string
) {
  return collection(
    ...getSpaceContentSegments(spaceID, contentType),
    contentID,
    "v",
    versionID,
    "d"
  );
}

/**
 * Creates a firestore document reference to
 * `/spaces/{spaceID}/{contentType}/{contentID}/v/{versionID}/d/{definitionID}`.
 * @param {string} spaceID the space ID.
 * @param {ContentType} contentType the type of content.
 * @param {string} contentID the content ID.
 * @param {string} versionID the content version ID.
 * @param {string} definitionID the definition ID.
 * @return the document reference.
 */
export function contentVersionDefinition(
  spaceID: string,
  contentType: ContentType,
  contentID: string,
  versionID: string,
  definitionID: string
) {
  return doc(
    ...getSpaceContentSegments(spaceID, contentType),
    contentID,
    "v",
    versionID,
    "d",
    definitionID
  );
}

/**
 * Creates a firestore collection reference to
 * `/spaces/{spaceID}/{contentType}/{contentID}/c`.
 * @param {string} spaceID the space ID.
 * @param {ContentType} contentType the type of content.
 * @param {string} contentID the content ID.
 * @param {string} componentID the content component ID.
 * @return the collection reference.
 */
export function contentComponents(
  spaceID: string,
  contentType: ContentType,
  contentID: string
) {
  return collection(
    ...getSpaceContentSegments(spaceID, contentType),
    contentID,
    "c"
  );
}

/**
 * Creates a firestore document reference to
 * `/spaces/{spaceID}/{contentType}/{contentID}/c/{componentID}`.
 * @param {string} spaceID the space ID.
 * @param {ContentType} contentType the type of content.
 * @param {string} contentID the content ID.
 * @param {string} componentID the content component ID.
 * @return the document reference.
 */
export function contentComponent(
  spaceID: string,
  contentType: ContentType,
  contentID: string,
  componentID: string
) {
  return doc(
    ...getSpaceContentSegments(spaceID, contentType),
    contentID,
    "c",
    componentID
  );
}

/**
 * Creates a firestore document reference to `/spaces/{id}/details/settings`.
 * @param {string} id the space ID.
 * @return the document reference.
 */
export function spaceSettings(id: string) {
  return doc("spaces", id, "details", "settings");
}

/**
 * Creates a firestore document reference to `/spaces/{id}/details/billing`.
 * @param {string} id the space ID.
 * @return the document reference.
 */
export function spaceBilling(id: string) {
  return doc("spaces", id, "details", "billing");
}

/**
 * Creates a firestore collection reference to `/plans`.
 * @return the collection reference.
 */
export function plans() {
  return collection("plans");
}

/**
 * Creates a firestore document reference to `/plans/{id}`.
 * @param {string} id the plan ID.
 * @return the document reference.
 */
export function plan(id: string) {
  return doc("plans", id);
}
