import { useEffect, useId, useState } from "react";
import FieldError from "../field-error/FieldError";

/**
 * The properties for the {@link TextArea} component.
 */
export interface TextAreaProps {
  /** The label content. */
  label?: JSX.Element | string;

  /** The DOM id to use for the text area container. */
  id?: string;

  /** The DOM name attribute value. */
  name?: string;

  /** The field container class name. */
  className?: string;

  /** The text area value. */
  value?: string;

  /** The text area height, in pixels. */
  height?: number;

  /** The text area on hover title. */
  title?: string;

  /** The text area placeholder value. */
  placeholder?: string;

  /** The text area autocomplete value. */
  autoComplete?: string;

  /** The text area spellcheck value. */
  spellCheck?: boolean;

  /** The maximum number of characters that can be entered into the field. */
  maxLength?: number;

  /** Show a character limit, if a max length was specified. */
  showCharLimit?: boolean;

  /** Flag indicating if the text area is disabled or not. */
  disabled?: boolean;

  /** Flag indicating if the text area value is required. */
  required?: boolean;

  /** Flag indicating if the text area is invalid (i.e. has an error). */
  hasError?: boolean;

  /** An error message to display. */
  error?: string | JSX.Element;

  /** An event handler triggered when the user changes the field value. */
  onChange?: (
    value: string,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => any;

  /** An event handler triggered when the user focuses on the field. */
  onFocus?: (
    isFirstVisit: boolean,
    event: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => any;

  /** An event handler triggered when the user removes focus from the field. */
  onBlur?: (
    value: string,
    event: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => any;

  /** A function that formats the current value to a new value (e.g. to auto
   * add spaces, dashes, etc.). */
  formatter?: (value: string) => string;

  "aria-describedby"?: string;
}

/**
 * A simple text area to collect multi-line input from the user.
 */
export function TextArea(props: TextAreaProps): JSX.Element {
  const id = useId();
  const [isVisited, setIsVisited] = useState(false);
  const [value, setValue] = useState(props.value || "");

  const formatter = props.formatter;

  useEffect(() => {
    if (formatter) {
      setValue(formatter(value));
    }
  }, [value, formatter]);

  let className = "field text-area";
  if (props.className) {
    className += " " + props.className;
  }
  if (props.hasError) {
    className += " error";
  }
  const style =
    typeof props.height === "number"
      ? { height: props.height + "px" }
      : undefined;
  return (
    <div id={props.id} className={className}>
      {props.label && (
        <label htmlFor={id}>
          {props.label}
          {props.required && <span className="c-fb-neg-dark"> *</span>}
        </label>
      )}
      <textarea
        style={style}
        id={id}
        value={value}
        name={props.name}
        title={props.title}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        spellCheck={props.spellCheck}
        maxLength={props.maxLength}
        disabled={props.disabled}
        required={props.required}
        onChange={(event) => {
          if (formatter) {
            event.target.value = formatter(event.target.value);
          }
          setValue(event.target.value);
          props.onChange && props.onChange(event.target.value, event);
        }}
        onFocus={(event) => {
          const isFirstVisit = !isVisited;
          if (isFirstVisit) {
            setIsVisited(true);
          }
          props.onFocus && props.onFocus(isFirstVisit, event);
        }}
        onBlur={(event) =>
          props.onBlur && props.onBlur(event.target.value, event)
        }
        aria-invalid={props.hasError}
        aria-describedby={
          props.error ? id + "-error" : props["aria-describedby"]
        }
      ></textarea>
      {props.maxLength !== undefined && props.showCharLimit && (
        <p className="font-info text-right m-top-xxs">
          {value.length + " / " + props.maxLength}
        </p>
      )}
      {props.error && <FieldError id={id + "-error"} error={props.error} />}
    </div>
  );
}

export default TextArea;
