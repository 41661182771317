import Icon from "../../core/icon/Icon";

/**
 * The properties for the {@link FieldError} component.
 */
export interface FieldErrorProps {
  /** The DOM id to use for the container. */
  id?: string;

  /** The error to display. */
  error: string | JSX.Element;
}

/**
 * Displays an error message; for use below a form field.
 */
export function FieldError(props: FieldErrorProps): JSX.Element {
  return (
    <div id={props.id} className="c-fb-neg-dark icon-start m-top-xs">
      <Icon name="error" />
      {props.error}
    </div>
  );
}

export default FieldError;
