import { useEffect, useState } from "react";
import Toast from "../../models/Toast";

/**
 * A function which sets the list of toasts to display.
 */
export type ToastSetter = (toasts: Toast[]) => void;

/**
 * The properties for the {@link Toasts} component.
 */
export interface ToastsProps {
  /**
   * A function that when called, will update the toasts being displayed by
   * this component.
   */
  onUpdate: (setter: ToastSetter) => void;
}

/**
 * A container for toasts, which are passed through a setter instead of props
 * to allow the list of toasts to change outside the render function.
 *
 * Note: this component is meant to be rendered by the `ToastManager`.
 */
export function Toasts(props: ToastsProps): JSX.Element {
  const [toasts, setToasts] = useState<Toast[]>([]);

  // We want the parent (which should be controlled by a ToastManager) to be
  // able to send the updated list of toasts whenever the list changes
  const onUpdate = props.onUpdate;
  useEffect(() => {
    onUpdate((toasts) => {
      setToasts(toasts.map((x) => x));
    });
  }, [onUpdate]);

  return (
    <div className="container-toasts">
      {toasts.map((toast) => {
        return (
          <output key={toast.id} className="toast">
            {toast.label}
          </output>
        );
      })}
    </div>
  );
}

export default Toasts;
