import {
  Banner,
  Button,
  ButtonBar,
  ColourPicker,
  generateID,
  TextField,
  toast,
} from "@formatlas/react";
import { FEStyleEditorProps } from "../FEStyleEditor";
import ColourCard from "src/shared/colour-card/ColourCard";
import { orderColours, OrderedColour } from "src/utils/content";
import { useContext, useEffect, useState } from "react";
import ContentContext from "src/context/content-context/ContentContext";
import ContentVersionContext from "src/context/content-version-context/ContentVersionContext";
import { eachMappedValue } from "src/utils/data-utils";
import { StyleDefDocData } from "@formatlas/types";
import RefreshContext from "src/context/refresh-context/RefreshContext";

export interface FEStyleColourEditorProps extends FEStyleEditorProps {}

interface EditableColour extends OrderedColour {
  updated?: boolean;
  new?: boolean;
}

function isColourUsed(testID: string, current: StyleDefDocData) {
  let isUsed = false;
  eachMappedValue(current.shadows, (value) => {
    value.shadows.forEach(({ c }) => {
      if (c === testID) {
        isUsed = true;
      }
    });
  });
  if (isUsed) return isUsed;
  eachMappedValue(current.outlines, ({ c }) => {
    if (c === testID) {
      isUsed = true;
    }
  });
  if (isUsed) return isUsed;
  [current.lightColours, current.darkColours].forEach((theme) => {
    if (!theme || isUsed) return;
    Object.keys(theme).forEach((id) => {
      if (theme[id as keyof typeof theme] === testID) {
        isUsed = true;
      }
    });
  });
  return isUsed;
}

const MAX_COLOURS = 100;

export function FEStyleColourEditor({
  data,
  readonly,
}: FEStyleColourEditorProps) {
  const { cm, content } = useContext(ContentContext);
  const { version } = useContext(ContentVersionContext);
  const { refresh } = useContext(RefreshContext);
  const contentID = content.id;
  const versionID = version.id;

  const { allColours } = data;

  const [filter, setFilter] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [colours, setColours] = useState<EditableColour[]>(
    orderColours(allColours)
  );

  useEffect(() => {
    setColours((colours) => {
      const ordered = orderColours(allColours);
      const newColours: EditableColour[] = ordered.map((v) => {
        const old = colours.find((old) => old.id === v.id);
        return old?.updated || old?.new ? old : v;
      });
      colours.forEach((v) => {
        if (v.new && !newColours.find((c) => c.id === v.id)) {
          newColours.push(v);
        }
      });

      return newColours;
    });
  }, [allColours]);

  function onUpdate() {
    const updates = {
      set: colours
        .filter((v) => v.new || v.updated)
        .map((v) => ({ id: v.id, value: v.value })),
      remove: Object.keys(allColours).filter(
        (id) => !colours.find((v) => v.id === id)
      ),
    };
    if (!updates.set.length && !updates.remove.length) {
      setIsEditing(false);
      return;
    }

    // Update the doc via the API
    setIsUpdating(true);
    cm.updateStyleContent({
      contentID,
      versionID,
      colours: updates,
    })
      .then((res) => {
        if (res.failed) {
          console.error("Failed to update colours.", res);
          toast.add({
            label: "Failed to update colours.",
            type: "error",
          });
        } else {
          refresh()
            .catch((err) => {
              console.error("Failed to get refreshed data.", err);
            })
            .finally(() => setIsEditing(false));
        }
      })
      .catch((err) => {
        console.error("Failed to update colours.", err);
        toast.add({
          label: "Failed to update colours.",
          type: "error",
        });
      })
      .finally(() => setIsUpdating(false));
  }

  const filterField = (
    <TextField
      label="Search for Colours"
      className="flex col-30 s-col-100"
      value={filter}
      onChange={(v) => setFilter(v)}
    />
  );

  const filterLower = filter.toLowerCase();
  const filteredColours = filter
    ? colours.filter(
        (colour) =>
          colour.value.name.toLowerCase().indexOf(filterLower) >= 0 ||
          colour.value.colour.toLowerCase().indexOf(filterLower) >= 0
      )
    : colours;
  const filterCount = (
    <p className="font-info mb-xs">
      Showing {filteredColours.length} of {colours.length}.
    </p>
  );

  if (!isEditing) {
    return (
      <>
        <div
          className={
            "m-m container-flex wrap gap-s ai-center jc-" +
            (readonly ? "end" : "between")
          }
        >
          {!readonly && (
            <Button
              type="tertiary"
              icon="edit"
              onClick={() => {
                setColours(orderColours(allColours));
                setIsEditing(true);
              }}
            >
              Edit Colours
            </Button>
          )}
          {filterField}
        </div>
        {filterCount}
        {filteredColours.map((colour) => (
          <div key={colour.id} className="m-xxs">
            <ColourCard colour={colour.value} />
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      <div className="m-m container-flex wrap gap-s ai-center jc-between">
        <ButtonBar className="grow">
          <Button onClick={onUpdate} disabled={isUpdating}>
            Update
          </Button>
          <Button
            type="tertiary"
            onClick={() => {
              setColours(orderColours(allColours));
              setIsEditing(false);
            }}
            disabled={isUpdating}
          >
            Cancel
          </Button>
        </ButtonBar>
        {filterField}
      </div>
      {filterCount}
      {filteredColours.map((colour) => {
        const c = colour.value.colour;
        return (
          <div key={colour.id} className="container-flex gap-s m-s ai-end">
            <div className="container-flex gap-xs wrap grow">
              <TextField
                className="grow"
                label="Name"
                name="colour.name"
                maxLength={40}
                value={colour.value.name}
                onChange={(value) => {
                  setColours((colours) =>
                    colours.map((v) => {
                      if (v.id !== colour.id) return v;
                      return {
                        ...v,
                        value: { ...v.value, name: value },
                        updated: true,
                      };
                    })
                  );
                }}
              />
              <ColourPicker
                className="flex col-25"
                label="Colour"
                name="colour.colour"
                value={c}
                onChange={(value) => {
                  setColours((colours) =>
                    colours.map((v) => {
                      if (v.id !== colour.id) return v;
                      return {
                        ...v,
                        value: { ...v.value, colour: value },
                        updated: true,
                      };
                    })
                  );
                }}
              />
            </div>
            <div
              className={isColourUsed(colour.id, data) ? "fe-se-hidden" : ""}
            >
              <Button
                type="icon"
                icon="delete"
                title="Remove colour"
                aria-label="Remove colour"
                onClick={() =>
                  setColours((colours) =>
                    colours.filter((v) => v.id !== colour.id)
                  )
                }
              />
            </div>
          </div>
        );
      })}
      <ButtonBar>
        <Button
          type="tertiary"
          icon="add_circle"
          onClick={() =>
            setColours((colours) => {
              let newID = generateID(2);
              while (colours.some((v) => v.id === newID)) {
                newID = generateID(2);
              }
              return [
                ...colours,
                {
                  id: newID,
                  value: { name: "New Colour", colour: "#000000" },
                  whiteContrast: 0,
                  new: true,
                },
              ];
            })
          }
          disabled={isUpdating || colours.length >= MAX_COLOURS}
        >
          Add Colour
        </Button>
      </ButtonBar>
      {colours.length >= MAX_COLOURS && (
        <Banner type="info" className="mt-s">
          <p>Only {MAX_COLOURS} colours can be added.</p>
        </Banner>
      )}
    </>
  );
}

export default FEStyleColourEditor;
