import { useContext, useState } from "react";
import SpaceContext from "src/context/space-context/SpaceContext";
import SpaceEnvContext from "src/context/space-env-context/SpaceEnvContext";
import EnvTag from "../env-tag/EnvTag";

import "./EnvMiniSelect.css";
import ContextMenuButton from "../context-menu-button/ContextMenuButton";
import { ContextMenu, ContextMenuOption, PageContext } from "@formatlas/react";
import { formatEnvironmentID } from "src/utils/format";
import { MFAApp, MFAAppID, appsByID } from "src/utils/apps";
import { useNavigate } from "react-router-dom";

export interface EnvMiniSelectProps {}

export function EnvMiniSelect(props: EnvMiniSelectProps) {
  const { space, envs } = useContext(SpaceContext);
  const { env } = useContext(SpaceEnvContext);
  const { ancestors, current } = useContext(PageContext);

  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);

  const allPages = [...ancestors, current];
  let app: MFAApp | null = null;
  for (let i = 0, n = allPages.length; i < n; i++) {
    const page = allPages[n - 1 - i];
    if (typeof page?.data?.app === "string") {
      app = appsByID[page.data.app as MFAAppID];
      break;
    }
  }

  if (!app || !env || !envs || envs.length < 2) {
    return <></>;
  }

  const otherEnvs = envs.filter((v) => v.isAvailable && v.id !== env.id);
  if (!otherEnvs.length) {
    return <></>;
  }

  const options: ContextMenuOption[][] = [
    [
      {
        id: env.id,
        name: formatEnvironmentID(env.env),
        active: true,
      },
      ...otherEnvs.map((v) => {
        return {
          id: v.id,
          name: formatEnvironmentID(v.env),
          onClick: () => {
            navigate(`/s/${space.id}/${app?.uri}/env/${v.id}`);
          },
        };
      }),
    ],
  ];
  return (
    <div className="env-mini-select container-flex jc-end grow relative m-bottom-m">
      <ContextMenuButton
        title={`Change the current environment from ${formatEnvironmentID(
          env.env
        )}`}
        isMenuVisible={showMenu}
        onClick={() => setShowMenu((v) => !v)}
      >
        <EnvTag env={env.env} />
      </ContextMenuButton>
      {showMenu && (
        <ContextMenu
          options={options}
          onBlur={() => setShowMenu(false)}
          align="right"
        />
      )}
    </div>
  );
}

export default EnvMiniSelect;
