import { useContext } from "react";
import SpaceContext from "src/context/space-context/SpaceContext";
import EnvCard from "../env-card/EnvCard";

export interface EnvSelectProps {}

export function EnvSelect(props: EnvSelectProps): JSX.Element {
  const { space, envs } = useContext(SpaceContext);
  return (
    <>
      {envs && (
        <>
          <div className="text-centre">
            <p className="m-m bold">Select Environment</p>
          </div>
          {envs.length > 0 && (
            <nav
              aria-label={`Environment links for the "${space.displayName}" space`}
            >
              {envs.map((env) => (
                <EnvCard key={env.id} env={env} />
              ))}
            </nav>
          )}
        </>
      )}
    </>
  );
}

export default EnvSelect;
