import { Functions, getFunctions, httpsCallable } from "firebase/functions";
import { convertTimestamps } from "./firebase-helpers";
import {
  APIRequestCreateContent,
  APIRequestCreateContentVersion,
  APIRequestCreateSpace,
  APIRequestDeleteContent,
  APIRequestUpdateSpaceSettings,
  APIRequestUpdateStyleContent,
  APIResponseCreateContent,
  APIResponseCreateContentVersion,
  APIResponseCreateSpace,
  APIResponseDeleteContent,
  APIResponseUpdateSpaceSettings,
  APIResponseUpdateStyleContent,
} from "@formatlas/types";

/**
 * The API manager class manages all connections to the Cloud Functions APIs.
 */
export class APIManager {
  /** The Cloud Functions instance. */
  public functions: Functions;

  /**
   * @param functions the Cloud Functions instance.
   */
  constructor(functions: Functions = getFunctions()) {
    this.functions = functions;
  }

  /**
   * Sends an API call to a callable Cloud Function and returns the result.
   * @param api the callable Cloud Function API name.
   * @param request the request data.
   * @returns a promise that resolves to the response.
   */
  protected async call<T, U>(api: string, request?: T): Promise<U> {
    const apiFn = httpsCallable(this.functions, api);
    const response = (await apiFn(request)).data as U;
    convertTimestamps(response);
    return response;
  }

  /**
   * Sends a request to create a new My Form Atlas space.
   * @param request the request data.
   * @returns a promise resolving to the response.
   */
  public async createSpace(
    request: APIRequestCreateSpace
  ): Promise<APIResponseCreateSpace> {
    return await this.call<APIRequestCreateSpace, APIResponseCreateSpace>(
      "MFA_createSpace",
      request
    );
  }

  /**
   * Sends a request to update space settings.
   * @param request the request data.
   * @returns a promise resolving to the response.
   */
  public async updateSpaceSettings(
    request: APIRequestUpdateSpaceSettings
  ): Promise<APIResponseUpdateSpaceSettings> {
    return await this.call<
      APIRequestUpdateSpaceSettings,
      APIResponseUpdateSpaceSettings
    >("MFA_updateSpaceSettings", request);
  }

  /**
   * Sends a request to create space content.
   * @param request the request data.
   * @returns a promise resolving to the response.
   */
  public async createContent(
    request: APIRequestCreateContent
  ): Promise<APIResponseCreateContent> {
    return await this.call<APIRequestCreateContent, APIResponseCreateContent>(
      "MFA_createContent",
      request
    );
  }

  /**
   * Sends a request to create a space content version.
   * @param request the request data.
   * @returns a promise resolving to the response.
   */
  public async createContentVersion(
    request: APIRequestCreateContentVersion
  ): Promise<APIResponseCreateContentVersion> {
    return await this.call<
      APIRequestCreateContentVersion,
      APIResponseCreateContentVersion
    >("MFA_createContentVersion", request);
  }

  /**
   * Sends a request to delete space content.
   * @param request the request data.
   * @returns a promise resolving to the response.
   */
  public async deleteContent(
    request: APIRequestDeleteContent
  ): Promise<APIResponseDeleteContent> {
    return await this.call<APIRequestDeleteContent, APIResponseDeleteContent>(
      "MFA_deleteContent",
      request
    );
  }

  /**
   * Sends a request to update style content.
   * @param request the request data.
   * @returns a promise resolving to the response.
   */
  public async updateStyleContent(
    request: APIRequestUpdateStyleContent
  ): Promise<APIResponseUpdateStyleContent> {
    return await this.call<
      APIRequestUpdateStyleContent,
      APIResponseUpdateStyleContent
    >("MFA_updateStyleContent", request);
  }
}

export default APIManager;
