import { toast, PageContainer, Page } from "@formatlas/react";
import { Space } from "@formatlas/types";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import DashboardApp from "src/apps/dashboard-app/DashboardApp";
import FormEditorApp from "src/apps/form-editor-app/FormEditorApp";
import FormManagerApp from "src/apps/form-manager-app/FormManagerApp";
import SpaceSettingsApp from "src/apps/space-settings-app/SpaceSettingsApp";
import SpaceContext, {
  SpaceContextData,
} from "src/context/space-context/SpaceContext";
import Error404Page from "src/pages/errors/error-404-page/Error404Page";
import SpacePage from "src/pages/space-page/SpacePage";
import { spaceManager } from "src/utils/SpaceManager";
import { MFAApp, MFAAppID, appsByID } from "src/utils/apps";

export interface SpaceViewProps {
  space: Space;
}

export function SpaceView(props: SpaceViewProps): JSX.Element {
  const space = props.space;
  const spaceID = space.id;
  const [spaceContext, setSpaceContext] = useState<SpaceContextData>({ space });
  spaceManager.setCurrentSpace(spaceID);

  useEffect(() => {
    spaceManager.setCurrentSpace(spaceID);
    setSpaceContext((s) => {
      return {
        ...s,
        space,
        envs: undefined,
        singleEnv: undefined,
        userSpace: undefined,
      };
    });
    spaceManager
      .getSpaceEnvironments()
      .then((envs) => {
        setSpaceContext((s) => {
          const availableEnvs = envs.filter((env) => env.isAvailable === true);
          return {
            ...s,
            envs,
            availableEnvs,
            singleEnv:
              availableEnvs.length === 1 ? availableEnvs[0] : undefined,
          };
        });
      })
      .catch((error) => {
        console.error("Failed to get space environments.", error);
        toast.add({
          label: "Failed to get space environments.",
          type: "error",
        });
      });
    spaceManager
      .getUserSpaceDetails()
      .then((userSpace) => {
        setSpaceContext((s) => {
          return { ...s, userSpace };
        });
      })
      .catch((error) => {
        console.error("Failed to get user space.", error);
        toast.add({
          label: "Failed to get user space.",
          type: "error",
        });
      });
    spaceManager.getSpaceSettings().catch((error) => {
      console.error("Failed to get space settings.", error);
      toast.add({
        label: "Failed to get space settings.",
        type: "error",
      });
    });
    const settingsUnsub = spaceManager.addEventListener(
      "onSpaceSettingsUpdated",
      (data) => {
        setSpaceContext((s) => {
          return { ...s, settings: data.settings };
        });
      }
    );
    // TODO add retry on fail

    return () => {
      settingsUnsub();
    };
  }, [spaceID, space]);

  const baseURL = `/s/${spaceID}/`;

  function createPage(app: MFAApp): Page {
    return {
      url: `${baseURL}${app.uri}`,
      id: `space-${app.id}`,
      name: app.name,
      icon: app.icon,
      description: app.shortDescription,
      data: {
        app: app.id,
      },
    };
  }

  function createRoute(appID: MFAAppID, element: JSX.Element) {
    const app = appsByID[appID];
    return (
      <Route
        path={`${app.uri}/*`}
        element={
          <PageContainer current={createPage(app)}>{element}</PageContainer>
        }
      />
    );
  }

  return (
    <SpaceContext.Provider value={spaceContext}>
      <Routes>
        <Route path="" element={<SpacePage />} />
        {createRoute("dashboard", <DashboardApp />)}
        {createRoute("form-editor", <FormEditorApp />)}
        {createRoute("form-manager", <FormManagerApp />)}
        {createRoute("settings", <SpaceSettingsApp />)}
        <Route path="*" element={<Error404Page />} />
      </Routes>
    </SpaceContext.Provider>
  );
}

export default SpaceView;
