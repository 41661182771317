import Button from "../button/Button";
import Icon from "../icon/Icon";

/**
 * The properties for the {@link Banner} component.
 */
export interface BannerProps {
  /** The component's DOM id. */
  id?: string;

  /** Extra classes to be added to the component. */
  className?: string;

  /** The type of banner. */
  type?: "info" | "success" | "warning" | "error";

  /** The icon to show for the banner. */
  icon?: string;

  /** If true, the banner will have a close button. */
  closeable?: boolean;

  /** A custom label for the close button. */
  closeButtonLabel?: string;

  /** A function called when the user clicks the close button. */
  onClose?: () => any;

  /** The content to display in the banner. */
  children?: any;
}

/**
 * A banner used to provide the user with important information, warnings,
 * errors, or success messages.
 */
export function Banner(props: BannerProps): JSX.Element {
  // Determine the class
  let className = "banner banner-";
  if (props.type === "error") {
    className += "neg";
  } else if (props.type === "warning") {
    className += "warn";
  } else if (props.type === "success") {
    className += "pos";
  } else {
    className += "info";
  }
  if (props.closeable) {
    className += " banner-with-close";
  }
  if (props.className) {
    className += " " + props.className;
  }

  // Determine icon
  let iconName = "announcement";
  if (typeof props.icon === "string") {
    iconName = props.icon;
  } else if (props.type === "error") {
    iconName = "error";
  } else if (props.type === "warning") {
    iconName = "warning";
  } else if (props.type === "success") {
    iconName = "check_circle";
  }

  return (
    <div id={props.id} className={className}>
      {iconName && <Icon name={iconName} className="banner-icon" />}
      {props.children}
      {props.closeable && (
        <Button
          type="icon"
          icon="close"
          className="banner-close"
          onClick={() => props.onClose && props.onClose()}
          title={props.closeButtonLabel ?? "Close"}
          aria-label={props.closeButtonLabel ?? "Close"}
        />
      )}
    </div>
  );
}

export default Banner;
