import { Tag } from "@formatlas/types";
import {
  COLOUR_BG,
  COLOUR_TEXT,
  chooseHighestContrastColour,
} from "@formatlas/react";

import "./TagValue.css";

export interface TagValueProps {
  className?: string;
  id: string;
  tag: Tag;
  onClick?: (id: string, tag: Tag) => void;
}

export function TagValue(props: TagValueProps) {
  const { id, tag } = props;

  let className = "tag-value font-info-light inline-block";
  if (props.className) {
    className += " " + props.className;
  }

  const bg = tag.colour || "#afefff";
  const style = {
    backgroundColor: bg,
    color: chooseHighestContrastColour(bg, COLOUR_TEXT, COLOUR_BG),
  };

  if (!props.onClick) {
    return (
      <span className={className} style={style}>
        {tag.name}
      </span>
    );
  }

  return (
    <button
      className={className}
      style={style}
      onClick={() => props.onClick && props.onClick(id, tag)}
    >
      {tag.name}
    </button>
  );
}

export default TagValue;
