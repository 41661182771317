import { MappedObject } from "@formatlas/types";

/**
 * Loops through each value in a mapped object and calls a callback.
 * @param {MappedObject<T> | undefined | null} object the mapped object.
 * @param {function} callback the callback for each mapped value.
 */
export function eachMappedValue<T>(
  object: MappedObject<T> | undefined | null,
  callback: (value: T, id: string, index: number, total: number) => void
) {
  if (!object) return;
  const keys = Object.keys(object);
  const n = keys.length;
  keys.forEach((id, index) => {
    callback(object[id], id, index, n);
  });
}
