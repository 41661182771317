import { useContext } from "react";
import SpaceContext from "src/context/space-context/SpaceContext";

/**
 * Creates a function to check if the user has all the specified space user
 * permission IDs.
 * @return a function that takes any number of permission IDs and checks if
 * the user has all the specified permissions.
 */
export function useCanPerform() {
  const { userSpace } = useContext(SpaceContext);

  // Map permissions to an object
  const userSpacePermissionsByID: { [id: string]: boolean } = {};
  if (userSpace && Array.isArray(userSpace.permissions)) {
    for (let i = 0, n = userSpace.permissions.length; i < n; i++) {
      const id = userSpace.permissions[i];
      if (!id || typeof id !== "string") continue;
      userSpacePermissionsByID[id] = true;
    }
  }

  // Create a function that dynamically can determine permissions
  return function (...permissionIDs: string[]): boolean {
    if (!userSpace || !Array.isArray(userSpace.permissions)) {
      return false;
    }
    return permissionIDs.every((id) => userSpacePermissionsByID[id]);
  };
}

export default useCanPerform;
