import { toast } from "@formatlas/react";
import { ContentType, ContentVersion } from "@formatlas/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import ContentContext from "src/context/content-context/ContentContext";
import useCanPerform from "src/hooks/use-can-perform/useCanPerform";
import useSpaceContent from "src/hooks/use-space-content/useSpaceContent";
import ContentNotFoundPage from "src/pages/errors/content-not-found-page/ContentNotFoundPage";
import LoadingPage from "src/pages/loading-page/LoadingPage";
import SpaceContentPage from "src/pages/space-content-page/SpaceContentPage";
import ContentManager from "src/utils/ContentManager";
import { spaceManager } from "src/utils/SpaceManager";
import { getContentDevPermission } from "src/utils/content";
import FEVersionViewer from "../fe-version-viewer/FEVersionViewer";
import { formatContentType } from "src/utils/format";

export interface FEContentViewerProps {
  type: ContentType;
}

export function FEContentViewer(props: FEContentViewerProps) {
  const { contentID } = useParams();
  const { type } = props;

  const canPerform = useCanPerform();
  const allContent = useSpaceContent(type);
  const content = allContent.find((c) => c.id === contentID);
  const cm = useMemo(
    () => spaceManager.getContentManager() ?? new ContentManager(""),
    []
  );
  const isLoaded = cm.isContentLoaded(type);

  const [versions, setVersions] = useState<ContentVersion[]>([]);
  const [versionsLastLoaded, setVersionsLastLoaded] = useState<Date | null>(
    null
  );
  const [versionLoadError, setVersionLoadError] = useState<string | null>(null);

  const refreshVersions = useCallback(async () => {
    if (!content || !content.id) {
      return;
    }

    try {
      const versions = await cm.getContentVersions(type, content.id);
      setVersions(versions);
      setVersionsLastLoaded(new Date());
      setVersionLoadError(null);
    } catch (e) {
      const msg = "Failed to get versions";
      console.error(msg + ".", e);
      toast.add({ label: msg + ".", type: "error" });
      setVersionLoadError(msg + ": " + (e || "unknown error."));
    }
  }, [type, content, cm]);

  useEffect(() => {
    if (
      versionsLastLoaded &&
      Date.now() - versionsLastLoaded.getTime() < 5 * 60 * 1000
    ) {
      return;
    }
    refreshVersions();
  }, [refreshVersions, versionsLastLoaded]);

  return (
    <>
      {isLoaded && !content && (
        <ContentNotFoundPage type={type} returnTo="./.." />
      )}
      {!isLoaded && !content && <LoadingPage />}
      {content && (
        <ContentContext.Provider
          value={{
            type,
            typeLabel: formatContentType(type, false, false),
            typeLabelTitle: formatContentType(type, false, true),
            canUpdate: canPerform(getContentDevPermission(type)),
            content,
            versions,
            versionsLastLoaded,
            versionLoadError,
            cm,
            refreshVersions,
            setVersions: (versions) =>
              setVersions(ContentManager.sortContentVersions(versions)),
          }}
        >
          <Routes>
            <Route path="" element={<SpaceContentPage app="form-editor" />} />
            <Route path="v/:versionID" element={<FEVersionViewer />} />
          </Routes>
        </ContentContext.Provider>
      )}
    </>
  );
}

export default FEContentViewer;
