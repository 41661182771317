import { SpaceEnv } from "@formatlas/types";
import { Link } from "react-router-dom";

import "./EnvCard.css";
import EnvTag from "../env-tag/EnvTag";
import { formatEnvironmentID } from "src/utils/format";

export interface EnvCardProps {
  env: SpaceEnv;
}

export function EnvCard(props: EnvCardProps): JSX.Element {
  const env = props.env;
  let className =
    "env-card card bold container-flex jc-start ai-center flex col-40 s-col-100 m-col-70 shadow-hover";
  if (!env.isAvailable) {
    className += " disabled";
  }

  const content = (
    <>
      <EnvTag className="flex p-r-s" env={env.env} />
      <span className="env-card-content flex p-l-s">
        <span className="env-card-title">{formatEnvironmentID(env.env)}</span>
      </span>
    </>
  );

  return (
    <div className="container-flex jc-center m-s">
      {env.isAvailable ? (
        <Link to={`env/${env.id}`} className={className}>
          {content}
        </Link>
      ) : (
        <div className={className}>{content}</div>
      )}
    </div>
  );
}

export default EnvCard;
