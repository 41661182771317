import environment from "environment";

export interface DocsLinkProps {
  id?: string;
  className?: string;
  to: string;
  children?: any;
}

/**
 * A link to the Form Atlas documentation site.
 */
export function DocsLink(props: DocsLinkProps): JSX.Element {
  return (
    <a
      id={props.id}
      className={props.className}
      href={environment.docsURL + props.to}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
}
