import { AppHeader } from "@formatlas/react";
import SmartNavBar from "src/shared/smart-nav-bar/SmartNavBar";

/**
 * The properties for the {@link ErrorPage} component.
 */
export interface ErrorPageProps {
  showNavLinks?: boolean;
  title?: string;
  children?: any;
}

/**
 * A simple error page.
 */
export default function ErrorPage(props: ErrorPageProps): JSX.Element {
  return (
    <>
      <AppHeader title={props.title ?? "My Form Atlas"} />
      {props.showNavLinks && <SmartNavBar />}
      <main className="container-max-width">
        {props.children || "An unknown error occurred."}
      </main>
    </>
  );
}
