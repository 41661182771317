import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonBar, Icon, Table, toast } from "@formatlas/react";
import { ContentType } from "@formatlas/types";

import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";
import SmartNavBar from "src/shared/smart-nav-bar/SmartNavBar";
import SpaceSelect from "src/shared/space-select/SpaceSelect";
import FEBreadcrumbs from "../fe-breadcrumbs/FEBreadcrumbs";
import useCanPerform from "src/hooks/use-can-perform/useCanPerform";
import { spaceManager } from "src/utils/SpaceManager";
import useSpaceContent from "src/hooks/use-space-content/useSpaceContent";
import { navByContent } from "../fe-utils/fe-nav-utils";
import { formatContentType } from "src/utils/format";
import FEContentMoreMenu from "./components/FEContentMoreMenu";
import { getContentDevPermission } from "src/utils/content";
import SpaceContext from "src/context/space-context/SpaceContext";
import TagValue from "src/shared/tag-value/TagValue";

export interface FEContentBrowserProps {
  type: ContentType;
  children?: any;
}

export function FEContentBrowser(props: FEContentBrowserProps) {
  const { settings = { tags: {} } } = useContext(SpaceContext);
  const tagsByID = settings.tags;
  const allTags = Object.keys(tagsByID)
    .map((id) => ({ id, tag: tagsByID[id] }))
    .sort((a, b) =>
      ((a.tag && a.tag.name) || "").toLowerCase() <
      ((b.tag && b.tag.name) || "").toLowerCase()
        ? -1
        : 1
    )
    .map(({ id }) => id);

  const { type } = props;

  const navigate = useNavigate();
  const canPerform = useCanPerform();
  const content = useSpaceContent(type);

  const [isCreating, setIsCreating] = useState(false);

  const typeLabel = formatContentType(type, false, false);
  const typeLabelTitle = formatContentType(type, false, true);

  const contentIcon = navByContent[type].icon;

  return (
    <>
      <ProfileAppHeader title="Form Editor" />
      <SmartNavBar />
      <main className="container-max-width">
        <SpaceSelect />
        <FEBreadcrumbs parts={[navByContent[type]]} />
        {canPerform(getContentDevPermission(type)) && (
          <ButtonBar className="m-bottom-l">
            <Button
              title={`Create a new ${typeLabel}`}
              icon="add_circle_outline"
              disabled={isCreating}
              onClick={() => {
                setIsCreating(true);
                spaceManager
                  .getContentManager()
                  ?.createContent(type)
                  .then((res) => {
                    if (res.failed) {
                      const msg = `Failed to create ${typeLabel}.`;
                      console.error(msg, res);
                      toast.add({ label: msg, type: "error" });
                    } else {
                      toast.add({
                        label: `Successfully created new ${typeLabel}.`,
                        type: "success",
                      });
                      navigate(`./${res.data?.contentID}`);
                    }
                  })
                  .catch((err) => {
                    const msg = `Failed to create ${typeLabel}.`;
                    console.error(msg, err);
                    toast.add({ label: msg, type: "error" });
                  })
                  .finally(() => {
                    setIsCreating(false);
                  });
              }}
            >
              Create {typeLabelTitle}
            </Button>
          </ButtonBar>
        )}
        {props.children}
        <Table
          caption={`${typeLabelTitle}s`}
          className="m-top-m m-bottom-l"
          cols={[
            {
              id: "name",
              title: "Name",
              useHeader: true,
              format: (c) => {
                return (
                  <span className="container-flex ai-center gap-s wrap">
                    <Link
                      to={`./${c.id}`}
                      className={contentIcon ? "icon-start" : ""}
                    >
                      {contentIcon && <Icon name={contentIcon} />}
                      {c.name}
                    </Link>
                    {c.tags && c.tags.length > 0 && (
                      <span className="container-flex ai-center gap-xs wrap">
                        {allTags
                          .filter((id) => c.tags?.includes(id))
                          .map((id) => (
                            <TagValue key={id} id={id} tag={tagsByID[id]} />
                          ))}
                      </span>
                    )}
                  </span>
                );
              },
            },
            {
              id: "menu",
              title: "",
              format: (c) => <FEContentMoreMenu type={type} content={c} />,
              width: 50,
              style: { position: "relative" },
            },
          ]}
          data={content.map((value) => ({ value, id: value.id }))}
          emptyTable={
            <p className="m-top-m m-bottom-l">
              No {formatContentType(type, true, false)} have been created yet.
            </p>
          }
        />
      </main>
    </>
  );
}

export default FEContentBrowser;
