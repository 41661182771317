import { COMPANY_NAME } from "../../../constants";

/**
 * The properties for the {@link AppFooter} component.
 */
export interface AppFooterProps {
  /** If true, the footer content will take the full page width. */
  fullWidth?: boolean;

  /** Footer content. */
  children?: any;
}

/**
 * A footer component that can be placed at the bottom of the page.
 */
export function AppFooter(props: AppFooterProps): JSX.Element {
  const content = (
    <>
      {props.children}
      <p
        className={"font-info text-centre" + (props.children ? " m-top-s" : "")}
      >
        &copy; {new Date().getFullYear()} {COMPANY_NAME}
      </p>
    </>
  );
  return (
    <footer className="fa-footer">
      {props.fullWidth ? (
        content
      ) : (
        <div className="container-max-width">{content}</div>
      )}
    </footer>
  );
}

export default AppFooter;
