import { Link } from "react-router-dom";
import ErrorPage from "../error-page/ErrorPage";

export interface Error404PageProps {}

export function Error404Page(props: Error404PageProps): JSX.Element {
  return (
    <ErrorPage title="Page Not Found" showNavLinks>
      <p className="m-m">The page you are looking for does not exist.</p>
      <p className="m-m">
        <Link to="/">Return to My Form Atlas home.</Link>
      </p>
    </ErrorPage>
  );
}

export default Error404Page;
