import { Button, ButtonBar, Tabs, toast } from "@formatlas/react";
import { ColourTheme, StyleDefDocData } from "@formatlas/types";
import React, { useState } from "react";

import { DocumentData, DocumentReference, updateDoc } from "firebase/firestore";

import useCanPerform from "src/hooks/use-can-perform/useCanPerform";
import { getContentDevPermission } from "src/utils/content";
import FEStyleSpacingEditor from "./components/FEStyleSpacingEditor";
import FEStyleColourEditor from "./components/FEStyleColourEditor";
import FEStyleThemeEditor from "./components/FEStyleThemeEditor";
import FEStyleBreakpointEditor from "./components/FEStyleBreakpointEditor";
import FEStyleBorderEditor from "./components/FEStyleBorderEditor";
import FEStyleFontEditor from "./components/FEStyleFontEditor";

import "./FEStyleEditor.css";

export interface FEStyleEditorProps {
  readonly?: boolean;
  data: StyleDefDocData;
  docRef: DocumentReference<DocumentData, DocumentData>;
}

export function FEStyleEditor(props: FEStyleEditorProps) {
  const { data, docRef } = props;
  const canPerform = useCanPerform();
  const readonly =
    !!props.readonly || !canPerform(getContentDevPermission("style"));
  const childProps: FEStyleEditorProps = { data, readonly, docRef };

  const [themeUpdated, setThemeUpdated] = useState(0);

  return (
    <div>
      <Tabs
        className="mb-l"
        tabs={[
          {
            id: "colours",
            title: "Colours",
            content: <FEStyleColourEditor {...childProps} />,
          },
          {
            id: "fonts",
            title: "Fonts",
            content: <FEStyleFontEditor {...childProps} />,
          },
          {
            id: "theme",
            title: "Theme",
            content: (
              <React.Fragment key={themeUpdated}>
                <FEStyleThemeEditor
                  {...{ ...childProps, mode: "light" }}
                  onChange={() => setThemeUpdated(Date.now())}
                />
                <FEStyleThemeEditor
                  {...{ ...childProps, mode: "dark" }}
                  onChange={() => setThemeUpdated(Date.now())}
                  onDelete={() => {
                    data.darkColours = undefined;
                    delete data.darkColours;
                    setThemeUpdated(Date.now());
                  }}
                />
                {!data.darkColours && !readonly && (
                  <ButtonBar>
                    <Button
                      type="tertiary"
                      icon="add_circle"
                      onClick={() => {
                        const darkColours: ColourTheme = {
                          bg: "c",
                          c: "bg",
                          p: "p",
                          s: "s",
                          t: "t",
                          url: "url",
                          dbg: "dbg",
                          df: "df",
                          inbg: "inbg",
                          inf: "inf",
                          sbg: "sbg",
                          sf: "sf",
                          wbg: "wbg",
                          wf: "wf",
                          ebg: "ebg",
                          ef: "ef",
                        };
                        const keys = Object.keys(
                          darkColours
                        ) as (keyof ColourTheme)[];
                        keys.forEach((key) => {
                          if (!data.allColours[key]) {
                            darkColours[key] = Object.keys(data.allColours)[0];
                          }
                        });
                        updateDoc(docRef, { darkColours })
                          .then(() => {
                            data.darkColours = darkColours;
                            setThemeUpdated(Date.now());
                          })
                          .catch((err) => {
                            console.error("Failed to add dark theme.", err);
                            toast.add({
                              label: "Failed to add dark theme.",
                              type: "error",
                            });
                          });
                      }}
                    >
                      Add Dark Theme
                    </Button>
                  </ButtonBar>
                )}
              </React.Fragment>
            ),
          },
          {
            id: "spacing",
            title: "Spacing",
            content: <FEStyleSpacingEditor {...childProps} />,
          },
          {
            id: "breakpoints",
            title: "Screens",
            content: <FEStyleBreakpointEditor {...childProps} />,
          },
          {
            id: "borders",
            title: "Borders",
            content: <FEStyleBorderEditor {...childProps} />,
          },
        ]}
      />
    </div>
  );
}

export default FEStyleEditor;
