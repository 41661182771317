import React from "react";
import { Route, Routes } from "react-router-dom";
import FEAppHome from "./fe-app-home/FEAppHome";
import FEContentBrowser from "./fe-content-browser/FEContentBrowser";
import FEContentViewer from "./fe-content-viewer/FEContentViewer";
import { navByContent } from "./fe-utils/fe-nav-utils";
import { ALL_CONTENT_TYPES } from "src/utils/ContentManager";

export interface FormEditorAppProps {}

export function FormEditorApp(props: FormEditorAppProps): JSX.Element {
  return (
    <Routes>
      <Route path="" element={<FEAppHome />} />
      {ALL_CONTENT_TYPES.map((type) => {
        const nav = navByContent[type];
        return (
          <React.Fragment key={type}>
            <Route path={nav.uri} element={<FEContentBrowser type={type} />} />
            <Route
              path={`${nav.uri}/:contentID/*`}
              element={<FEContentViewer type={type} />}
            />
          </React.Fragment>
        );
      })}
    </Routes>
  );
}

export default FormEditorApp;
