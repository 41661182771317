import { Link } from "react-router-dom";
import Icon from "../icon/Icon";

/**
 * The properties for the {@link NavBar} component.
 */
export interface NavBarProps {
  /** The nav bar items that appear on the left side. */
  leftItems?: NavBarItem[];
  /** The nav bar items that appear on the right side. */
  rightItems?: NavBarItem[];
  /** If true, the nav bar content will take the full page width. */
  fullWidth?: boolean;
}

/**
 * Represents a {@link NavBar} item that can appear in the navigation bar.
 */
export interface NavBarItem {
  /** An icon to display before the title or by itself. */
  icon?: string;
  /** The item title to display. */
  title?: string;
  /** The tooltip to display when hovering over the item. */
  tooltip?: string;
  /** The URL to navigate to on click. */
  url: string;
}

/**
 * A simple navigation bar that can be used directly under the page header.
 * The bar can display items (with links) on the left and right side.
 */
export function NavBar(props: NavBarProps) {
  function createItem(item: NavBarItem) {
    return (
      <Link
        key={item.url}
        to={item.url}
        className="item container-flex ai-center gap-xxs"
        title={item.tooltip}
        aria-label={item.title || item.tooltip}
      >
        {item.icon && <Icon name={item.icon} />}
        {item.title && <span className="text">{item.title}</span>}
      </Link>
    );
  }

  let containerClass = "container-flex jc-between ai-center wrap gap-s";
  if (!props.fullWidth) {
    containerClass += " container-max-width";
  }
  const itemContainerClass = "container-flex ai-center wrap gap-s";

  return (
    <div className="nav-bar">
      <div className={containerClass}>
        <div className={itemContainerClass}>
          {props.leftItems?.map(createItem)}
        </div>
        <div className={itemContainerClass}>
          {props.rightItems?.map(createItem)}
        </div>
      </div>
    </div>
  );
}

export default NavBar;
