import { useContext } from "react";
import SpaceContext from "src/context/space-context/SpaceContext";
import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";
import SmartNavBar from "src/shared/smart-nav-bar/SmartNavBar";
import SpaceSelect from "src/shared/space-select/SpaceSelect";

export interface DashboardAppProps {}

export function DashboardApp(props: DashboardAppProps): JSX.Element {
  const spaceContext = useContext(SpaceContext);
  const space = spaceContext.space;
  return (
    <>
      <ProfileAppHeader title="Dashboard" />
      <SmartNavBar />
      <main className="container-max-width">
        <SpaceSelect />
        <p>Dashboard TODO</p>
      </main>
    </>
  );
}

export default DashboardApp;
