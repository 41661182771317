import { useContext, useState } from "react";
import { Accordion, Banner, Button, ButtonBar } from "@formatlas/react";
import SpaceContext from "src/context/space-context/SpaceContext";
import useCanPerform from "src/hooks/use-can-perform/useCanPerform";
import TagEditor from "./components/TagEditor";

export interface GeneralSettingsProps {}

export function GeneralSettings(props: GeneralSettingsProps) {
  const { settings = { tags: {} } } = useContext(SpaceContext);

  const canPerform = useCanPerform();
  const canUpdate = canPerform("space-settings-manage");

  const [editingTag, setEditingTag] = useState<string>();
  const [isAddingTag, setIsAddingTag] = useState(false);

  const tags = Object.keys(settings.tags)
    .map((id) => {
      return { id, tag: settings.tags[id as keyof typeof settings.tags] };
    })
    .sort((a, b) =>
      a.tag.name.toLowerCase() < b.tag.name.toLowerCase() ? -1 : 1
    );
  function onTagEdit(id: string, isEditing: boolean) {
    setEditingTag(isEditing ? id : undefined);
    if (!id && !isEditing) {
      setIsAddingTag(false);
    }
  }

  return (
    <>
      <section>
        <h2 className="m-s">Categorization</h2>
        <Accordion title="Tags">
          <Banner type="info" className="m-bottom-s">
            <p>
              Tags are used to categorize space forms, components, styles, and
              resources, and up to 100 can be created per space.
              {canUpdate && (
                <>
                  {" "}
                  Click on an existing tag to edit or click the "Create Tag"
                  button below to add a tag.
                </>
              )}
            </p>
          </Banner>
          <div className="container-flex wrap">
            {tags.map((v) => (
              <TagEditor
                key={v.id}
                {...v}
                isEditing={editingTag === v.id}
                onEditStateChange={onTagEdit}
                locked={!canUpdate}
              />
            ))}
            {isAddingTag && (
              <TagEditor
                id=""
                tag={{ name: "New Tag", colour: "#bddfff" }}
                isEditing
                onEditStateChange={onTagEdit}
                locked={!canUpdate}
              />
            )}
          </div>
          {canUpdate && !isAddingTag && (
            <ButtonBar className="m-xs">
              <Button
                type="tertiary"
                icon="add_circle_outline"
                onClick={() => {
                  setIsAddingTag(true);
                  setEditingTag("");
                }}
              >
                Create Tag
              </Button>
            </ButtonBar>
          )}
        </Accordion>
      </section>
    </>
  );
}

export default GeneralSettings;
