import { useContext, useState } from "react";
import {
  Button,
  ButtonBar,
  CreatedUpdated,
  MODAL_AUTOFOCUS_CLASS,
  Modal,
  Table,
  TextArea,
  TextField,
  formatDateTime,
  toast,
} from "@formatlas/react";
import { deleteField } from "firebase/firestore";

import FEBreadcrumbs from "src/apps/form-editor-app/fe-breadcrumbs/FEBreadcrumbs";
import { navByContent as feNavByContent } from "src/apps/form-editor-app/fe-utils/fe-nav-utils";
import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";
import SmartNavBar from "src/shared/smart-nav-bar/SmartNavBar";
import SpaceSelect from "src/shared/space-select/SpaceSelect";
import { MFAAppID } from "src/utils/apps";
import { ContentUpdates } from "src/utils/ContentManager";
import SpaceContext from "src/context/space-context/SpaceContext";
import TagValue from "src/shared/tag-value/TagValue";
import ContentTagMultiselect from "src/shared/content-tag-multiselect/ContentTagMultiselect";
import { Link, useNavigate } from "react-router-dom";
import ContentContext from "src/context/content-context/ContentContext";
import ContentVersionMoreMenu from "./components/ContentVersionMoreMenu";

export interface SpaceContentPageProps {
  app: MFAAppID;
}

export function SpaceContentPage(props: SpaceContentPageProps) {
  const { settings = { tags: {} } } = useContext(SpaceContext);
  const {
    type,
    typeLabel,
    typeLabelTitle,
    content,
    cm,
    versions,
    setVersions,
    canUpdate,
  } = useContext(ContentContext);
  const tagsByID = settings.tags;

  const navigate = useNavigate();

  const { app: appName } = props;
  const title = `View ${typeLabelTitle}`;

  const [name, setName] = useState(content.name);
  const [description, setDescription] = useState(content.description ?? "");

  const [isEditingTags, setIsEditingTags] = useState(false);
  const [isEditingTagsDisabled, setIsEditingTagsDisabled] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [isCreatingVersion, setIsCreatingVersion] = useState(false);

  function updateContentWith(updates: ContentUpdates) {
    // Update the document
    return cm
      .updateContent(type, content.id, updates)
      .catch((err) => {
        const msg = `Failed to update ${typeLabel} content.`;
        console.error(msg, err);
        toast.add({ label: msg, type: "error" });
      })
      .finally(() => setIsUpdating(false));
  }

  /**
   * Updates the content document with a new name or description.
   */
  function update() {
    if (!canUpdate) return;
    setIsUpdating(true);

    // Determine values to update
    const updates: ContentUpdates = {};
    if (name !== content.name) {
      updates.name = name;
    }
    if (
      description !== content.description &&
      (description || content.description)
    ) {
      updates.description = description || deleteField();
    }

    updateContentWith(updates);
  }

  return (
    <>
      <ProfileAppHeader title={title} />
      <SmartNavBar />
      <main className="container-max-width">
        <SpaceSelect />
        {appName === "form-editor" && (
          <FEBreadcrumbs
            parts={[
              { ...feNavByContent[type], uri: feNavByContent[type].uri },
              {
                uri: content.id,
                title,
              },
            ]}
          />
        )}
        <h2>{content.name}</h2>
        <CreatedUpdated data={content} />
        <div className="mb-xs container-flex gap-xs wrap ai-center">
          {isEditingTags ? (
            <ContentTagMultiselect
              className="mt-s"
              tags={content.tags || []}
              onUpdate={(newTags) => {
                setIsEditingTagsDisabled(true);
                setIsUpdating(true);
                updateContentWith({
                  tags: newTags.length ? newTags : deleteField(),
                }).finally(() => {
                  setIsEditingTags(false);
                  setIsEditingTagsDisabled(false);
                });
              }}
              onCancel={() => {
                setIsEditingTags(false);
                setIsEditingTagsDisabled(false);
              }}
              disabled={isEditingTagsDisabled}
            />
          ) : (
            <>
              {(content.tags || [])
                .filter((id, i, arr) => !!tagsByID[id] && arr.indexOf(id) === i)
                .map((id) => (
                  <TagValue key={id} id={id} tag={tagsByID[id]} />
                ))}
              {canUpdate && Object.keys(tagsByID).length > 0 && (
                <Button
                  className="mt-xxs"
                  type="tertiary"
                  icon="edit"
                  title={`Edit ${typeLabel} tags`}
                  onClick={() => setIsEditingTags(true)}
                >
                  Edit Tags
                </Button>
              )}
            </>
          )}
        </div>
        <div className="container-flex">
          <TextField
            className="flex col-100 m-xs"
            label="Name"
            value={name}
            onChange={(v) => setName(v)}
            maxLength={100}
            disabled={!canUpdate}
          />
        </div>
        <div className="container-flex">
          <TextArea
            className="flex col-100 m-xs"
            label="Description"
            value={description}
            onChange={(v) => setDescription(v)}
            maxLength={500}
            showCharLimit
            disabled={!canUpdate}
          />
        </div>
        {canUpdate && (
          <ButtonBar className="mt-xs">
            <Button
              title={`Update ${typeLabel} information`}
              onClick={update}
              disabled={isUpdating}
            >
              Update
            </Button>
            <Button
              type="danger"
              onClick={() => {
                setShowConfirmDelete(true);
              }}
              title={`Delete ${typeLabel}`}
              disabled={isDeleting}
            >
              Delete
            </Button>
          </ButtonBar>
        )}
        <h3 className="mt-m mb-s">Versions</h3>
        {canUpdate && (
          <ButtonBar className="mb-m">
            <Button
              type="primary"
              icon="add_circle_outline"
              title={`Create a new draft version of the ${typeLabel}`}
              disabled={isCreatingVersion}
              onClick={() => {
                setIsCreatingVersion(true);
                cm.createContentVersion({ type, contentID: content.id })
                  .then((res) => {
                    console.log(res);
                    const data = res.data;
                    if (res.failed || !data) {
                      const msg = `Failed to create ${typeLabel} version.`;
                      console.error(msg, res);
                      toast.add({ label: msg, type: "error" });
                    } else {
                      setVersions([...versions, data.version]);
                      toast.add({
                        label: `Successfully created new ${typeLabel} version.`,
                        type: "success",
                      });
                    }
                  })
                  .catch((err) => {
                    const msg = `Failed to create ${typeLabel} version.`;
                    console.error(msg, err);
                    toast.add({ label: msg, type: "error" });
                  })
                  .finally(() => setIsCreatingVersion(false));
              }}
            >
              Create Version
            </Button>
          </ButtonBar>
        )}
        {versions.length ? (
          <Table
            className="mb-l"
            caption={`${typeLabelTitle} Versions`}
            cols={[
              {
                id: "number",
                title: "#",
                format: (v, row) => (
                  <Link to={`./v/${v.id}`}>{versions.length - row}</Link>
                ),
                width: 30,
              },
              {
                id: "created",
                title: "Created",
                format: (v) => formatDateTime(v.created),
              },
              {
                id: "updated",
                title: "Updated",
                format: (v) => formatDateTime(v.updated),
                displayRules: [{ comparison: ">", value: 450 }],
              },
              {
                id: "draft",
                title: "Draft",
                format: (v) => (v.draft ? "Yes" : ""),
              },
              {
                id: "menu",
                title: "",
                format: (v) => (
                  <ContentVersionMoreMenu
                    version={v}
                    label={`View options for the ${typeLabel} version.`}
                  />
                ),
                width: 50,
                style: { position: "relative" },
              },
            ]}
            data={versions.map((v) => ({ id: v.id, value: v }))}
            limit={25}
          />
        ) : (
          <p>No versions have been created.</p>
        )}
      </main>
      {showConfirmDelete && (
        <Modal
          render={(close) => (
            <>
              <h2>Confirm Delete {typeLabelTitle}</h2>
              <p className="mt-s mb-l">
                You are about to delete the {typeLabel} "{name}". Are you sure?
              </p>
              <ButtonBar align="right">
                <Button
                  type="tertiary"
                  className={MODAL_AUTOFOCUS_CLASS}
                  onClick={() => close()}
                >
                  Cancel
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    setIsDeleting(true);
                    cm.deleteContent({ contentID: content.id, type })
                      .then((res) => {
                        const action = res.data?.action;
                        if (res.failed || !action) {
                          const msg = `Failed to delete ${typeLabel}.`;
                          console.error(msg, res);
                          toast.add({ label: msg, type: "error" });
                          return;
                        }
                        if (action === "soft-delete") {
                          toast.add({
                            label: `Successfully marked ${typeLabel} for deletion.`,
                            type: "success",
                          });
                        } else if (action === "hard-delete") {
                          toast.add({
                            label: `Successfully deleted ${typeLabel}.`,
                            type: "success",
                          });
                          navigate("./..");
                        }
                      })
                      .catch((err) => {
                        const msg = `Failed to delete ${typeLabel}.`;
                        console.error(msg, err);
                        toast.add({ label: msg, type: "error" });
                      })
                      .finally(() => {
                        setShowConfirmDelete(false);
                        setIsDeleting(false);
                      });
                  }}
                  disabled={isDeleting}
                >
                  Delete
                </Button>
              </ButtonBar>
            </>
          )}
          onClose={(e) => {
            setShowConfirmDelete(false);
            e.focus(e.original);
          }}
        />
      )}
    </>
  );
}

export default SpaceContentPage;
