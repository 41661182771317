import { Banner, Button, ButtonBar, toast } from "@formatlas/react";
import app from "app";
import { sendEmailVerification } from "firebase/auth";
import { useEffect, useState } from "react";
import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";

export interface VerifyEmailPageProps {}

/**
 * A page displayed when a user who is logged in with an unverified email
 * attempts to use the app.
 */
export function VerifyEmailPage(props: VerifyEmailPageProps): JSX.Element {
  const user = app.getUser();

  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if (!emailSent && !user?.emailVerified) {
      return;
    }
    const id = setInterval(() => {
      if (user?.emailVerified) {
        window.location.reload();
      }
      user
        ?.reload()
        .then(() => {
          if (user.emailVerified) {
            user
              .getIdToken(true)
              .catch((error) => {
                console.error("Failed to refresh ID token.", error);
              })
              .finally(() => {
                window.location.reload();
              });
          }
        })
        .catch((error) => {
          console.error("Failed to reload user.", error);
        });
    }, 10000);
    return () => {
      clearInterval(id);
    };
  }, [emailSent, user]);

  if (!user) {
    return <></>;
  }
  return (
    <>
      <ProfileAppHeader titleLink="" />
      <main className="container-max-width m-bottom-l">
        <h1 className="m-bottom-l">Verify Email</h1>
        <Banner type="info" className="m-m">
          <p>Before using My Form Atlas, you must verify your email address.</p>
        </Banner>
        {emailSent && (
          <p>
            We have sent a verification email to <b>{user.email}</b>; please
            open the email and click the link to verify your email.
          </p>
        )}
        <ButtonBar className="m-m">
          <Button
            onClick={() => {
              setIsSendingEmail(true);
              sendEmailVerification(user)
                .then(() => {
                  toast.add({
                    label: "Verification email sent!",
                    type: "success",
                  });
                  setEmailSent(true);
                })
                .catch((error) => {
                  console.error("Failed to send email.", error);
                  toast.add({ label: "Failed to send email.", type: "error" });
                })
                .finally(() => setIsSendingEmail(false));
            }}
            disabled={isSendingEmail || !!user.emailVerified}
          >
            Send Verification Email
          </Button>
        </ButtonBar>
      </main>
    </>
  );
}

export default VerifyEmailPage;
