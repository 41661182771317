import { Button, ButtonBar, CheckboxGroup } from "@formatlas/react";
import { useContext, useState } from "react";
import SpaceContext from "src/context/space-context/SpaceContext";
import TagValue from "../tag-value/TagValue";

export interface ContentTagMultiselectProps {
  className?: string;
  tags: string[];
  onUpdate?: (newTags: string[]) => void;
  onCancel?: () => void;
  disabled?: boolean;
}

export function ContentTagMultiselect(props: ContentTagMultiselectProps) {
  const { settings = { tags: {} } } = useContext(SpaceContext);
  const tagsByID = settings.tags;
  const allTags = Object.keys(tagsByID)
    .map((id) => ({ id, tag: tagsByID[id] }))
    .sort((a, b) =>
      ((a.tag && a.tag.name) || "").toLowerCase() <
      ((b.tag && b.tag.name) || "").toLowerCase()
        ? -1
        : 1
    );
  const maxTags = Math.min(10, allTags.length);

  const { tags, disabled } = props;
  const [selected, setSelected] = useState(
    tags
      .filter((id, i, arr) => tagsByID[id] && arr.indexOf(id) === i)
      .filter((_, i) => i < maxTags)
  );

  return (
    <div className={props.className}>
      <CheckboxGroup
        className="container-flex wrap ai-center gap-s"
        label={`Select up to ${maxTags} tag${maxTags === 1 ? "" : "s"}`}
        name="content.tags"
        options={allTags.map(({ id, tag }) => ({
          value: id,
          label: (
            <span
              className="inline-block"
              style={{ transform: "translateY(-4px)" }}
            >
              <TagValue id={id} tag={tag} />
            </span>
          ),
          disabled: !selected.includes(id) && selected.length >= maxTags,
        }))}
        values={selected}
        onChange={(v) => setSelected(v)}
        disabled={disabled}
      />
      <ButtonBar className="m-top-xs">
        <Button
          type="primary"
          disabled={disabled}
          onClick={() => {
            props.onUpdate &&
              props.onUpdate(
                allTags
                  .filter(({ id }) => selected.includes(id))
                  .filter((_, i) => i < maxTags)
                  .map(({ id }) => id)
              );
          }}
        >
          Update Tags
        </Button>
        <Button
          type="tertiary"
          disabled={disabled}
          onClick={() => {
            props.onCancel && props.onCancel();
          }}
        >
          Cancel
        </Button>
      </ButtonBar>
    </div>
  );
}

export default ContentTagMultiselect;
