import { createContext } from "react";
import {
  Space,
  SpaceEnv,
  SpaceSettingsDocData,
  UserSpaceDocData,
} from "@formatlas/types";

/**
 * Represents the data contained in the {@link SpaceContext}.
 */
export interface SpaceContextData {
  /** The current space. */
  space: Space;

  /** The environments for the space. */
  envs?: SpaceEnv[];

  /** The available environments for the space. */
  availableEnvs?: SpaceEnv[];

  /** If the space only has one available environment, it is contained in this field. */
  singleEnv?: SpaceEnv;

  /** The user space details. */
  userSpace?: UserSpaceDocData;

  /** The space settings. */
  settings?: SpaceSettingsDocData;
}

/**
 * The Space Context provides common Space data that is used across the app.
 */
export const SpaceContext = createContext<SpaceContextData>({
  space: {
    id: "",
    vanityID: null,
    created: new Date(NaN),
    updated: new Date(NaN),
    displayName: "",
    icon: "",
    owner: "",
    createdBy: "",
  },
});

export default SpaceContext;
