/** The display name of the company. */
export const COMPANY_NAME = "Form Atlas";

/** The common maximum page width for content, in pixels (including padding). */
export const CONTENT_MAX_WIDTH = 1240;

/** The left padding for content, in pixels. */
export const CONTENT_LEFT_PADDING = 8;

/** The right padding for content, in pixels. */
export const CONTENT_RIGHT_PADDING = 8;

/** The default text colour (in hex). */
export const COLOUR_TEXT = "#001122";

/** The default background colour (in hex). */
export const COLOUR_BG = "#FFFFFF";

/** The primary brand colour (in hex). */
export const COLOUR_PRIMARY = "#04578b";

/** The secondary brand colour (in hex). */
export const COLOUR_SECONDARY = "#5cbdfa";

/** The tertiary brand colour (in hex). */
export const COLOUR_TERTIARY = "#00bd2a";

/** The positive (light) feedback colour (in hex). */
export const COLOUR_FB_POSITIVE_LIGHT = "#a0ffa3";

/** The positive (dark) feedback colour (in hex). */
export const COLOUR_FB_POSITIVE_DARK = "#008800";

/** The negative (light) feedback colour (in hex). */
export const COLOUR_FB_NEGATIVE_LIGHT = "#ff9292";

/** The negative (dark) feedback colour (in hex). */
export const COLOUR_FB_NEGATIVE_DARK = "#af0000";

/** The warning (light) feedback colour (in hex). */
export const COLOUR_FB_WARN_LIGHT = "#ffe589";

/** The warning (dark) feedback colour (in hex). */
export const COLOUR_FB_WARN_DARK = "#9f6300";

/** The information (light) feedback colour (in hex). */
export const COLOUR_FB_INFO_LIGHT = "#9fd4ff";

/** The information (dark) feedback colour (in hex). */
export const COLOUR_FB_INFO_DARK = "#3b5aff";
