import { useId, useState } from "react";

/**
 * A tab with content to display and a unique (non-DOM) ID.
 */
export interface Tab {
  /** A unique ID for the tab. */
  id: string;

  /** The title to display for the tab. */
  title: string;

  /** The content to display when the tab is active. */
  content: JSX.Element;

  /** Flag indicating if the content of the tab is focusable when shown. */
  focusable?: boolean;
}

/**
 * The properties for the {@link Tabs} component.
 */
export interface TabsProps {
  /** The DOM id to use for the container. */
  id?: string;

  /** The container class name. */
  className?: string;

  /** The tabs to display. */
  tabs: Tab[];

  /** The ID of the active tab. */
  active?: string;

  /** A handler that is called when a new tab becomes active by user interaction. */
  onChange?: (tab: Tab) => any;

  "aria-describedby"?: string;
}

/**
 * A set of tabs which the user can select to show different content.
 */
export function Tabs(props: TabsProps): JSX.Element {
  const id = useId();
  const [active, setActive] = useState(
    props.active ?? (props.tabs[0] || { id: "" }).id
  );
  const [tabButtons] = useState<{ [id: string]: HTMLButtonElement | null }>({});

  let className = "tabs";
  if (props.className) {
    className += " " + props.className;
  }

  return (
    <div
      id={props.id}
      className={className}
      aria-describedby={props["aria-describedby"]}
    >
      <div className="container-tablist">
        <div role="tablist">
          {props.tabs.map((tab, index) => {
            const isActive = tab.id === active;
            return (
              <button
                key={tab.id}
                id={id + "-tab-" + index}
                className={isActive ? "tab active" : "tab"}
                ref={(ref) => (tabButtons[tab.id] = ref)}
                type="button"
                role="tab"
                tabIndex={isActive ? undefined : -1}
                aria-selected={isActive}
                aria-controls={id + "-tabpanel-" + index}
                onClick={() => {
                  setActive(tab.id);
                  props.onChange && props.onChange(tab);
                }}
                onKeyDown={(event) => {
                  let next: Tab | null = null;
                  if (event.code === "ArrowRight") {
                    next = props.tabs[(index + 1) % props.tabs.length];
                  } else if (event.code === "ArrowLeft") {
                    next =
                      props.tabs[
                        index - 1 < 0 ? props.tabs.length - 1 : index - 1
                      ];
                  }
                  if (next) {
                    setActive(next.id);
                    const nextButton = tabButtons[next.id];
                    if (nextButton) {
                      nextButton.focus();
                    }
                    props.onChange && props.onChange(tab);
                  }
                }}
              >
                {tab.title}
              </button>
            );
          })}
        </div>
      </div>
      {props.tabs.map((tab, index) => {
        const isActive = tab.id === active;
        return (
          <div
            key={tab.id}
            id={id + "-tabpanel-" + index}
            className={isActive ? "tabpanel active" : "tabpanel"}
            role="tabpanel"
            tabIndex={isActive && tab.focusable ? 0 : -1}
            aria-labelledby={id + "-tab-" + index}
            style={{ display: isActive ? "block" : "none" }}
          >
            {tab.content}
          </div>
        );
      })}
    </div>
  );
}

export default Tabs;
