import { Button, ButtonBar, NavBar, TextField, toast } from "@formatlas/react";
import app, { AppManager } from "app";
import { useEffect, useState } from "react";
import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";

/**
 * The properties for the {@link ProfilePage} component.
 */
export interface ProfilePageProps {}

/**
 * The profile app/page for the user to view their user information and logout.
 */
export default function ProfilePage(props: ProfilePageProps): JSX.Element {
  const user = app.getUser();
  const userDoc = app.getUserDoc();

  const [firstName, setFirstName] = useState(userDoc?.firstName || "");
  const [lastName, setLastName] = useState(userDoc?.lastName || "");
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    return app.onUserReady((doc) => {
      setFirstName(doc.firstName);
      setLastName(doc.lastName);
    });
  }, []);

  function formatDate(v: string | number | undefined | null): string {
    if (!v) return "";
    const date = new Date(v);
    return date.valueOf() ? date.toLocaleString() : "";
  }

  const created = formatDate(user?.metadata.creationTime);
  const lastSignIn = formatDate(user?.metadata.lastSignInTime);
  return (
    <>
      <ProfileAppHeader title="Profile" />
      <NavBar leftItems={[{ url: "/", icon: "home", title: "Home" }]} />
      <main className="container-max-width">
        <p>
          Your profile was created on {created || "(unknown)"}, and the last
          sign in was on {lastSignIn || "(unknown)"}.
        </p>
        <form className="m-m container-flex wrap jc-between">
          <TextField
            className="flex col-50 s-col-100 m-s p-r-s s-p-lr-0"
            label="First Name"
            name="user-first-name"
            value={firstName}
            autoComplete="given-name"
            onChange={(v) => setFirstName(v)}
          />
          <TextField
            className="flex col-50 s-col-100 m-s p-l-s s-p-lr-0"
            label="Last Name"
            name="user-last-name"
            value={lastName}
            autoComplete="family-name"
            onChange={(v) => setLastName(v)}
          />
          <TextField
            className="flex col-50 s-col-100 m-s p-r-s s-p-lr-0"
            label="Display Name"
            value={user?.displayName || ""}
            disabled
          />
          <TextField
            className="flex col-50 s-col-100 m-s p-l-s s-p-lr-0"
            label="Email"
            value={user?.email || ""}
            disabled
          />
        </form>
        <ButtonBar className="container-flex m-l jc-between">
          <Button
            title="Update your profile details"
            onClick={() => {
              setIsUpdating(true);
              app
                .updateUser({ firstName, lastName })
                .then(() => {
                  toast.add({ label: "Profile updated.", type: "success" });
                })
                .catch((error) => {
                  console.error("Failed to update profile.", error);
                  toast.add({
                    label: "Failed to update profile.",
                    type: "error",
                  });
                })
                .finally(() => {
                  setIsUpdating(false);
                });
            }}
            disabled={isUpdating}
          >
            Update
          </Button>
          <Button
            title="Log out of My Form Atlas"
            onClick={() => {
              app.logout();
              AppManager.redirectToSignIn();
            }}
          >
            Logout
          </Button>
        </ButtonBar>
      </main>
    </>
  );
}
