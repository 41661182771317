import { ContentType, FAContent, FAContentType } from "@formatlas/types";
import { useEffect, useState } from "react";
import { spaceManager } from "src/utils/SpaceManager";

/**
 * Gets space content and listens for real time updates on the server side.
 * @param {ContentTye} type the type of content to get.
 * @return {FAContentType<T>[]} the space content.
 */
export function useSpaceContent<T extends ContentType>(
  type: T
): FAContentType<T>[] {
  const [content, setContent] = useState<FAContent[]>(
    spaceManager.getContentManager()?.getContent(type) || []
  );

  useEffect(() => {
    const unsubscribe = spaceManager
      .getContentManager()
      ?.subscribeToContent(type, (data) => {
        setContent(data.content);
      });
    return () => {
      unsubscribe && unsubscribe();
    };
  }, [type]);

  return content as FAContentType<T>[];
}

export default useSpaceContent;
