import { useEffect, useId, useState } from "react";
import FieldError from "../field-error/FieldError";

/**
 * An option for the drop down.
 */
export interface DropDownOption {
  /** The value of the option. */
  value: string;

  /** The label for the option. */
  label: string;

  /** Flag indicating if the option is disabled. */
  disabled?: boolean;
}

/**
 * An option or category for the drop down.
 */
export interface PrimaryDropDownOption extends DropDownOption {
  /** Child options which can be selected. */
  children?: DropDownOption[];
}

/**
 * The properties for the {@link DropDown} component.
 */
export interface DropDownProps {
  /** The label content. */
  label?: JSX.Element | string;

  /** The DOM id to use for the container. */
  id?: string;

  /** The DOM name attribute value. */
  name?: string;

  /** The field container class name. */
  className?: string;

  /** The selected value. */
  value?: string;

  /** The options to display in the drop down. */
  options: PrimaryDropDownOption[];

  /** The drop down on hover title. */
  title?: string;

  /** The drop down placeholder value. */
  placeholder?: string;

  /** Flag indicating if the drop down is disabled or not. */
  disabled?: boolean;

  /** Flag indicating if the drop down value is required. */
  required?: boolean;

  /** Flag indicating if the drop down is invalid (i.e. has an error). */
  hasError?: boolean;

  /** An error message to display. */
  error?: string | JSX.Element;

  /** An event handler triggered when the user changes the field value. */
  onChange?: (
    value: string,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => any;

  /** An event handler triggered when the user focuses on the field. */
  onFocus?: (
    isFirstVisit: boolean,
    event: React.FocusEvent<HTMLSelectElement, Element>
  ) => any;

  /** An event handler triggered when the user removes focus from the field. */
  onBlur?: (
    value: string,
    event: React.FocusEvent<HTMLSelectElement, Element>
  ) => any;

  "aria-describedby"?: string;
}

/**
 * A simple drop down that allows a user to select a value from a list.
 */
export function DropDown(props: DropDownProps): JSX.Element {
  const id = useId();
  const [isVisited, setIsVisited] = useState(false);
  const [value, setValue] = useState(props.value ?? "");

  useEffect(() => {
    setValue(props.value ?? "");
  }, [props.value]);

  let className = "field drop-down";
  if (props.className) {
    className += " " + props.className;
  }
  if (props.hasError) {
    className += " error";
  }

  function createOption(opt: DropDownOption) {
    return (
      <option key={opt.value} value={opt.value} disabled={opt.disabled}>
        {opt.label}
      </option>
    );
  }

  return (
    <div id={props.id} className={className}>
      {props.label && (
        <label htmlFor={id}>
          {props.label}
          {props.required && <span className="c-fb-neg-dark"> *</span>}
        </label>
      )}
      <select
        id={id}
        value={value}
        name={props.name}
        title={props.title}
        placeholder={props.placeholder}
        disabled={props.disabled}
        required={props.required}
        onChange={(event) => {
          setValue(event.target.value);
          props.onChange && props.onChange(event.target.value, event);
        }}
        onFocus={(event) => {
          const isFirstVisit = !isVisited;
          if (isFirstVisit) {
            setIsVisited(true);
          }
          props.onFocus && props.onFocus(isFirstVisit, event);
        }}
        onBlur={(event) =>
          props.onBlur && props.onBlur(event.target.value, event)
        }
        aria-invalid={props.hasError}
        aria-describedby={
          props.error ? id + "-error" : props["aria-describedby"]
        }
      >
        <option value="" disabled hidden></option>
        {props.options.map((opt) => {
          if (!opt.children) {
            return createOption(opt);
          }
          return (
            <optgroup key={opt.label} label={opt.label}>
              {opt.children.map((subopt) => createOption(subopt))}
            </optgroup>
          );
        })}
      </select>
      {props.error && <FieldError id={id + "-error"} error={props.error} />}
    </div>
  );
}

export default DropDown;
