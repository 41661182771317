import {
  EMEventCallback,
  EventManager,
  isValidPathSegment,
} from "@formatlas/react";
import {
  APIRequestCreateContentVersion,
  APIRequestDeleteContent,
  APIRequestUpdateStyleContent,
  APIResponseCreateContent,
  APIResponseCreateContentVersion,
  APIResponseDeleteContent,
  APIResponseUpdateStyleContent,
  ContentDocData,
  ContentType,
  ContentVersion,
  FAComponent,
  FAContent,
  FAForm,
  FAResource,
  FAStyle,
} from "@formatlas/types";
import {
  FieldValue,
  Unsubscribe,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import * as db from "./db";
import { convertTimestamps } from "./firebase-helpers";
import app from "app";

export const ALL_CONTENT_TYPES: ContentType[] = [
  "form",
  "component",
  "style",
  "resource",
];

export type ContentUpdates = Omit<
  { [P in keyof ContentDocData]?: ContentDocData[P] | FieldValue },
  "created" | "statesByEnv"
>;

type ContentEvents = {
  onSpaceChange: { spaceID: string };
  onFormChange: { content: FAForm[]; isLoaded: boolean };
  onComponentChange: { content: FAComponent[]; isLoaded: boolean };
  onStyleChange: { content: FAStyle[]; isLoaded: boolean };
  onResourceChange: { content: FAResource[]; isLoaded: boolean };
};

type ContentCallback = EMEventCallback<
  ContentEvents,
  "onFormChange" | "onComponentChange" | "onStyleChange" | "onResourceChange"
>;

interface ContentCache {
  form: {
    content: FAForm[];
    unsubscribe?: Unsubscribe;
    isLoaded: boolean;
  };
  component: {
    content: FAComponent[];
    unsubscribe?: Unsubscribe;
    isLoaded: boolean;
  };
  style: {
    content: FAStyle[];
    unsubscribe?: Unsubscribe;
    isLoaded: boolean;
  };
  resource: {
    content: FAResource[];
    unsubscribe?: Unsubscribe;
    isLoaded: boolean;
  };
}

export class ContentManager extends EventManager<ContentEvents> {
  /** The current space's content. */
  private spaceContent: ContentCache = ContentManager.createContentCache();

  private spaceID: string;

  constructor(spaceID: string) {
    super();
    this.spaceID = spaceID;
    this.onSpaceChange();
  }

  protected onSpaceChange() {
    this.unsubscribeAll();
    this.triggerEvent("onSpaceChange", { spaceID: this.spaceID });
  }

  public setSpaceID(spaceID: string) {
    if (this.spaceID !== spaceID) {
      this.spaceID = spaceID;
      this.onSpaceChange();
    }
  }

  public getSpaceID() {
    return this.spaceID;
  }

  protected getAndCheckSpaceID(): string {
    const id = this.getSpaceID();
    if (!id) {
      throw new Error("No space set.");
    }
    if (!isValidPathSegment(id)) {
      throw new Error("Invalid space ID set.");
    }
    return id;
  }

  protected onEventListenerAdded<U extends keyof ContentEvents>(
    type: U,
    callback: EMEventCallback<ContentEvents, U>
  ): void {
    if (type === "onSpaceChange") {
      callback({ spaceID: this.spaceID } as any, type);
    }

    let contentType: ContentType | null = null;
    if (type === "onFormChange") {
      contentType = "form";
    } else if (type === "onComponentChange") {
      contentType = "component";
    } else if (type === "onStyleChange") {
      contentType = "style";
    } else if (type === "onResourceChange") {
      contentType = "resource";
    }

    if (contentType) {
      const data = this.spaceContent[contentType];
      callback(
        { content: [...data.content], isLoaded: data.isLoaded } as any,
        type
      );
    }
  }

  private mergeContent(field: keyof ContentCache, content: FAContent) {
    const allContent = this.spaceContent[field].content;
    const index = allContent.findIndex((test) => test.id === content.id);
    if (index >= 0) {
      allContent[index] = content as (typeof allContent)[number];
    } else {
      allContent.push(content as any);
    }
  }

  private sortContent(field: keyof ContentCache) {
    this.spaceContent[field].content.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
  }

  public async createContent(
    type: ContentType
  ): Promise<APIResponseCreateContent> {
    const spaceID = this.getSpaceID();
    const api = app.getAPIManager();
    const result = await api.createContent({ spaceID, type });
    if (!result.failed && result.data) {
      this.mergeContent(type, result.data.content);
      this.sortContent(type);
    }

    return result;
  }

  public async createContentVersion(
    data: Omit<APIRequestCreateContentVersion, "spaceID">
  ): Promise<APIResponseCreateContentVersion> {
    const spaceID = this.getSpaceID();
    const api = app.getAPIManager();
    return await api.createContentVersion({ spaceID, ...data });
  }

  public async getContentVersions(
    type: ContentType,
    contentID: string
  ): Promise<ContentVersion[]> {
    const spaceID = this.getAndCheckSpaceID();
    if (!contentID) {
      throw new Error("Invalid argument: contentID is empty.");
    }
    const versions: ContentVersion[] = [];
    const result = await getDocs(
      query(db.contentVersions(spaceID, type, contentID))
    );
    result.forEach((doc) => {
      versions.push(
        convertTimestamps({ ...doc.data(), id: doc.id } as ContentVersion)
      );
    });
    ContentManager.sortContentVersions(versions);

    return versions;
  }

  public getContent(type: ContentType) {
    return [...this.spaceContent[type].content];
  }

  public subscribeToContent(type: ContentType, callback: ContentCallback) {
    const cm = this;
    let callbackUnsub = null;

    let eventType: keyof ContentEvents | null = null;
    if (type === "form") {
      eventType = "onFormChange";
    } else if (type === "component") {
      eventType = "onComponentChange";
    } else if (type === "style") {
      eventType = "onStyleChange";
    } else if (type === "resource") {
      eventType = "onResourceChange";
    } else {
      throw new Error("Invalid content type.");
    }
    callbackUnsub = this.addEventListener(eventType, callback);

    // Check if already subscribed
    const spaceID = this.getSpaceID();
    const subData = this.spaceContent[type];
    if (subData.unsubscribe) {
      return callbackUnsub;
    }

    const unsubscribe = onSnapshot(db.content(spaceID, type), (snapshot) => {
      // Update data
      snapshot.docChanges().forEach((doc) => {
        const id = doc.doc.id;
        if (doc.type === "removed") {
          cm.spaceContent[type].content = cm.spaceContent[type].content.filter(
            (v) => v.id !== id
          ) as any;
          return;
        }
        cm.mergeContent(
          type,
          convertTimestamps({ ...doc.doc.data(), id } as FAContent)
        );
      });
      cm.sortContent(type);
      const subData = cm.spaceContent[type];
      subData.isLoaded = true;

      // Call callbacks
      const data = cm.spaceContent[type];
      cm.triggerEvent(eventType as any, {
        content: [...data.content],
        isLoaded: true,
      });
    });
    this.spaceContent[type].unsubscribe = unsubscribe;

    return callbackUnsub;
  }

  public unsubscribeAll() {
    const cm = this;
    this.removeAllEventListeners();
    Object.keys(this.spaceContent).forEach((field) => {
      const unsubscribe =
        cm.spaceContent[field as keyof typeof cm.spaceContent].unsubscribe;
      if (unsubscribe) {
        unsubscribe();
      }
    });
  }

  public isContentLoaded(type: ContentType) {
    return this.spaceContent[type].isLoaded;
  }

  public async updateContent(
    type: ContentType,
    id: string,
    updates: ContentUpdates
  ) {
    updates.updated = serverTimestamp();
    if (Object.keys(updates).length < 2) {
      return;
    }

    return await updateDoc(
      db.contentItem(this.getSpaceID(), type, id),
      updates
    );
  }

  public async deleteContent(
    data: Omit<APIRequestDeleteContent, "spaceID">
  ): Promise<APIResponseDeleteContent> {
    const spaceID = this.getSpaceID();
    const api = app.getAPIManager();
    return await api.deleteContent({ spaceID, ...data });
  }

  public async updateStyleContent(
    data: Omit<APIRequestUpdateStyleContent, "spaceID">
  ): Promise<APIResponseUpdateStyleContent> {
    const spaceID = this.getSpaceID();
    const api = app.getAPIManager();
    return await api.updateStyleContent({ spaceID, ...data });
  }

  public getStyleDocDefinitionRef(contentID: string, versionID: string) {
    return db.contentVersionDefinition(
      this.getAndCheckSpaceID(),
      "style",
      contentID,
      versionID,
      "root"
    );
  }

  public static sortContentVersions(
    versions: ContentVersion[]
  ): ContentVersion[] {
    return versions.sort((a, b) => b.created.getTime() - a.created.getTime());
  }

  private static createContentCache(): ContentCache {
    return {
      form: { content: [], isLoaded: false },
      component: { content: [], isLoaded: false },
      style: { content: [], isLoaded: false },
      resource: { content: [], isLoaded: false },
    };
  }
}

export default ContentManager;
