import { Button, ContextMenu } from "@formatlas/react";
import { ContentType, FAContent } from "@formatlas/types";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SpaceContext from "src/context/space-context/SpaceContext";
import { appsByID } from "src/utils/apps";
import { formatEnvironmentID } from "src/utils/format";
import { navByContent } from "../../fe-utils/fe-nav-utils";

export interface FEContentMoreMenuProps {
  type: ContentType;
  content: FAContent;
}

export function FEContentMoreMenu(props: FEContentMoreMenuProps) {
  const navigate = useNavigate();
  const { availableEnvs = [] } = useContext(SpaceContext);

  const { type, content } = props;
  const [showMenu, setShowMenu] = useState(false);

  const label = `"${content.name}" ${type} options`;
  const fm = appsByID["form-manager"];
  const contentTypeURI = navByContent[type].uri;

  return (
    <>
      <Button
        type="icon"
        icon="more_vert"
        title={label}
        aria-label={label}
        onClick={() => setShowMenu((v) => !v)}
      />
      {showMenu && (
        <ContextMenu
          options={[
            [
              {
                id: "view",
                icon: "visibility",
                name: "View",
                tooltip: `View the ${type} details.`,
                onClick: () => {
                  navigate(`./${content.id}`);
                },
              },
              ...availableEnvs.map((e) => {
                const envName = formatEnvironmentID(e.env);
                return {
                  id: `fm-view-${e.id}`,
                  icon: fm.icon,
                  name: `View in ${envName}`,
                  tooltip: `View the ${type} in the ${fm.name} ${envName} environment.`,
                  onClick: () => {
                    navigate(
                      `../../${fm.uri}/env/${e.id}/${contentTypeURI}/${content.id}`
                    );
                  },
                };
              }),
            ],
          ]}
          start="bottom"
          align="right"
          onBlur={() => setShowMenu(false)}
        />
      )}
    </>
  );
}

export default FEContentMoreMenu;
