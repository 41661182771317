const ENGLISH_LOCALE_EXP = /(^$|^en)/i;

const FRENCH_LOCALE_EXP = /^fr/i;

/** The full names for the days of the week in English. */
export const DAYS_OF_WEEK_EN = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

/** The short names for the days of the week in English. */
export const DAYS_OF_WEEK_SHORT_EN = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

/** The full names for the months in English. */
export const MONTHS_EN = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/** The short names for the months in English. */
export const MONTHS_SHORT_EN = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

/**
 * Converts a value to a date.
 * @param date the value to convert.
 * @returns a `Date` object, which may be invalid.
 */
export function toDate(date: Date | string | number): Date {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return date;
}

/**
 * Gets the day of the week label.
 * @param date the date.
 * @param fullName if true, the full name is returned.
 * @returns the full or short name for the day of the week.
 */
export function getDayOfWeek(date: Date, fullName = false) {
  const day = date.getDay();
  const days = fullName ? DAYS_OF_WEEK_EN : DAYS_OF_WEEK_SHORT_EN;
  return days[day];
}

/**
 * Gets the month label.
 * @param date the date.
 * @param fullName if true, the full name is returned.
 * @returns the full or short name for the month.
 */
export function getMonth(date: Date, fullName = false) {
  const month = date.getMonth();
  const months = fullName ? MONTHS_EN : MONTHS_SHORT_EN;
  return months[month];
}

/**
 * Formats a date in a user-friendly format, excluding the time.
 * @param date the date.
 * @returns if a valid date was provided, the formatted date - otherwise "".
 */
export function formatDate(date: Date | string | number): string {
  date = toDate(date);
  if (!date.getTime()) {
    return "";
  }
  return (
    getDayOfWeek(date) +
    " " +
    getMonth(date) +
    " " +
    date.getDate() +
    ", " +
    date.getFullYear()
  );
}

/**
 * Formats a time in a user-friendly format, excluding the date.
 * @param date the date.
 * @returns if a valid date was provided, the formatted time - otherwise "".
 */
export function formatTime(date: Date | string | number): string {
  date = toDate(date);
  if (!date.getTime()) {
    return "";
  }
  const hrs = date.getHours();
  return (
    ("00" + (hrs < 13 ? hrs : hrs % 12)).slice(-2) +
    ":" +
    ("00" + date.getMinutes()).slice(-2) +
    (date.getHours() > 11 ? " PM" : " AM")
  );
}

/**
 * Formats a datetime in a user-friendly format.
 * @param date the date.
 * @returns if a valid date was provided, the formatted datetime - otherwise "".
 */
export function formatDateTime(date: Date | string | number): string {
  date = toDate(date);
  if (!date.getTime()) {
    return "";
  }
  return formatDate(date) + " at " + formatTime(date);
}

/**
 * Formats a date to ISO.
 * @param date the value to format.
 * @returns the formatted date, in ISO format, yyyy-MM-dd.
 */
export function formatDateAsISODate(date: Date): string | null {
  if (!date.valueOf()) return null;
  return (
    date.getFullYear() +
    "-" +
    ("00" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("00" + date.getDate()).slice(-2)
  );
}

/**
 * Formats a number to a specific number of decimal places, and with specific
 * separators for the decimal and thousands.
 * @param value the value to format.
 * @param minDecimalPlaces the minimum number of decimal places.
 * @param maxDecimalPlaces the maximum number of decimal places.
 * @param decimalSeparator the separator between the integer and decimal portion of the number.
 * @param thousandSeparator the separator every thousands.
 * @returns the formatted number.
 */
export function formatNumber(
  value: number,
  minDecimalPlaces: number = 0,
  maxDecimalPlaces: number = 3,
  decimalSeparator: string = ".",
  thousandSeparator: string = ","
): string {
  if (isNaN(value)) return "NaN";
  const isNegative = value < 0;
  value = Math.abs(value);
  const parts = value.toFixed(maxDecimalPlaces).split(".");
  let intPart = parts[0],
    decimalPart = parts[1] || "";

  // Remove extra decimals at the end
  while (
    decimalPart.length > minDecimalPlaces &&
    decimalPart.slice(-1) === "0"
  ) {
    decimalPart = decimalPart.slice(0, -1);
  }

  // Add thousand separator
  for (let i = intPart.length - 3; i > 0; i -= 3) {
    intPart = intPart.slice(0, i) + thousandSeparator + intPart.slice(i);
  }

  // No decimal
  const result = (isNegative ? "-" : "") + intPart;
  if (maxDecimalPlaces < 1 || !decimalPart) return result;

  decimalPart = decimalPart.slice(0, maxDecimalPlaces);
  return result + decimalSeparator + decimalPart;
}

/**
 * Formats a number in a specific locale to a number of decimal places.
 * @param value the value to format.
 * @param minDecimalPlaces the minimum number of decimal places.
 * @param maxDecimalPlaces the maximum number of decimal places.
 * @param locale the locale, e.g. `en`, to format the number in.
 * @returns the formatted number.
 */
export function formatNumberInLocale(
  value: number,
  minDecimalPlaces: number = 0,
  maxDecimalPlaces: number = 3,
  locale: string = "en"
): string {
  // Determine the locale
  const locales = [
    { exp: ENGLISH_LOCALE_EXP, args: [".", ","] },
    { exp: FRENCH_LOCALE_EXP, args: [",", " "] },
  ];
  for (let i = 0; i < locales.length; i++) {
    const l = locales[i];
    if (l.exp.test(locale)) {
      return formatNumber(
        value,
        minDecimalPlaces,
        maxDecimalPlaces,
        l.args[0],
        l.args[1]
      );
    }
  }
  return formatNumber(value, minDecimalPlaces, maxDecimalPlaces);
}

/**
 * Formats a currency value.
 * @param value the number to format as a currency.
 * @param locale the locale, e.g. `en`, to format the number in.
 * @param currencySymbol the symbol to add to the formatted value.
 * @returns the formatted currency.
 */
export function formatCurrency(
  value: number,
  locale: string = "en",
  currencySymbol: string = "$"
): string {
  if (isNaN(value)) return "NaN";

  // Determine the locale
  const locales = [
    { exp: ENGLISH_LOCALE_EXP, before: currencySymbol, after: "" },
    { exp: FRENCH_LOCALE_EXP, before: "", after: " " + currencySymbol },
  ];
  let l = locales[0];
  for (let i = 0; i < locales.length; i++) {
    const test = locales[i];
    if (test.exp.test(locale)) {
      l = test;
      break;
    }
  }

  const formatted =
    l.before + formatNumberInLocale(Math.abs(value), 2, 2, locale) + l.after;

  return (value < 0 ? "-" : "") + formatted;
}

/**
 * Formats a percent value based on locale, where 100 means 100%.
 * @param value the value to format.
 * @param locale the locale, e.g. `en`, to format the number in.
 * @returns the formatted percent value.
 */
export function formatPercent100(value: number, locale: string = "en"): string {
  if (isNaN(value)) return "NaN";

  // Determine the locale
  const locales = [
    { exp: ENGLISH_LOCALE_EXP, after: "%" },
    { exp: FRENCH_LOCALE_EXP, after: " %" },
  ];
  let l = locales[0];
  for (let i = 0; i < locales.length; i++) {
    const test = locales[i];
    if (test.exp.test(locale)) {
      l = test;
      break;
    }
  }

  return formatNumberInLocale(value, 2, 2, locale) + l.after;
}

/**
 * Formats a percent value based on locale, where 1 means 100%.
 * @param value the value to format.
 * @param locale the locale, e.g. `en`, to format the number in.
 * @returns the formatted percent value.
 */
export function formatPercent1(value: number, locale: string = "en"): string {
  return formatPercent100(value * 100, locale);
}

/**
 * Removes all undefined properties in place from an object.
 * @param {T} object the object to remove values from.
 * @return {T} the updated object.
 */
export function removeUndefined<T>(object: T): T {
  if (!object || typeof object !== "object") return object;
  for (const key in object) {
    if (!Object.prototype.hasOwnProperty.call(object, key)) continue;
    const value = object[key];
    if (value === undefined) {
      delete object[key];
    } else if (value && typeof value === "object") {
      removeUndefined(value);
    }
  }
  return object;
}
