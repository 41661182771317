import React, { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";

import { toast, PageContainer } from "@formatlas/react";
import { Space } from "@formatlas/types";

import environment from "environment";
import app, { AppManager } from "app";

import { User } from "firebase/auth";

import LoadingPage from "./pages/loading-page/LoadingPage";
import ErrorPage from "./pages/errors/error-page/ErrorPage";
import HomePage from "./pages/home-page/HomePage";
import ProfilePage from "./pages/profile-page/ProfilePage";
import CreateSpacePage from "./pages/create-space-page/CreateSpacePage";
import VerifyEmailPage from "./pages/verify-email-page/VerifyEmailPage";
import SpaceView from "./views/space-view/SpaceView";
import Error404Page from "./pages/errors/error-404-page/Error404Page";

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [spaces, setSpaces] = useState<Space[]>(app.getUserSpaces());

  useEffect(() => {
    app.setOnAuthReady((_, user) => {
      setIsLoaded(true);
      setUser(user);
      if (!user) {
        AppManager.redirectToSignIn();
      }
    });
    return () => {
      app.setOnAuthReady();
    };
  }, []);

  useEffect(() => {
    return app.onSpacesChange((spaces) => {
      setSpaces(spaces);
    });
  }, []);

  // Waiting for auth data
  if (!isLoaded) {
    return (
      <>
        <LoadingPage>Loading application...</LoadingPage>
        {toast.renderAllToasts()}
      </>
    );
  }

  // Not logged in - shouldn't ever be shown (redirect), but to be safe
  if (!user) {
    return (
      <>
        <ErrorPage>
          You are not logged in. Please{" "}
          <Link to={environment.loginURL}>log in to My Form Atlas</Link>.
        </ErrorPage>
        {toast.renderAllToasts()}
      </>
    );
  }

  if (!user.emailVerified) {
    return (
      <>
        <VerifyEmailPage />
        {toast.renderAllToasts()}
      </>
    );
  }

  return (
    <PageContainer
      current={{
        url: "/",
        id: "home",
        name: "Home",
        icon: "home",
        description: "My Form Atlas home page",
      }}
    >
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        {spaces.map((space) => {
          return (
            <Route
              key={space.id}
              path={`/s/${space.id}/*`}
              element={
                <PageContainer
                  current={{
                    url: `/s/${space.id}`,
                    id: "space-home",
                    name: "Space Home",
                    icon: space.icon || "explore",
                    description: `The space home page for ${space.displayName}.`,
                    data: {
                      spaceID: space.id,
                    },
                  }}
                >
                  <SpaceView space={space} />
                </PageContainer>
              }
            />
          );
        })}
        {app.isLoadingUserSpaces() && (
          <Route
            path="/s/*"
            element={<LoadingPage>Loading application...</LoadingPage>}
          />
        )}
        <Route
          path="/spaces/new"
          element={
            <PageContainer
              current={{
                url: "/spaces/new",
                id: "create-space",
                name: "Create Space",
                icon: "add",
                description: "Create a new My Form Altas space.",
              }}
            >
              <CreateSpacePage />
            </PageContainer>
          }
        />
        <Route path="*" element={<Error404Page />} />
      </Routes>
      {toast.renderAllToasts()}
    </PageContainer>
  );
}

export default App;
