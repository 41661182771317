import { useContext } from "react";
import SpaceContext from "src/context/space-context/SpaceContext";
import SpaceEnvContext from "src/context/space-env-context/SpaceEnvContext";
import EnvMiniSelect from "src/shared/env-mini-select/EnvMiniSelect";
import ProfileAppHeader from "src/shared/profile-app-header/ProfileAppHeader";
import SmartNavBar from "src/shared/smart-nav-bar/SmartNavBar";
import SpaceSelect from "src/shared/space-select/SpaceSelect";

export interface FMEnvHomeProps {}

export function FMEnvHome(props: FMEnvHomeProps): JSX.Element {
  const spaceContext = useContext(SpaceContext);
  const spaceEnvContext = useContext(SpaceEnvContext);
  const space = spaceContext.space;
  const env = spaceEnvContext.env;

  return (
    <>
      <ProfileAppHeader title="Form Manager" />
      <SmartNavBar />
      <main className="container-max-width">
        <div className="container-flex jc-between ai-center wrap gap-s">
          <SpaceSelect />
          <EnvMiniSelect />
        </div>
        <p>TODO form manager</p>
      </main>
    </>
  );
}

export default FMEnvHome;
