/** Event definitions for the event manager. */
export type EMEvents = {
  [event: string]: object;
};

/** An event manager callback called on an event. */
export type EMEventCallback<T extends EMEvents, U extends keyof T> = (
  data: T[U],
  event: U
) => void;

/**
 * The `EventManager` class provides basic event management capabilities,
 * including adding, removing, and getting event handlers by event type and a
 * method to trigger events.
 */
export abstract class EventManager<T extends EMEvents> {
  /** An object that stores all callbacks based on event type. */
  protected eventListenersByType: Partial<
    Record<keyof T, EMEventCallback<T, any>[]>
  > = {};

  /**
   * A function called when a new event listener is added. Enables calling the
   * event listener if an event has already been triggered.
   * @param type the type of event.
   * @param callback the event listener.
   */
  protected abstract onEventListenerAdded<U extends keyof T>(
    type: U,
    callback: EMEventCallback<T, U>
  ): void;

  /**
   * Triggers an event by notifying all associated event listeners.
   * @param type the type of event.
   * @param data the event data.
   */
  protected triggerEvent<U extends keyof T>(type: U, data: T[U]) {
    this.eventListenersByType[type]?.forEach((callback) =>
      callback(data, type)
    );
  }

  /**
   * Gets a copy of the event listeners for a specific type of event.
   * @param type the type of event.
   * @returns the event listeners.
   */
  public getEventListeners<U extends keyof T>(type: U) {
    return [...(this.eventListenersByType[type] ?? [])];
  }

  /**
   * Adds an event listener for the specified event type.
   * @param type the type of event.
   * @param callback the event listener.
   * @returns a function that can be used to remove the event listener.
   */
  public addEventListener<U extends keyof T>(
    type: U,
    callback: EMEventCallback<T, U>
  ) {
    if (!this.eventListenersByType[type]) {
      this.eventListenersByType[type] = [];
    }
    this.eventListenersByType[type]?.push(callback);
    this.onEventListenerAdded(type, callback);

    const em = this;
    return () => {
      em.removeEventListener(type, callback);
    };
  }

  /**
   * Removes an event listener to prevent it from being called.
   * @param type the type of event.
   * @param callback the event listener to remove.
   */
  public removeEventListener<U extends keyof T>(
    type: U,
    callback: EMEventCallback<T, U>
  ) {
    this.eventListenersByType[type] = this.eventListenersByType[type]?.filter(
      (test) => test !== callback
    );
  }

  /**
   * Removes all event listeners for a specified event type. If no event type
   * is provided, all event listeners are removed.
   * @param type the type of event.
   */
  public removeAllEventListeners<U extends keyof T>(type?: U) {
    if (!type) {
      this.eventListenersByType = {};
    } else if (this.eventListenersByType[type]) {
      delete this.eventListenersByType[type];
    }
  }
}

export default EventManager;
