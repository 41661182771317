import React, { useState } from "react";
import { FEStyleEditorProps } from "../FEStyleEditor";
import {
  Banner,
  Button,
  ButtonBar,
  Icon,
  TextField,
  formatNumber,
  toast,
} from "@formatlas/react";
import { updateDoc } from "firebase/firestore";

export interface FEStyleBreakpointEditorProps extends FEStyleEditorProps {}

interface BreakpointInfo {
  size: string;
  type: string;
  icon: string;
}

export function FEStyleBreakpointEditor({
  data,
  docRef,
  readonly,
}: FEStyleBreakpointEditorProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [breakpoints, setBreakpoints] = useState<{
    [Property in keyof typeof data.breakpoints]: number;
  }>({ ...data.breakpoints });

  const labels: {
    [Property in keyof typeof data.breakpoints]: BreakpointInfo;
  } = {
    s: {
      size: "Small",
      type: "Phone (landscape)",
      icon: "stay_primary_landscape",
    },
    m: { size: "Medium", type: "Tablet", icon: "tablet_mac" },
    l: { size: "Large", type: "Laptop", icon: "laptop_chromebook" },
    xl: { size: "X-Large", type: "Desktop", icon: "desktop_windows" },
  };
  const labelIDs: (keyof typeof data.breakpoints)[] = ["s", "m", "l", "xl"];
  const cardClasses = ["p-r-s", "p-l-s", "p-r-s", "p-l-s"];

  const isValid =
    breakpoints.s < breakpoints.m &&
    breakpoints.m < breakpoints.l &&
    breakpoints.l < breakpoints.xl;

  function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    if (!isEditing || !isValid) return;

    updateDoc(docRef, { breakpoints })
      .then(() => {
        labelIDs.forEach((id) => (data.breakpoints[id] = breakpoints[id]));
        setIsEditing(false);
        toast.add({
          label: "Successfully updated screen sizes.",
          type: "success",
        });
      })
      .catch((err) => {
        console.error("Failed to update screen sizes.", err);
        toast.add({
          label: "Failed to update screen sizes.",
          type: "error",
        });
      })
      .finally(() => setIsUpdating(false));
    setIsUpdating(true);
  }

  function wrap(content: React.JSX.Element) {
    return isEditing ? <form onSubmit={submit}>{content}</form> : content;
  }

  return wrap(
    <>
      {!readonly && (
        <ButtonBar className="mt-xs mb-m">
          {!isEditing ? (
            <Button
              type="tertiary"
              icon="edit"
              title="Edit the values for the screen size breakpoints"
              onClick={() => setIsEditing(true)}
              htmlType="button"
            >
              Edit Breakpoints
            </Button>
          ) : (
            <>
              <Button
                type="primary"
                title="Save the changes"
                htmlType="submit"
                disabled={!isValid || isUpdating}
              >
                Update
              </Button>
              <Button
                type="tertiary"
                title="Cancel the changes you made"
                htmlType="button"
                onClick={() => {
                  setBreakpoints({ ...data.breakpoints });
                  setIsEditing(false);
                }}
                disabled={isUpdating}
              >
                Cancel
              </Button>
            </>
          )}
        </ButtonBar>
      )}
      <div className="container-flex wrap">
        {labelIDs.map((id, i) => {
          const label = labels[id];
          const next = i + 1 < labelIDs.length ? labelIDs[i + 1] : null;
          return (
            <div
              key={id}
              className={
                "m-xs flex col-50 s-col-100 s-p-lr-0 " + cardClasses[i]
              }
            >
              <div className="card container-flex gap-s ai-center">
                <Icon name={label.icon} />
                <span className="container-flex grow fd-col gap-xxs">
                  <span>
                    <b>Device</b>: {label.type}
                  </span>
                  {!isEditing ? (
                    <span>
                      <b>Min Size</b>: {formatNumber(breakpoints[id])}px
                    </span>
                  ) : (
                    <span>
                      <TextField
                        name={`breakpoint.${id}`}
                        label="Min Size"
                        title="Edit the minimum screen size for this device category"
                        mode="numeric"
                        maxLength={5}
                        value={"" + breakpoints[id]}
                        onChange={(value) => {
                          const min = Math.min(
                            Math.max(parseInt(value), 0),
                            10000
                          );
                          setBreakpoints((b) => ({
                            ...b,
                            [id]: isNaN(min) ? 0 : min,
                          }));
                        }}
                        hasError={
                          !!(next && breakpoints[next] <= breakpoints[id])
                        }
                      />
                    </span>
                  )}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      {isEditing && !isValid && (
        <Banner type="error" className="m-s">
          <p>The screen sizes must be in ascending order.</p>
        </Banner>
      )}
    </>
  );
}

export default FEStyleBreakpointEditor;
