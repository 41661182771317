/**
 * The properties for the {@link ButtonBar} component.
 */
export interface ButtonBarProps {
  /** The component's DOM id. */
  id?: string;

  /** Extra classes to be added to the component. */
  className?: string;

  /** The button alignment. */
  align?: "left" | "right";

  children?: any;
}

/**
 * A simple button bar that can display multiple buttons in a row.
 * Automatically adjusts to small screens.
 */
export function ButtonBar(props: ButtonBarProps): JSX.Element {
  let className = "btn-bar";
  if (props.align === "right") {
    className += " right";
  }
  if (props.className) {
    className += " " + props.className;
  }

  return (
    <div id={props.id} className={className}>
      {props.children}
    </div>
  );
}

export default ButtonBar;
