import { useId, useState } from "react";
import FieldOption from "../../models/FieldOption";
import Checkbox from "../checkbox/Checkbox";
import FieldError from "../field-error/FieldError";

/**
 * The properties for the {@link CheckboxGroup} component.
 */
export interface CheckboxGroupProps {
  /** The DOM id to use for the checkbox group container. */
  id?: string;

  /** The field container class name. */
  className?: string;

  /** The label to display for this field. */
  label: string | JSX.Element;

  /** The DOM name attribute value. */
  name: string;

  /** The options to display. */
  options: FieldOption[];

  /** An option that when selected, deselects everything else. */
  noneOfTheAbove?: FieldOption;

  /** The selected values. */
  values?: string[];

  /** The checkbox on hover title. */
  title?: string;

  /** Flag indicating if the checkbox group is disabled or not. */
  disabled?: boolean;

  /** Flag indicating if the checkbox group value is required. */
  required?: boolean;

  /** Flag indicating if the checkbox group is invalid (i.e. has an error). */
  hasError?: boolean;

  /** An error message to display. */
  error?: string | JSX.Element;

  /** An event handler triggered when the user changes the field value. */
  onChange?: (values: string[]) => any;

  /** An event handler triggered when the user focuses on the field. */
  onFocus?: (values: string[], isFirstVisit: boolean) => any;

  /** An event handler triggered when the user removes focus from the field. */
  onBlur?: (values: string[]) => any;

  "aria-describedby"?: string;
}

/**
 * A group of checkboxes.
 */
export function CheckboxGroup(props: CheckboxGroupProps): JSX.Element {
  const id = useId();
  const [values, setValues] = useState<string[]>(props.values || []);
  const [isVisited, setIsVisited] = useState(false);
  const [checkboxRefs] = useState<{
    [value: string]: React.RefObject<HTMLInputElement>;
  }>({});
  const [noneRef, setNoneRef] =
    useState<React.RefObject<HTMLInputElement> | null>(null);

  function createGetRef(opt: FieldOption) {
    return (ref: React.RefObject<HTMLInputElement>) => {
      checkboxRefs[opt.value] = ref;
    };
  }

  function createOnChange(opt: FieldOption) {
    return (checked: boolean) => {
      let updatedValues = values;
      if (checked) {
        updatedValues = values
          .concat([opt.value])
          .filter(
            (v, i, arr) =>
              v !== props.noneOfTheAbove?.value && arr.indexOf(v) === i
          );
        if (noneRef && noneRef.current) {
          noneRef.current.checked = false;
        }
      } else {
        updatedValues = values.filter((v) => v !== opt.value);
      }
      setValues(updatedValues);
      props.onChange && props.onChange(updatedValues);
    };
  }

  function createOnFocus() {
    return () => {
      const isFirstVisit = !isVisited;
      if (isFirstVisit) {
        setIsVisited(true);
      }
      props.onFocus && props.onFocus(values, isFirstVisit);
    };
  }

  function createOnBlur() {
    return () => props.onBlur && props.onBlur(values);
  }

  let className = "fieldset checkbox-group";
  if (props.className) {
    className += " " + props.className;
  }
  return (
    <fieldset
      id={props.id}
      className={className}
      title={props.title}
      aria-describedby={props["aria-describedby"]}
    >
      <legend>
        {props.label}
        {props.required && <span className="c-fb-neg-dark"> *</span>}
      </legend>
      {props.options.map((opt) => {
        const selected = values.indexOf(opt.value) >= 0;
        return (
          <Checkbox
            key={opt.value}
            checked={selected}
            name={props.name}
            label={opt.label}
            required={opt.required}
            disabled={props.disabled || opt.disabled}
            hasError={props.hasError || opt.hasError}
            getRef={createGetRef(opt)}
            onChange={createOnChange(opt)}
            onFocus={createOnFocus()}
            onBlur={createOnBlur()}
            aria-describedby={
              props.error ? id + "-error" : props["aria-describedby"]
            }
          />
        );
      })}
      {props.noneOfTheAbove && (
        <Checkbox
          checked={values.indexOf(props.noneOfTheAbove.value) >= 0}
          name={props.name}
          label={props.noneOfTheAbove.label}
          required={props.noneOfTheAbove.required}
          disabled={props.disabled || props.noneOfTheAbove.disabled}
          hasError={props.hasError || props.noneOfTheAbove.hasError}
          getRef={(ref) => setNoneRef(ref)}
          onChange={(checked) => {
            const noneValue = props.noneOfTheAbove?.value || "";
            let updatedValues = values;
            if (checked) {
              updatedValues = [noneValue];
              Object.keys(checkboxRefs).forEach((value) => {
                const ref = checkboxRefs[value];
                if (ref.current) ref.current.checked = false;
              });
            } else {
              updatedValues = values.filter((v) => v !== noneValue);
            }
            setValues(updatedValues);
            props.onChange && props.onChange(updatedValues);
          }}
          onFocus={createOnFocus()}
          onBlur={createOnBlur()}
          aria-describedby={
            props.error ? id + "-error" : props["aria-describedby"]
          }
        />
      )}
      {props.error && <FieldError id={id + "-error"} error={props.error} />}
    </fieldset>
  );
}

export default CheckboxGroup;
