import { createContext } from "react";

/**
 * Represents a logical page that can be visited in the browser.
 */
export interface Page {
  /** The URL to the page. */
  url: string;
  /** An ID that identifies the page. */
  id?: string;
  /** The name that appears on breadcrumbs and back navigation. */
  name?: string;
  /** An icon that may be displayed to represent the page. */
  icon?: string;
  /** A brief description of the page. */
  description?: string;
  /** Additional page data. */
  data?: {
    [key: string]: any;
  };
}

/**
 * Represents the data contained in the {@link PageContext}.
 */
export interface PageContextData {
  /** The higher-level pages to get to the current page. */
  ancestors: Page[];
  /** The current page object. */
  current?: Page;
}

/**
 * The Page Context provides data about where in the application the user
 * currently is.
 */
export const PageContext = createContext<PageContextData>({
  ancestors: [],
});

export default PageContext;
