/**
 * The properties for the {@link Icon} component.
 */
export interface IconProps {
  /** The [Material Icon Name](https://fonts.google.com/icons?icon.set=Material+Icons&icon.style=Filled). */
  name: string;

  /** The icon's DOM id. */
  id?: string;

  /** Extra classes to be added to the icon. */
  className?: string;

  /** The icon's size. Default: small. */
  size?: "small" | "medium" | "large";
}

/**
 * An Icon component that renders a
 * [Material Icon](https://fonts.google.com/icons?icon.set=Material+Icons&icon.style=Filled).
 */
export function Icon(props: IconProps): JSX.Element {
  let className = "material-icons icon";
  if (props.size === "medium") {
    className += " m";
  } else if (props.size === "large") {
    className += " l";
  }
  if (props.className) {
    className += " " + props.className;
  }
  return (
    <span className={className} id={props.id} aria-hidden="true">
      {props.name}
    </span>
  );
}

export default Icon;
