import { useId, useState } from "react";

/**
 * The properties for the {@link RadioButtonBox} component.
 */
export interface RadioButtonBoxProps {
  /** The label content. */
  label?: JSX.Element | string;

  /** The DOM id to use for the container. */
  id?: string;

  /** The DOM name attribute value. */
  name?: string;

  /** The field container class name. */
  className?: string;

  /** Flag indicating if the radio button is checked or not. */
  checked?: boolean;

  /** The radio button on hover title. */
  title?: string;

  /** Flag indicating if the radio button is disabled or not. */
  disabled?: boolean;

  /** Flag indicating if the radio button value is required. */
  required?: boolean;

  /** Flag indicating if the radio button is invalid (i.e. has an error). */
  hasError?: boolean;

  /** An event handler triggered when the user changes the field value. */
  onChange?: (
    checked: boolean,
    event: React.ChangeEvent<HTMLInputElement>
  ) => any;

  /** An event handler triggered when the user focuses on the field. */
  onFocus?: (
    isFirstVisit: boolean,
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => any;

  /** An event handler triggered when the user removes focus from the field. */
  onBlur?: (
    checked: boolean,
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => any;

  "aria-describedby"?: string;
}

/**
 * A simple radio button to collect true/false input from the user.
 */
export function RadioButtonBox(props: RadioButtonBoxProps): JSX.Element {
  const id = useId();
  const [isVisited, setIsVisited] = useState(false);

  let className = "radio-button-box";
  if (props.className) {
    className += " " + props.className;
  }
  if (props.hasError) {
    className += " error";
  }
  return (
    <div id={props.id} className={className}>
      <input
        type="radio"
        id={id}
        defaultChecked={props.checked}
        name={props.name}
        title={props.title}
        disabled={props.disabled}
        required={props.required}
        onChange={(event) => {
          props.onChange && props.onChange(event.target.checked, event);
        }}
        onFocus={(event) => {
          const isFirstVisit = !isVisited;
          if (isFirstVisit) {
            setIsVisited(true);
          }
          props.onFocus && props.onFocus(isFirstVisit, event);
        }}
        onBlur={(event) =>
          props.onBlur && props.onBlur(event.target.checked, event)
        }
        aria-invalid={props.hasError}
        aria-describedby={props["aria-describedby"]}
      />
      <label htmlFor={id}>{props.label || ""}</label>
    </div>
  );
}

export default RadioButtonBox;
