import { Button, ButtonBar, TextField, toast } from "@formatlas/react";
import { useState } from "react";
import { FEStyleEditorProps } from "../FEStyleEditor";
import { updateDoc } from "firebase/firestore";

export interface FEStyleSpacingEditorProps extends FEStyleEditorProps {}

function SpacingExample({ label, size }: { label?: string; size: number }) {
  return (
    <div className="container-flex fd-col ai-center gap-xxs">
      {label && <span>{label}</span>}
      <div
        className="fe-se-spacing-example br-s relative container-flex ai-center jc-center"
        style={{ padding: size }}
        aria-hidden
      >
        <div className="fe-se-spacing-example-box">{size}</div>
      </div>
    </div>
  );
}

export function FEStyleSpacingEditor({
  data,
  readonly,
  docRef,
}: FEStyleSpacingEditorProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [preview, setPreview] = useState(0);

  const spaceInputClass = "flex col-10 m-col-25";
  const labels: {
    [Property in keyof typeof data.spacing]: string;
  } = {
    xxs: "XX-Small",
    xs: "X-Small",
    s: "Small",
    m: "Medium",
    l: "Large",
    xl: "X-Large",
    xxl: "XX-Large",
  };
  const labelIDs = Object.keys(labels) as (keyof typeof data.spacing)[];

  const [spacing, setSpacing] = useState<{
    [Property in keyof typeof data.spacing]: number;
  }>({ ...data.spacing });

  if (!isEditing) {
    return (
      <>
        {!readonly && (
          <ButtonBar className="mt-xs mb-m">
            <Button
              type="tertiary"
              icon="edit"
              title="Edit the values for the spacing"
              onClick={() => setIsEditing(true)}
            >
              Edit Spacing
            </Button>
          </ButtonBar>
        )}
        <div className="container-flex wrap gap-s m-s">
          {labelIDs.map((id) => (
            <SpacingExample
              key={id}
              label={labels[id]}
              size={data.spacing[id]}
            />
          ))}
        </div>
      </>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        const finalSpacing = { ...spacing };
        labelIDs.forEach((id) => {
          if (isNaN(finalSpacing[id])) {
            finalSpacing[id] = 0;
          }
        });
        updateDoc(docRef, { spacing: finalSpacing })
          .then(() => {
            labelIDs.forEach((id) => (data.spacing[id] = finalSpacing[id]));
            setIsEditing(false);
            toast.add({
              label: "Successfully updated spacing.",
              type: "success",
            });
          })
          .catch((err) => {
            console.error("Failed to update spacing.", err);
            toast.add({
              label: "Failed to update spacing.",
              type: "error",
            });
          })
          .finally(() => setIsUpdating(false));
        setIsUpdating(true);
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <ButtonBar className="mt-xs mb-m">
        <Button
          type="primary"
          title="Save the changes"
          htmlType="submit"
          disabled={isUpdating}
        >
          Update
        </Button>
        <Button
          type="tertiary"
          title="Cancel the changes you made"
          onClick={() => {
            setIsEditing(false);
            setSpacing({ ...data.spacing });
          }}
          htmlType="button"
          disabled={isUpdating}
        >
          Cancel
        </Button>
      </ButtonBar>
      <div className="container-flex wrap gap-xs ai-center m-s">
        {labelIDs.map((id) => (
          <TextField
            key={id}
            className={spaceInputClass}
            name={"spacing." + id}
            mode="numeric"
            maxLength={4}
            label={labels[id]}
            value={isNaN(spacing[id]) ? "" : "" + spacing[id]}
            onFocus={() => {
              setPreview(spacing[id]);
            }}
            onChange={(value) => {
              const size = Math.min(Math.max(parseInt(value), 0), 1000);
              setPreview(size);
              setSpacing((s) => ({
                ...s,
                [id]: size,
              }));
            }}
            onBlur={() => setPreview(0)}
          />
        ))}
      </div>
      {!isNaN(preview) && preview > 0 && (
        <div className="mt-m">
          <SpacingExample size={preview} />
        </div>
      )}
    </form>
  );
}

export default FEStyleSpacingEditor;
