import React from "react";
import { Link } from "react-router-dom";
import Icon from "../icon/Icon";

/**
 * The properties for the {@link Button} component.
 */
export interface ButtonProps {
  /** The component's DOM id. */
  id?: string;

  /** Extra classes to be added to the component. */
  className?: string;

  /** The style of button. */
  type?: "primary" | "secondary" | "tertiary" | "danger" | "icon" | "text";

  /** The type of button, which influences the default onClick behaviour. */
  htmlType?: "button" | "submit" | "reset";

  /** An icon to add to the start of the button text. */
  icon?: string;

  /** The URL to navigate to if the button is clicked. */
  linkURL?: string;

  /** If true, the linkURL opens in a new window. */
  linkOpensNewWindow?: boolean;

  /** Disables the button so that it cannot be clicked. */
  disabled?: boolean;

  children?: any;

  /** The value for the HTML title attribute. */
  title?: string;

  /** Handles a click event, when the button is enabled. */
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => any;

  /** Handles a keydown event, when the button is enabled. */
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => any;

  "aria-label"?: string;
  "aria-expanded"?: boolean;
}

/**
 * A basic button.
 */
export const Button = React.forwardRef(
  (props: ButtonProps, ref: React.ForwardedRef<any>): JSX.Element => {
    const { type } = props;
    let className = "";
    if (!type || type === "primary") {
      className = "btn-primary";
    } else if (
      type === "secondary" ||
      type === "tertiary" ||
      type === "danger" ||
      type === "icon" ||
      type === "text"
    ) {
      className = "btn-" + type;
    }
    if (props.className) {
      className += " " + props.className;
    }

    const onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!props.disabled && props.onClick) {
        props.onClick(event);
      }
    };
    const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
      if (!props.disabled && props.onKeyDown) {
        props.onKeyDown(event);
      }
    };

    const content = (
      <>
        {props.icon && <Icon name={props.icon} />}
        {props.children}
      </>
    );

    // Link
    if (!props.disabled && props.linkURL) {
      return (
        <Link
          ref={ref}
          to={props.linkURL}
          id={props.id}
          className={className}
          title={props.title}
          target={props.linkOpensNewWindow ? "_blank" : "_self"}
          onClick={onClick}
          onKeyDown={onKeyDown}
          aria-label={props["aria-label"]}
          aria-expanded={props["aria-expanded"]}
        >
          {content}
        </Link>
      );
    }

    // Regular button
    return (
      <button
        ref={ref}
        id={props.id}
        className={className}
        title={props.title}
        type={props.htmlType}
        disabled={props.disabled}
        onClick={onClick}
        onKeyDown={onKeyDown}
        aria-label={props["aria-label"]}
        aria-expanded={props["aria-expanded"]}
      >
        {content}
      </button>
    );
  }
);

export default Button;
