import { Banner, Button, ButtonBar } from "@formatlas/react";
import { ContentType } from "@formatlas/types";
import ErrorPage from "../error-page/ErrorPage";
import { formatContentType } from "src/utils/format";

export interface ContentNotFoundPageProps {
  type: ContentType;
  returnTo?: string;
}

export function ContentNotFoundPage(props: ContentNotFoundPageProps) {
  const { type, returnTo } = props;
  return (
    <ErrorPage
      title={`${formatContentType(type, false, true)} Not Found`}
      showNavLinks
    >
      <Banner type="error" className="m-bottom-l">
        The {formatContentType(type)} you are looking for could not be found.
      </Banner>
      {returnTo && (
        <ButtonBar className="m-l">
          <Button linkURL={returnTo}>
            Return to {formatContentType(type, true, true)}
          </Button>
        </ButtonBar>
      )}
    </ErrorPage>
  );
}

export default ContentNotFoundPage;
