import {
  Button,
  ButtonBar,
  ColourPicker,
  MODAL_AUTOFOCUS_CLASS,
  Modal,
  TextField,
  generateID,
  toast,
} from "@formatlas/react";
import { Tag } from "@formatlas/types";
import { useContext, useEffect, useState } from "react";
import SpaceContext from "src/context/space-context/SpaceContext";
import TagValue from "src/shared/tag-value/TagValue";
import { spaceManager } from "src/utils/SpaceManager";

export interface TagEditorProps {
  id: string;
  tag: Tag;
  locked?: boolean;
  isEditing?: boolean;
  onEditStateChange: (id: string, isEditing: boolean) => void;
}

export function TagEditor(props: TagEditorProps) {
  const { id, tag, isEditing: propsIsEditing } = props;

  const { settings } = useContext(SpaceContext);

  const [isEditing, setIsEditing] = useState(!!propsIsEditing);
  const [name, setName] = useState(tag.name);
  const [colour, setColour] = useState(tag.colour);

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    setIsEditing(!!propsIsEditing);
  }, [propsIsEditing]);

  function wrap(content: JSX.Element) {
    return <div className="flex m-xs col-100">{content}</div>;
  }

  function getID() {
    if (id) return id;
    let tmpID = generateID(4);
    while (settings && settings.tags[tmpID]) {
      tmpID = generateID(4);
    }
    return tmpID;
  }

  if (!isEditing) {
    return wrap(
      <TagValue
        onClick={() => {
          if (!props.locked) {
            setIsEditing(true);
            props.onEditStateChange(id, true);
          }
        }}
        id={id}
        tag={tag}
      />
    );
  }
  return wrap(
    <>
      <div className="container-flex wrap">
        <TextField
          className="flex col-40 s-col-100 p-r-s s-p-lr-0 m-xxs"
          name="tag.name"
          label="Tag Name"
          value={name}
          onChange={(v) => setName(v)}
          maxLength={40}
        />
        <ColourPicker
          className="flex p-lr-s s-p-l-0 m-xxs"
          name="tag.colour"
          label="Tag Colour"
          value={colour}
          onChange={(v) => setColour(v)}
        />
        <div className="flex p-l-s m-xxs">
          <p className="bold m-bottom-s">Tag Preview</p>
          <TagValue id={id} tag={{ name, colour }} />
        </div>
      </div>
      <ButtonBar className="m-top-s">
        <Button
          type="primary"
          disabled={isSaving}
          onClick={() => {
            setIsSaving(true);
            spaceManager
              .updateSpaceSettings({
                tags: { set: [{ id: getID(), value: { name, colour } }] },
              })
              .then((res) => {
                if (res.failed) {
                  console.error("Failed to update settings.", res);
                } else {
                  setIsEditing(false);
                  props.onEditStateChange(id, false);
                }
              })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => setIsSaving(false));
          }}
        >
          {id ? "Update" : "Save"}
        </Button>
        <Button
          type="tertiary"
          onClick={() => {
            setIsEditing(false);
            props.onEditStateChange(id, false);
          }}
          title="Stop editing the tag"
        >
          Cancel
        </Button>
        {id && (
          <Button
            type="danger"
            disabled={isDeleting}
            onClick={() => setShowConfirmDelete(true)}
          >
            Delete
          </Button>
        )}
      </ButtonBar>
      {showConfirmDelete && (
        <Modal
          render={(close) => (
            <>
              <h2>Confirm Delete Tag</h2>
              <p className="mt-s mb-l">
                You are about to delete the tag "{name}", which cannot be
                undone. If any content uses this tag, it will automatically be
                removed. Are you sure?
              </p>
              <ButtonBar align="right">
                <Button
                  type="tertiary"
                  className={MODAL_AUTOFOCUS_CLASS}
                  onClick={() => close()}
                  disabled={isDeleting}
                >
                  Cancel
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    setIsDeleting(true);
                    spaceManager
                      .updateSpaceSettings({
                        tags: { remove: [id] },
                      })
                      .then((res) => {
                        if (res.failed) {
                          const msg = "Failed to delete tag.";
                          console.error(msg, res);
                          toast.add({ label: msg, type: "error" });
                        } else {
                          close();
                          setIsEditing(false);
                          props.onEditStateChange(id, false);
                          toast.add({
                            label: `Successfully deleted tag "${name}".`,
                            type: "success",
                          });
                        }
                      })
                      .catch((err) => {
                        const msg = "Failed to delete tag.";
                        console.error(msg, err);
                        toast.add({ label: msg, type: "error" });
                      })
                      .finally(() => setIsDeleting(false));
                  }}
                  disabled={isDeleting}
                >
                  Delete Tag
                </Button>
              </ButtonBar>
            </>
          )}
          onClose={(e) => {
            setShowConfirmDelete(false);
            e.focus(e.original);
          }}
        />
      )}
    </>
  );
}

export default TagEditor;
